<template>
    <ul :class="['pagination', alignClass, sizeClass]">
        <li class="page-item" v-if="!firstNumber">
            <a class="page-link" href="#" aria-label="First" @click.prevent="setPage(1)">
                <span aria-hidden="true">«</span>
            </a>
        </li>

        <li :class="['page-item', {disabled: isPrevDisabled}]">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="setPage(modelValue - 1)">
                <span aria-hidden="true">‹</span>
            </a>
        </li>

        <template v-if="showFirst">
            <li :class="['page-item', {active: modelValue == 1}]">
                <a class="page-link" href="#" @click.prevent="setPage(1)">1</a>
            </li>
            <li class="page-item disabled"><a class="page-link" href="#">…</a></li>
        </template>

        <template v-for="(p, i) in visiblePages" :key="`page-${i}`">
            <li :class="['page-item', {active: modelValue == p}]">
                <a class="page-link" href="#" @click.prevent="setPage(p)">{{ p }}</a>
            </li>
        </template>

        <template v-if="showLast">
            <li class="page-item disabled"><a class="page-link" href="#">…</a></li>
            <li :class="['page-item', {active: modelValue == totalPages}]">
                <a class="page-link" href="#" @click.prevent="setPage(totalPages)">{{ totalPages }}</a>
            </li>
        </template>

        <li :class="['page-item', {disabled: isNextDisabled}]">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="setPage(modelValue + 1)">
                <span aria-hidden="true">›</span>
            </a>
        </li>

        <li class="page-item" v-if="!lastNumber">
            <a class="page-link" href="#" aria-label="Last" @click.prevent="setPage(totalPages)">
                <span aria-hidden="true">»</span>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        compatConfig: {
            COMPONENT_V_MODEL: false
        },
        name: "b-pagination",
        props: {
            modelValue: {type: Number},
            totalRows: {type: Number, default: 0},
            perPage: {type: Number, default: 10},
            firstNumber: {type: Boolean, default: false},
            lastNumber: {type: Boolean, default: false},
            size: {type: String},
            align: {type: String}
        },
        emits: ['update:modelValue'],
        computed: {
            totalPages: function() {
                return Math.ceil(this.totalRows / this.perPage)
            },
            alignClass: function() {
                if (!this.align) {
                    return ''
                }

                return `justify-content-${this.align}`
            },
            sizeClass: function() {
                if (!this.size) {
                    return ''
                }

                return `pagination-${this.size}`
            },
            showFirst: function() {
                return this.firstNumber && !this.visiblePages.includes(1)
            },
            showLast: function() {
                return this.lastNumber && !this.visiblePages.includes(this.totalPages)
            },
            isPrevDisabled: function() {
                return this.modelValue == 1
            },
            isNextDisabled: function() {
                return this.modelValue == this.totalPages
            },
            visiblePages: function() {
                let max_pages = 5,
                    start = Math.max(this.modelValue - 2, 1),
                    step = 1,
                    length = max_pages;

                if (this.totalPages < max_pages) {
                    length = this.totalPages
                } else if (this.modelValue + 2 > this.totalPages) {
                    start = this.totalPages - max_pages + 1
                }

                return Array.from(
                    {length: length},
                    (value, index) => start + index * step
                )
            }
        },
        watch: {
            perPage: function(val) {
                if (this.modelValue > this.totalPages) {
                    this.$emit("update:modelValue", this.totalPages)
                }
            }
        },
        methods: {
            setPage: function(num) {
                if (num >= 1 && num <= this.totalPages) {
                    this.$emit("update:modelValue", num)
                }
            }
        }
    }
</script>

<style lang="scss">
</style>