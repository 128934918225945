<template>
  <div class="row justify-content-center text-center">
        <div class="col-6">
            <template v-if="title">
                <label class="label">{{ title }}</label>
                <br />
            </template>
            <font-awesome-icon class="loader" icon="sync-alt" spin />
        </div>
    </div>
</template>

<script>
export default {
    props: ["title"]
}
</script>

<style lang="scss" scoped>
.loader {
    font-size: 2rem;
}
</style>