<template>
    <div v-if="stats">
        <dl class="row small">
            <dt class="col-sm-3">email</dt>
            <dd class="col-sm-3">{{ stats.email }}</dd>

            <dt class="col-sm-3">jméno</dt>
            <dd class="col-sm-3">{{ stats.name }}</dd>

            <dt class="col-sm-3">datum registrace</dt>
            <dd class="col-sm-3">{{ $filters.datetime(stats.registred) }}</dd>

            <dt class="col-sm-3">poslední aktivita</dt>
            <dd class="col-sm-3">{{ $filters.datetime(stats.last_activity) }} [{{ dateDist(stats.last_activity) }}]</dd>

            <dt class="col-sm-3">první aktivita</dt>
            <dd class="col-sm-3">{{ $filters.datetime(stats.first_activity) }} [{{ dateDist(stats.first_activity) }}]</dd>

            <dt class="col-sm-3">počet aktivních dní</dt>
            <dd class="col-sm-3">{{ stats.unique_days }}</dd>

            <dt class="col-sm-3">počet uložených průzkumů</dt>
            <dd class="col-sm-3">{{ stats.saved_researches_count }}</dd>

            <dt class="col-sm-3">počet dní neaktivity</dt>
            <dd class="col-sm-3">{{ stats.medians.inactive_days }}</dd>

            <dt class="col-sm-3">počet akcí</dt>
            <dd class="col-sm-3">{{ stats.medians.actions_count }}</dd>

            <dt class="col-sm-3">počet zobrazených zakázek</dt>
            <dd class="col-sm-3">{{ stats.medians.tender_details_count }}</dd>

            <dt class="col-sm-3">počet stažených dokumentů</dt>
            <dd class="col-sm-3">{{ stats.medians.documents_downloaded_count }}</dd>

            <dt class="col-sm-3">změny filtru - uložené průzkumy</dt>
            <dd class="col-sm-3">{{ stats.medians.changes_count_saved }}</dd>

            <dt class="col-sm-3">změny filtru - nedokončené průzkumy</dt>
            <dd class="col-sm-3">{{ stats.medians.changes_count_unsaved }}</dd>
        </dl>
    </div>
</template>

<script>
import userStatsMixin from '@/plugins/userStatsMixin.js'

export default {
    props: {
        data: { type: Object}
    },
    data: function() {
        return {}
    },
    mixins: [ userStatsMixin ],
    computed: {
        stats: function() {
            return this.data ? this.getUserStats(this.data) : null
        }
    }
}
</script>

<style lang="scss" scoped>
</style>