<template>
    <div class="dropdown" ref="dropdown">
        <button class="btn dropdown-toggle" :class="[variantClass]" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <slot name="button-content"/>
        </button>
        <ul class="dropdown-menu">
            <slot></slot>
        </ul>
    </div>
</template>
<script>

import { Dropdown } from 'bootstrap';
    export default {
        name: "b-dropdown",
        props: {
            variant: {
                type: String,
                default: "outline-primary",
                required: false
            }
    },
    data: function () {
        return {
            instance: null
        }
    },
    mounted: function () {
        this.instance = new Dropdown(this.$refs.dropdown, {})
    },
    unmounted: function () {
        if (this.instance) {
            this.instance.dispose();
        }
    },
    computed: {
        variantClass: function () {
            return "btn-" + this.variant;
        }
    }
}
</script>

<style lang="scss">

</style>