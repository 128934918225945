<template>
    <div :class="responsiveClass">
        <table :class="['table', {'table-striped': striped}, {'table-hover': hover}, {'table-sm': small}]">
            <thead>
                <tr>
                    <template v-for="(f, i) in tableFields" :key="`tbl-row-${i}`">
                        <th scope="col" v-if="f.sortable" >
                            <a href="#" @click.prevent="sortRows(f.name)">
                                <slot :name="`head(${f.name})`">{{ f.name }}</slot>
                            </a>
                            <span v-if="sortBy == f.name">
                                <template v-if="sortDesc">▼</template>
                                <template v-else>▲</template>
                            </span>
                        </th>
                        <th scope="col" v-else @click="resetSorting">
                            <slot :name="`head(${f.name})`">{{ f.name }}</slot>
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody>
                <tr v-if="isBusy">
                    <td :colspan="tableFields.length">
                        <slot name="table-busy"></slot>
                    </td>
                </tr>
                <tr v-else-if="showEmpty && !dataRows.length">
                    <td :colspan="tableFields.length">
                        <slot name="emptyfiltered" v-if="isFiltered"></slot>
                        <slot name="empty" v-else></slot>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="(r, i) in dataRows" :key="`tbl-row-${i}`" @click="$emit('row-clicked', r)">
                        <td v-for="c in tableFields" :key="`tbl-cell-${c}`">
                            <slot :name="`cell(${c.name})`" :value="r[c.name]" :item="r">
                                {{ r[c.name] }}
                            </slot>
                        </td>
                    </tr>
                </template>

            </tbody>
        </table>
    </div>
</template>

<script>
    class TblField {
        constructor(name, sortable=false) {
            this.name = name
            this.sortable = sortable
        }
    }

    export default {
        name: "b-table",
        props: {
            items: {},
            fields: {type: Array, required: true},
            currentPage: {type: Number, default: 0},
            perPage: {type: Number, default: 10},
            filter: {},
            sortBy: {},
            sortDesc: {type: Boolean, default: false},
            small: {type: Boolean, default: false},
            striped: {type: Boolean, default: false},
            hover: {type: Boolean, default: false},
            showEmpty: {type: Boolean, default: false},
            noSortReset: {type: Boolean, default: false},
            responsive: {type: [Boolean, String], default: false},
            filterIncludedFields: {type: Array}
        },
        data: function() {
            return {
                dataRows: [],
                isBusy: false,
                lastSortBy: null
            }
        },
        mounted: function() {
            this.loadDataRows()
        },
        watch: {
            currentPage: function() {
                this.loadDataRows()
            },
            perPage: function() {
                this.loadDataRows()
            },
            filter: function() {
                this.loadDataRows(true)
            },
            sortBy: function() {
                if (this.sortBy != this.lastSortBy) {
                    this.loadDataRows()
                }
            },
            sortDesc: function() {
                this.loadDataRows()
            }
        },
        computed: {
            tableFields: function() {
                return this.fields.map(n => {
                    if (typeof(n) == 'string') {
                        return new TblField(n)
                    } else {
                        return new TblField(n.key, n.sortable)
                    }
                })
            },
            isFiltered: function() {
                if (this.filter) {
                    return Object.keys(this.filter).length > 0
                }

                return false
            },
            responsiveClass: function() {
                if (this.responsive === true) {
                    return 'table-responsive'
                }

                if (this.responsive != null) {
                    return `table-responsive-${this.responsive}`
                }

                return null
            }
        },
        methods: {
            filterValue: function(value, filter) {
                if (value == null) {
                    return false
                }

                if (Array.isArray(value)) {
                    return value.some(n => this.filterValue(n, filter))
                } else if (typeof value === 'object')  {
                    return JSON.stringify(value).includes(filter)
                }

                return value.includes(filter)
            },
            loadDataRows: async function(emitFiltered) {
                this.lastSortBy = this.sortBy

                if (Array.isArray(this.items)) {
                    let rows = this.items

                    // filter items
                    if (this.filter) {
                        let filteredFields = this.filterIncludedFields || this.fields.map(n => n.key)
                        rows = rows.filter(n => filteredFields.some(f => this.filterValue(n[f], this.filter)))
                    }

                    if (emitFiltered) {
                        this.$emit("filtered", rows)
                    }

                    // sort items
                    if (this.sortBy) {
                        rows.sort((a, b) => {
                            if (a[this.sortBy] < b[this.sortBy]) {
                                return -1
                            } else if (a[this.sortBy] > b[this.sortBy]) {
                                return 1
                            }
                            return 0
                        })

                        if (this.sortDesc) {
                            rows.reverse()
                        }
                    }

                    // get current page of items
                    rows = rows.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)

                    this.dataRows = rows
                }

                if (this.items instanceof Function) {
                    let result = this.items({
                        currentPage: this.currentPage,
                        perPage: this.perPage,
                        sortBy: this.sortBy,
                        sortDesc: this.sortDesc,
                        filter: this.filter
                    })

                    if (result instanceof Promise) {
                        try {
                            this.isBusy = true
                            this.dataRows = await result
                        } catch (err) {
                            this.dataRows = []
                        } finally {
                            this.isBusy = false
                        }
                    } else {
                        this.dataRows = result
                    }
                }
            },
            refresh: function() {
                this.loadDataRows()
            },
            sortRows: async function(fieldName) {
                if (this.lastSortBy != fieldName) {
                    this.$emit("update:sortDesc", false)
                } else {
                    this.$emit("update:sortDesc", !this.sortDesc)
                }

                this.$emit("update:sortBy", fieldName)

                this.$emit("sorted")
            },
            resetSorting: function() {
                if (this.noSortReset) {
                    this.$emit("update:sortDesc", false)
                    this.$emit("update:sortBy", null)
                    this.$emit("sorted")
                }
            }
        }

    }
</script>

<style lang="scss">

</style>