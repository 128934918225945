<template>
    <div v-if="data" class="row justify-content-between">
        <template v-for="(d, t, i) in data" :key="i" >
            <div class="col-3 m-4">
                <div class="row text-muted">{{ t }}</div>
                <div class="row text-center">
                    <div class="col-4">
                        <strong class="h2">{{ d.day }}</strong><br><small class="text-muted text-nowrap">den</small>
                    </div>
                    <div class="col-4">
                        <strong class="h2">{{ d.week }}</strong><br><small class="text-muted text-nowrap">týden</small>
                    </div>
                    <div class="col-4">
                        <strong class="h2">{{ d.month }}</strong><br><small class="text-muted text-nowrap">měsíc</small>
                    </div>
                </div>
            </div>

            <div v-if="(i + 1) % 3 == 0" class="w-100" :key="'break-' + i"></div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Object}
    }
}
</script>

<style lang="scss" scoped>
</style>