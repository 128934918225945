<template>
    <nav class="navbar" :class="[expand, navbarType, fixedClass]">
        <slot></slot>
  </nav>
</template>
<script>
    export default {
        name: "b-navbar",
        props: {
            fixed: {
                type: String,
                required: false,
                default: null
            },
            type: {
                type: String,
                required: false,
                default: "light"
            },
            toggleable: {
                type: String,
                required: false,
                default: "lg"
            },
        },
        computed: {
            expand: function () {
                return "navbar-expand-" + this.toggleable;
            },
            navbarType: function () {
                return "navbar-" + this.type;
            },
            fixedClass: function () {
                if(!this.fixed){
                    return ""
                }
                return "fixed-" + this.fixed
            }
        }
    }
</script>

<style lang="scss">

</style>