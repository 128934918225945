export default {
    methods: {
        buildTenderLog: function(tender) {
            return {
                id: tender.id,
                datlab_id: tender.datlab_id,
                persistent_id: tender.persistent_id,
                source: tender.source,
                title: tender.name
            }
        },
        buildSupplierLog: function(supplier) {
            let log = {
                supplier: {
                    id: supplier.id,
                    name: supplier.name,
                    organization_id: supplier.organization_id,
                    email: supplier.contact_email
                }
            }

            if (supplier.tenders) {
                log["tenders"] = supplier.tenders.map(t => this.buildTenderLog(t))
            }

            return log
        },
        buildCriteriaLog: function(criteria, regions, cpvs, suppliers) {
            regions = regions || null
            cpvs = cpvs || null
            suppliers = suppliers || null

            let awardCriterias = criteria?.award_criterias || null

            let log = { ...criteria }
            delete log["cpv_ids"]
            delete log["works_region_selector"]
            delete log["award_criterias"];
            delete log["requested_ids"];
            delete log["research_id"];
            delete log["research_name"];
            delete log["supplier_ids"]

            log["cpvs"] = cpvs
            log["regions"] = regions
            log["awardCriterias"] = awardCriterias
            log["suppliers"] = suppliers

            return log
        }
    }
}
