<template>
    <Container :fluid="false">
        <Header :title="title" :subtitle="subtitle" class="mt-0" />

        <SearchForm @search-form-submit="() => {
            $emit('search-form-submit')
            $ebus.$emit('close-overlay')
        }" />
    </Container>
</template>

<script>
import Container from "@/components/overlay/Container.vue";
import Header from "@/components/Header.vue";
import SearchForm from "@/components/SearchForm.vue";

export default {
    computed: {
        research: function() {
            return this.$store.getters.research
        },
        title: function () {
            return this.research && this.research.id ? this.$t("research.form.edit.label") : this.$t('research.form.create.label')
        },
        subtitle: function() {
            if (this.research && this.research.name) {
                return this.research.name
            }

            switch (this.research.research_type) {
                case "MarketResearchType.TENDERS":
                    return this.$t('research.references').toUpperCase()
                case "MarketResearchType.SUPPLIERS":
                    return this.$t('research.suppliers').toUpperCase()
                case "MarketResearchType.SUPPLIER_TENDERS":
                    return this.$t('research.supplierTenders').toUpperCase()
            }

            return null
        }
    },
    components: {
        Container,
        Header,
        SearchForm
    }
}
</script>

<style>

</style>