<template>
    <span class="badge" :class="[variantClass, pillClass]">
        <slot></slot>
    </span>
</template>
<script>
    export default {
        name: "b-badge",
        props: {
            variant: {
                type: String,
                required: false,
                default: "light"
            },
            pill: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        computed: {
            variantClass: function(){
                return "badge-" + this.variant
            },
            pillClass: function () {
                if (this.pill) {
                    return "badge-pill"
                }
                return ""
            }
        }
    }
</script>

<style lang="scss">

</style>