<template>
    <Loader v-if="busy" />
    <div v-else class="search-form">
        <b-form>
            <b-form-group>
                <b-form-row>
                    <div class="col-2 search-title">
                        <label v-if="isFulltextSearch" class="label">{{ $t('searchForm.fulltext.label') }}</label>
                        <label v-else class="label">{{ $t('searchForm.cpv.label') }}</label>
                    </div>
                    <div class="col-9 text-end">
                        <label class="toggle mb-2">
                            <input name="fulltext_search" id="id_fulltext_search" v-model="searchValues.fulltext_search" type="checkbox" />
                            <span class="toggleSlider"></span>
                            <span class="toggle-left noselect" :class="{active: !searchValues.fulltext_search}">{{ $t('searchForm.toggler.cpv') }}</span>
                            <span class="toggle-right noselect" :class="{active: searchValues.fulltext_search}">{{ $t('searchForm.toggler.fulltext') }}</span>
                        </label>
                    </div>
                </b-form-row>

                <b-form-row class="align-items-center">
                    <div class="col-11" v-if="isFulltextSearch">
                        <b-form-input
                            type="text"
                            :placeholder="$t('searchForm.fulltext.placeholder')"
                            size="lg"
                            name="fulltext_keywords"
                            id="id_fulltextKeywords"
                            v-model="searchValues.fulltext_keywords"
                            @keydown.enter.prevent
                        />
                    </div>
                    <div class="col-11" v-else>
                        <multiselect
                            v-model="cpvValue"
                            :options="cpvOptions"
                            :multiple="true"
                            :searchable="true"
                            :show-labels="false"
                            :placeholder="$t('searchForm.cpv.placeholder')"
                            label="name"
                            track-by="name"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="300"
                            :max-height="600"
                            :show-no-results="true"
                            :hide-selected="false"
                            @search-change="searchCpvs"
                        >
                            <template #option="{option, search}">
                                <span class="form-row">
                                    <span class="col-11 multiselect-text-start">{{ option.name }}</span>
                                    <span v-if="isTenderSearchForm" class="col-1 multiselect-text-end">{{ option.tender_count }}</span>
                                    <span v-else class="col-1 multiselect-text-end">{{ option.supplier_count }}</span>
                                </span>
                            </template>
                            <template #noOptions>
                                <span>{{ $t("searchForm.cpv.tooltip.lessThanThree") }}</span>
                            </template>
                            <template #noResult="data">
                                <span>
                                    <template v-if="data.search.length < 3">
                                        {{ $t("searchForm.cpv.tooltip.lessThanThree") }}
                                    </template>
                                    <template v-else-if="isCpvsLoading">
                                        {{ $t("searchForm.supplier.tooltip.busy") }}
                                    </template>
                                    <template v-else>
                                        {{ $t("searchForm.cpv.tooltip.empty.0") }}
                                        <b-link href="#" @click="switchToFulltext(data.search)" class="link-primary">{{ $t("searchForm.cpv.tooltip.empty.1") }}</b-link>.
                                    </template>
                                </span>
                            </template>
                        </multiselect>
                        <input type="hidden" name="cpvs" id="id_cpvs" v-model="searchValues.cpv_ids" />
                    </div>

                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.cpv" />
                    </div>
                </b-form-row>
            </b-form-group>

            <b-form-row>
               <div class="col-11">
                    <label class="label">{{ $t("searchForm.price.value") }}</label>
                </div>

                <div class="col-11 price_histogram">
                    <Histogram v-if="countsHistogram && countsHistogram.length" :data="countsHistogram" />
                    <div v-else id="empty_histogram"></div>
                </div>

                <div class="col-11 price_slider">
                    <vue-slider
                        v-bind="{min: 0, max: maxPrice, height: 6, tooltip: 'none'}"
                        v-model="minMaxPrice" ref="minMaxPriceSlider" :silent="true"/>
                </div>

                <div class="col-1 count_badge">
                    <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.price" />
                </div>
            </b-form-row>
            <b-form-row>
                <div class="col-8 text-start min_max_price">
                    <span v-show="!showMinPriceInput" @click="toggleShowMinPriceInput">
                        <template v-if="!searchValues">0</template>
                        <template v-else>{{ $filters.number($filters.integer(searchValues.min_price, 0)) }}</template>
                    </span>
                    <span v-show="showMinPriceInput">
                        <b-form-input
                            @blur="toggleShowMinPriceInput"
                            size="lg"
                            type="number"
                            name="min_price"
                            id="id_min_price"
                            v-model="minPriceEditHelper"
                            ref="minPriceInput"
                            @keydown.enter.prevent="$refs['minPriceInput'].blur()"
                            min="0"
                            :max="maxPrice"
                        />
                    </span>
                    -
                    <span v-show="!showMaxPriceInput" @click="toggleShowMaxPriceInput">
                        <template v-if="!searchValues || searchValues.max_price === null">{{ $filters.number($filters.integer(maxPrice)) }}+</template>
                        <template v-else>{{ $filters.number($filters.integer(searchValues.max_price)) }}</template>
                    </span>
                    <span v-show="showMaxPriceInput">
                        <b-form-input
                            @blur="toggleShowMaxPriceInput"
                            size="lg"
                            type="number"
                            name="max_price"
                            id="id_max_price"
                            v-model="maxPriceEditHelper"
                            ref="maxPriceInput"
                            @keydown.enter.prevent="$refs['maxPriceInput'].blur()"
                            min="0"
                            :max="maxPrice"
                        />
                    </span>
                    {{ $t("currency.czk") }}
                </div>
                <input type="hidden" name="min_price" required id="hidden_id_min_price" v-model="searchValues.min_price" />
                <input type="hidden" name="max_price" required id="hidden_id_max_price" v-model="searchValues.max_price" />
                <input type="hidden" name="results_price_counts" required id="hidden_id_results_price_counts" v-model="countsHistogram" />
            </b-form-row>

            <b-form-row class="mb-3 align-items-center">
                <div class="col col-11">
                    <b-form-group :label="$t('searchForm.works.label')" label-class="label" class="works-group">
                        <!-- <div class="custom-control custom-radio">
                            <input v-model="works" type="radio" name="works" id="works_europe" value="works_europe" checked class="custom-control-input">
                            <label class="custom-control-label" for="works_europe">{% trans "Evropa" %}</label>
                        </div>-->
                        <b-form-radio-group v-model="searchValues.works" :options="worksRadios" name="works" stacked />
                        <div class="works-region-multiselect col-12" v-show="isWorksRegionSelected">
                            <multiselect
                                v-model="worksRegionValue"
                                :options="worksRegionOptions"
                                :multiple="true"
                                :searchable="true"
                                :show-labels="false"
                                :placeholder="$t('searchForm.works.placeholder')"
                                label="name"
                                track-by="name"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :options-limit="300"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="false"
                            ></multiselect>
                            <input type="hidden" name="works_region_selector" id="id_work_regions" v-model="searchValues.works_region_selector" />
                        </div>
                    </b-form-group>
                </div>
                <div class="col-1 count_badge">
                    <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.works" />
                </div>
            </b-form-row>

            <b-form-group>
                <b-form-row class="align-items-center">
                    <div class="col-5">
                        <label class="form-check-label plus-minus-label">{{ $t("searchForm.age.label") }}</label>
                    </div>
                    <div class="col-6 text-end plus-minus-element no-select">
                        <a
                            class="decrease align-middle"
                            @mousedown="decreaseContractAgePushed"
                            @mouseup="decreaseContractAgeReleased"
                            @mouseleave="decreaseContractAgeReleased"
                            v-bind:class="{ disabled: isDecreaseActive }"
                        >
                            <font-awesome-icon icon="minus-circle" />
                        </a>
                        <div class="months-label">{{ $tc("searchForm.age.months", searchValues.contract_age) }}</div>
                        <a
                            class="increase align-middle"
                            @mousedown="increaseContractAgePushed"
                            @mouseup="increaseContractAgeReleased"
                            @mouseleave="increaseContractAgeReleased"
                        >
                            <font-awesome-icon icon="plus-circle" />
                        </a>
                    </div>
                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.age" />
                    </div>
                    <input type="hidden" name="contract_age" id="id_contract_age" v-model="searchValues.contract_age" />
                </b-form-row>
            </b-form-group>

            <template v-if="isTenderSearchForm">
                <b-form-row class="mb-3 align-items-center">
                   <div class="col-9">
                        <label class="form-check-label d-block">{{ $t("searchForm.documentation.label") }}</label>
                        <label class="form-check-label small text-dark">{{ $t("searchForm.documentation.info") }}</label>
                    </div>
                    <div class="col-2 text-end">
                        <div class="form-check">
                            <label class="switch">
                                <input
                                    name="documentation_specification"
                                    value="true"
                                    id="id_documentation_specification"
                                    v-model="searchValues.documentation_specification"
                                    type="checkbox"
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.specification" />
                    </div>
                </b-form-row>

                <b-form-row class="mb-3 align-items-center">
                   <div class="col-9">
                        <label class="form-check-label d-block">{{ $t("searchForm.contracts.label") }}</label>
                        <label class="form-check-label small text-dark">{{ $t("searchForm.contracts.info") }}</label>
                    </div>
                    <div class="col-2 text-end">
                        <div class="form-check">
                            <label class="switch">
                                <input
                                    name="documentation_contract"
                                    value="true"
                                    id="id_documentation_contract"
                                    v-model="searchValues.documentation_contract"
                                    type="checkbox"
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.contract" />
                    </div>
                </b-form-row>

                <b-form-row class="mb-3 align-items-center">
                   <div class="col-9">
                        <label class="form-check-label d-block">{{ $t("searchForm.meat.label") }}</label>
                        <label class="form-check-label small text-dark">{{ $t("searchForm.meat.info") }}</label>
                    </div>
                    <div class="col-2 text-end">
                        <div class="form-check">
                            <label class="switch">
                                <input name="meat_criteria" value="true" id="id_meat_criteria" v-model="searchValues.meat_criteria" type="checkbox" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.meatCriteria" />
                    </div>
                </b-form-row>

                <b-form-row class="mb-3 align-items-center" v-if="searchValues.meat_criteria">
                    <div class="col-11 text-end">
                        <multiselect
                            v-model="searchValues.award_criterias"
                            :options="awardCriteriaOptions"
                            :multiple="true"
                            :searchable="true"
                            :show-labels="false"
                            :placeholder="$t('searchForm.awardCriteria.placeholder')"
                            :custom-label="n => $t(`searchForm.awardCriteria.values.${n}`)"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="300"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="false"
                        ></multiselect>
                        <label class="form-check-label small text-dark">{{ $t("searchForm.meat.info") }}</label>
                    </div>
                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.awardCriterias" />
                    </div>
                </b-form-row>

                <template v-if="tenderAuditEnabled">
                    <b-form-row class="mb-3 align-items-center">
                        <div class="col-9">
                            <label class="form-check-label d-block">{{ $t("searchForm.audit.label") }}</label>
                            <label class="form-check-label small text-dark">{{ $t("searchForm.audit.info") }}</label>
                        </div>
                        <div class="col-2 text-end">
                            <div class="form-check">
                                <label class="switch">
                                    <input name="tender_audit" value="true" id="id_tender_audit" v-model="searchValues.tender_audit" type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-1 count_badge">
                            <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.tenderAudit" />
                        </div>
                    </b-form-row>

                    <b-form-row class="mb-3 align-items-center" v-if="searchValues.tender_audit">
                        <div class="col-11 text-end">
                            <multiselect
                                v-model="searchValues.tender_audit_source"
                                :options="tenderAuditOptions"
                                :multiple="true"
                                :show-labels="false"
                                :searchable="true"
                                :placeholder="$t('searchForm.audit.placeholder')"
                                :custom-label="n => $t(`audit.source.${n}`)"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :options-limit="300"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="false"
                            ></multiselect>

                        </div>
                        <div class="col-1count_badge">
                            <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.tenderAuditSource" />
                        </div>
                        <div class="col-12">
                            <i class="form-input-help">{{ $t("searchForm.audit.info2") }}</i>
                        </div>
                    </b-form-row>
                </template>

                <b-form-row class="align-items-center mb-3">
                   <div class="col-11">
                        <label class="label">{{ $t("searchForm.supplier.label") }}</label>

                        <multiselect
                            v-model="suppliersValue"
                            :options="suppliersOptions"
                            :multiple="true"
                            :searchable="true"
                            :show-labels="false"
                            :placeholder="$t('searchForm.supplier.placeholder')"
                            label="name"
                            track-by="name"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :options-limit="300"
                            :option-height="72"
                            :max-height="600"
                            :show-no-results="true"
                            :hide-selected="true"
                            @search-change="searchSuppliers"
                        >
                            <template #option="{ option, search }">
                                <div class="form-row">
                                    <div class="col-10 multiselect-text-left">
                                        {{ option.name }}
                                        <br />
                                        <small class="fw-light">{{ option.ico }}</small>
                                    </div>
                                    <div class="col-2 multiselect-text-end">
                                        <small class="text-nowrap fw-light">{{ $tc("searchForm.supplier.tenders", option.tender_count) }}</small>
                                        <br />
                                        <small class="text-nowrap fw-light">{{ $tc("searchForm.supplier.contracts", option.contract_count) }}</small>
                                    </div>
                                </div>
                            </template>
                            <template #tag="{option, search, remove}">
                                <div>
                                    <span class="multiselect__tag">
                                        <span>{{ option.name }}</span> <small class="fw-light">({{ option.ico }})</small>
                                        <i tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
                                    </span>
                                </div>
                            </template>
                            <template #noOptions>
                                <div>{{ $t("searchForm.supplier.tooltip.lessThanThree") }}</div>
                            </template>
                            <template #noResult="data">
                                <div>
                                    <template v-if="data.search.length < suppliersQueryMinLength">
                                        {{ $t("searchForm.supplier.tooltip.lessThanThree") }}
                                    </template>
                                    <template v-else-if="suppliersBusy">
                                        {{ $t("searchForm.supplier.tooltip.busy") }}
                                    </template>
                                    <template v-else>
                                        {{ $t("searchForm.supplier.tooltip.empty[0]") }}
                                        <ol class="fw-light mt-1 mb-0 small">
                                            <li>{{ $t("searchForm.supplier.tooltip.empty[1]") }}</li>
                                            <li>{{ $t("searchForm.supplier.tooltip.empty[2]") }}</li>
                                        </ol>
                                    </template>
                                </div>
                            </template>
                        </multiselect>
                        <input type="hidden" name="supplier" id="id_suppliers" v-model="searchValues.supplier_ids" />
                    </div>

                    <div class="col-1 count_badge">
                        <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.suppliers" />
                    </div>
                </b-form-row>
            </template>
            <template v-else>
                <b-form-group class="mb-3">
                    <b-form-row>
                        <div class="col-6">
                            <label class="form-check-label d-block">{{ $t("searchForm.minBiggestValue.label") }}</label>
                            <label class="form-check-label small text-dark">{{ $t("searchForm.minBiggestValue.info") }}</label>
                        </div>
                        <div class="col-5 text-end">
                            <span
                                v-show="!showMinBiggestPriceInput"
                                @click="toggleShowMinBiggestPriceInput"
                            >{{ $filters.number($filters.integer(searchValues.min_biggest_price, 0)) }} {{ $t("currency.czk") }}</span>
                            <span v-show="showMinBiggestPriceInput">
                                <b-form-input
                                    @blur="toggleShowMinBiggestPriceInput"
                                    size="lg"
                                    type="number"
                                    name="min_biggest_price"
                                    required
                                    id="id_min_biggest_price"
                                    v-model="searchValues.min_biggest_price"
                                    ref="minBiggestPriceInput"
                                    @keydown.enter.prevent="$refs['minBiggestPriceInput'].blur()"
                                    min="0"
                                    :max="maxPrice"
                                />
                            </span>
                            <vue-slider
                                v-bind="{min: 0, max: maxPrice, height: 6, tooltip: 'none'}"
                                v-model="searchValues.min_biggest_price" :silent="true"/>
                        </div>
                        <div class="col-1 count_badge">
                            <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.minBiggest" />
                        </div>
                    </b-form-row>
                </b-form-group>

                <b-form-group>
                    <b-form-row>
                        <div class="col-6">
                            <label class="form-check-label d-block">{{ $t("searchForm.minThreeBiggestValue.label") }}</label>
                            <label class="form-check-label small text-dark">{{ $t("searchForm.minThreeBiggestValue.info") }}</label>
                        </div>
                        <div class="col-5 text-end">
                            <span
                                v-show="!showMinThreeBiggestPricesInput"
                                @click="toggleShowMinThreeBiggestPricesInput"
                            >{{ $filters.number($filters.integer(searchValues.min_three_biggest_prices, 0)) }} {{ $t("currency.czk") }}</span>
                            <span v-show="showMinThreeBiggestPricesInput">
                                <b-form-input
                                    @blur="toggleShowMinThreeBiggestPricesInput"
                                    size="lg"
                                    type="number"
                                    name="min_three_biggest_prices"
                                    required
                                    id="id_min_three_biggest_prices"
                                    v-model="searchValues.min_three_biggest_prices"
                                    ref="minThreeBiggestPricesInput"
                                    @keydown.enter.prevent="$refs['minThreeBiggestPricesInput'].blur()"
                                    min="0"
                                    :max="maxPrice"
                                />
                            </span>
                            <vue-slider
                                v-bind="{min: 0, max: this.maxPrice, height: 6, tooltip: 'none'}"
                                v-model="searchValues.min_three_biggest_prices" :silent="true"/>
                        </div>
                        <div  class="col-1 count_badge">
                            <BadgeSubtotal v-show="!requestRunning" :value="resultCounts.minThree" />
                        </div>
                    </b-form-row>
                </b-form-group>
            </template>

            <div class="text-center">
                <template v-if="research">
                    <input type="hidden" name="research_name" id="id_research_name" v-model="research.name" />
                    <input type="hidden" name="research_id" id="id_research_id" v-model="research.id" />
                </template>
                <div
                    v-if="isTenderSearchForm"
                    v-show="!requestRunning"
                    class="results-count"
                    v-html="$tc('searchForm.found.tender', resultCounts.total, { n : $n(resultCounts.total, 'decimal')})"
                />
                <div
                    v-else
                    v-show="!requestRunning"
                    class="results-count"
                    v-html="$tc('searchForm.found.supplier', resultCounts.total, { n : $n(resultCounts.total, 'decimal')})"
                />
                <span id="search-submit-button" class="d-inline-block" v-b-tooltip.hover :title="searchButtonTooltip">
                    <b-button type="button" variant="primary" size="lg" :disabled="requestRunning || resultCounts.total == 0 || resultCounts.total > maxResultsCount" @click="submit">
                        {{ $t("searchForm.submit.label") }}
                        <font-awesome-icon v-if="requestRunning" icon="sync-alt" spin />
                        <font-awesome-icon v-else icon="search" />
                    </b-button>
                </span>
            </div>
        </b-form>
    </div>
</template>

<script>
import { CONFIG } from "../config.js";

import axios from "axios";
import BadgeSubtotal from "@/components/BadgeSubtotal.vue";
import Histogram from "@/components/Histogram.vue";
import Loader from "@/components/Loader.vue";
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import searchFormMixin from "@/plugins/searchFormMixin.js";

export default {
    props: {},
    components: { BadgeSubtotal, Histogram, Loader },
    mixins: [applicationLogMixin, searchFormMixin],
    data: function() {
        return {
            searchValues: {
                cpv_ids: [],
                min_price: null,
                max_price: null,
                works: "works_czech",
                works_region_selector: [],
                contract_age: 36,
                documentation_specification: false,
                documentation_contract: false,
                meat_criteria: false,
                min_biggest_price: 0,
                min_three_biggest_prices: 0,
                fulltext_search: true,
                fulltext_keywords: "",
                supplier: null,
                award_criterias: [],
                supplier_ids: [],
                tender_audit: false,
                tender_audit_source: []
            },
            cpvValue: null,
            worksRegionValue: null,
            requestRunning: false,
            fulltextHelper: {},
            cpvOptions: [],
            isCpvsLoading: false,
            resultCounts: {
                total: 0,
                cpv: 0,
                price: 0,
                works: 0,
                age: 0,
                specification: 0,
                contract: 0,
                meatCriteria: 0,
                minThree: 0,
                minBiggest: 0,
                winner: 0,
                awardCriterias: 0,
                suppliers: 0,
                tenderAudit: 0,
                tenderAuditSource: 0
            },
            minMaxPrice: [0, this.maxPrice],
            minPriceEditHelper: 0,
            maxPriceEditHelper: this.maxPrice,
            showMinBiggestPriceInput: false,
            showMinThreeBiggestPricesInput: false,
            showMinPriceInput: false,
            showMaxPriceInput: false,
            isDecreaseActive: false,
            cancelToken: null,
            clickTimer: null,
            countsHistogram: [],
            busy: false,
            awardCriteriaOptions: [
                "QUALITY",
                "INNOVATION",
                "QUALIFICATION",
                "SOCIAL",
                "ENVIRONMENT",
                "LIFECYCLE_COSTS",
                "DEADLINES",
                "OTHERS"
            ],
            suppliersValue: null,
            suppliersOptions: [],
            suppliersBusy: false,
            suppliersCancelToken: null,
            suppliersTimer: null,
            suppliersQueryMinLength: 3
        };
    },
    computed: {
        searchButtonTooltip: function () {
            if (this.resultCounts.total == 0) {
                return this.$t('searchForm.submit.tooltip.noResults');
            }
            if (this.resultCounts.total > this.maxResultsCount) {
                return this.$t('searchForm.submit.tooltip.moreThan', {n: this.maxResultsCount});
            }
            return '';
        },
        maxResultsCount: () => 150,
        maxPrice: () => 20000000,
        countsRequestDelay: () => 700,
        isTenderSearchForm: function() {
            return this.research == null
                || this.research.research_type == "MarketResearchType.TENDERS"
                || this.research.research_type == "MarketResearchType.SUPPLIER_TENDERS"
        },
        worksRegionOptions: function() {
            if (Array.isArray(this.regions)) {
                return this.regions.map(n => {
                    return { id: n.id, name: n.name };
                });
            }

            return [];
        },
        isWorksRegionSelected: function() {
            return this.searchValues.works == "works_region";
        },
        apiResultCountUrl: function() {
            return this.isTenderSearchForm
                ? CONFIG.api.endpoints.tender.count
                : CONFIG.api.endpoints.supplier.count;
        },
        minPriceText: function() {
            var def = "0"
            return this.searchValues
                ? this.getPriceText(this.searchValues.min_price, def)
                : def;
        },
        maxPriceText: function() {
            return this.searchValues
                ? this.getPriceText(this.searchValues.max_price, this.maxPrice)
                : this.getPriceText(this.maxPrice);
        },
        worksRadios: function() {
            return [
                {
                    text: this.$t("searchForm.works.radio[0]"),
                    value: "works_czech"
                },
                {
                    text: this.$t("searchForm.works.radio[1]"),
                    value: "works_region"
                }
            ];
        },
        isFulltextSearch: function() {
            return !!this.searchValues.fulltext_search;
        },
        countsRequestPayload: function() {
            var data = { ...this.searchValues };

            delete data.requested_ids;
            delete data.research_id;
            delete data.research_name;

            return data;
        },
        research: function() {
            return this.$store.getters.research;
        },
        countRequestDisabled: function() {
            return !this.research
        },
        tenderAuditOptions: function() {
            return [
                "OPZP_MZP",
                "OPPIK_MPO",
                "OPTP_MMR",
                "OPZ_MPSV",
                "OPD_MD",
                "IROP_MMR",
                "UOHS"
            ]
        },
        tenderAuditEnabled: () => CONFIG.tenderAuditEnabled
    },
    created: function() {
        this.busy = true
        this.loadRegions()
            .then(() => this.setResearchPrerequisites(this.research))
            .then(() => this.planCountsRequest())
            .finally(() => this.busy = false)

        // by creation award criteria have to be all selected
        this.searchValues.award_criterias = this.awardCriteriaOptions
    },
    watch: {
        searchValues: {
            handler: function() {
                this.planCountsRequest();
            },
            deep: true
        },
        "searchValues.min_price": function(value) {
            this.minMaxPrice = [isNaN(parseInt(value)) ? 0 : value, this.minMaxPrice[1]]
        },
        "searchValues.max_price": function(value) {
            this.minMaxPrice = [this.minMaxPrice[0], isNaN(parseInt(value)) ? this.maxPrice : value]
        },
        minMaxPrice:  {
            handler: function (newValue) {
                var newMin = parseInt(newValue[0]);
                var newMax = parseInt(newValue[1]);
                this.searchValues.min_price = newMin == 0 ? null : newMin;
                this.searchValues.max_price = newMax == this.maxPrice ? null : newMax;
            },
            deep: true
        },
        cpvValue: {
            handler: function(newValue) {
                if (Array.isArray(newValue)) {
                    this.searchValues.cpv_ids = newValue.map(n => n.id);
                }
            },
            deep: true
        },
        worksRegionValue: {
            handler: function(newValue) {
                if (Array.isArray(newValue)) {
                    this.searchValues.works_region_selector = newValue.map(n => n.id);
                }
            },
            deep: true
        },
        suppliersValue: {
            handler: function (newValue) {
                if (Array.isArray(newValue)) {
                    this.searchValues.supplier_ids = newValue.map(n => n.id);
                }
            },
            deep: true
        },
        research:{
            handler: function () {
                if (this.research) {
                    this.busy = true
                    this.loadRegions()
                        .then(() => this.setResearchPrerequisites(this.research))
                        .then(() => this.planCountsRequest())
                        .finally(() => this.busy = false)
                }
            },
            deep: true
        }
    },
    methods: {
        toLocaleString: function(input) {
            return input ? input.toLocaleString("cs-CZ") : "";
        },
        searchCpvs: function(query) {
            this.cpvOptions = [];

            if (query.length > 2) {
                this.isCpvsLoading = true

                this.$store.getters.api
                    .get(this.apiCpvFilterUrl(query), {})
                    .then(response => this.cpvOptions = response.data)
                    .catch(e => console.log(e))
                    .finally(() => this.isCpvsLoading = false)
            } else {
                this.cpvOptions = [];
            }
        },
        toggleShowMinBiggestPriceInput: function() {
            this.showMinBiggestPriceInput = !this.showMinBiggestPriceInput;
            if (this.showMinBiggestPriceInput) {
                this.$refs.minBiggestPriceInput.focus();
            }

            this.searchValues.min_biggest_price = this.fixPriceValue(this.searchValues.min_biggest_price)
        },
        toggleShowMinThreeBiggestPricesInput: function() {
            this.showMinThreeBiggestPricesInput = !this.showMinThreeBiggestPricesInput;
            if (this.showMinThreeBiggestPricesInput) {
                this.$refs.minThreeBiggestPricesInput.focus();
            } else {
                this.searchValues.min_three_biggest_prices = this.fixPriceValue(this.searchValues.min_three_biggest_prices)
            }
        },
        toggleShowMinPriceInput: function() {
            this.showMinPriceInput = !this.showMinPriceInput;
            if (this.showMinPriceInput) {
                this.minPriceEditHelper = this.searchValues.min_price == null ? 0 : this.searchValues.min_price;
                this.$refs.minPriceInput.focus();
            } else if (this.minPriceEditHelper != null) {
                this.minPriceEditHelper = this.fixPriceValue(this.minPriceEditHelper)
                var min = parseInt(this.minPriceEditHelper)
                var max = this.minMaxPrice[1]
                if (min > max) {
                    var tmp = min
                    min = max
                    max = tmp

                    this.minPriceEditHelper = max
                    this.maxPriceEditHelper = min
                }

                this.minMaxPrice = [min, max];
            }
        },
        toggleShowMaxPriceInput: function() {
            this.showMaxPriceInput = !this.showMaxPriceInput;
            if (this.showMaxPriceInput) {
                this.maxPriceEditHelper = this.searchValues.max_price == null ? this.maxPrice : this.searchValues.max_price;
                this.$refs.maxPriceInput.focus();
            } else if (this.maxPriceEditHelper != null) {
                this.maxPriceEditHelper = this.fixPriceValue(this.maxPriceEditHelper)
                var min = this.minMaxPrice[0]
                var max = parseInt(this.maxPriceEditHelper)
                if (min > max) {
                    var tmp = min
                    min = max
                    max = tmp

                    this.minPriceEditHelper = max
                    this.maxPriceEditHelper = min
                }

                this.minMaxPrice = [min, max];
            }
        },
        fixPriceValue: function(value) {
            if (value == null) {
                return null
            } else if (value < 0) {
                return 0
            } else if (value > this.maxPrice) {
                return this.maxPrice
            }

            return value
        },
        areArraysEqual: function(a, b) {
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length != b.length) return false;

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }

            return true;
        },
        setPricesForGraph: function(data) {
            this.graphIsUpdating = true;

            var newMinPrice = data.minPrice;

            if (this.searchValues.min_price == null) {
                this.minMaxPrice = [newMinPrice, this.minMaxPrice[1]];
            }

            var newMaxPrice = data.maxPrice;
            if (this.searchValues.max_price == null) {
                this.minMaxPrice = [this.minMaxPrice[0], newMaxPrice];
            }

            if (!this.areArraysEqual(this.countsHistogram, data.counts)) {
                this.countsHistogram = data.counts;
            }

            this.graphIsUpdating = false;
        },
        decreaseContractAgePushed: function() {
            this.buttonPushed = true;
            var self = this;
            function repeatDecrease() {
                if (self.buttonPushed) {
                    self.decreaseContractAge();
                    setTimeout(repeatDecrease, 200);
                }
            }

            repeatDecrease();
        },
        decreaseContractAgeReleased: function() {
            this.buttonPushed = false;
        },
        increaseContractAgePushed: function() {
            this.buttonPushed = true;
            var self = this;
            function repeatIncrease() {
                if (self.buttonPushed) {
                    self.increaseContractAge();
                    setTimeout(repeatIncrease, 200);
                }
            }

            repeatIncrease();
        },
        increaseContractAgeReleased: function() {
            this.buttonPushed = false;
        },
        decreaseContractAge: function() {
            if (this.searchValues.contract_age > 6) {
                this.searchValues.contract_age--;
            }

            if (this.searchValues.contract_age == 6) {
                this.isDecreaseActive = true;
            }
        },
        increaseContractAge: function() {
            this.searchValues.contract_age++;

            if (this.searchValues.contract_age == 7) {
                this.isDecreaseActive = false;
            }
        },
        planCountsRequest: function() {
            // mark request as running
            this.requestRunning = true;

            // check whether there is call to server already planned
            if (this.clickTimer) {
                clearTimeout(this.clickTimer);
            }

            if (this.countRequestDisabled) {
                this.requestRunning = false;
                return;
            }

            // check whether there is a call to server running
            // if so, cancel the request, new one will be planned instead
            if (this.cancelToken != null) {
                this.cancelToken.cancel("Operation canceled by the user.");
                this.cancelToken = null;
            }

            var req_uuid = this.$uuid.v4()

            // plan new call to server
            this.clickTimer = setTimeout(() => {
                this.loadCriteriaLog()
                    .then(log => {
                        this.$log('FILTER_MODIFICATION_BEFORE', {
                            criteria: log,
                            type: this.research.research_type,
                            uuid: req_uuid
                        })

                        var created = new Date(Date.now())

                        this.readCountFromServer()
                            .then(() => this.$log('FILTER_MODIFICATION_AFTER', {
                                    counts: this.resultCounts,
                                    criteria: log,
                                    type: this.research.research_type,
                                    uuid: req_uuid
                                }, created))
                            .catch(e => {
                                if (axios.isCancel(e)) {
                                    console.log("Request canceled", e.message);
                                } else {
                                    throw e
                                }
                            })
                    })
            }, this.countsRequestDelay)
        },
        readCountFromServer: function() {
            return new Promise((resolve, reject) => {
                // cancel already running request
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                }

                // initiate cancel token used to identify, whether ther is a request running already
                this.cancelToken = axios.CancelToken.source();

                // send request to server
                this.$store.getters.api
                    .post(this.apiResultCountUrl, this.countsRequestPayload, {
                        cancelToken: this.cancelToken.token
                    })
                    .then(response => {
                        this.setPricesForGraph({
                            minPrice: 0,
                            maxPrice: this.maxPrice,
                            counts: response.data.prices != null ? response.data.prices.counts : []
                        })

                        this.resultCounts = {
                            total: response.data.final,
                            cpv: response.data.cpv_filter,
                            price: response.data.price_filter,
                            works: response.data.works_filter,
                            age: response.data.age_filter,
                            specification: response.data.specification_filter,
                            contract: response.data.contract_filter,
                            meatCriteria: response.data.meat_criteria_filter,
                            minThree: response.data.min_three_filter,
                            minBiggest: response.data.min_biggest_filter,
                            winner: response.data.winner,
                            awardCriterias: response.data.award_criterias_filter,
                            suppliers: response.data.suppliers_filter,
                            tenderAudit: response.data.tender_audit_filter,
                            tenderAuditSource: response.data.tender_audit_source_filter
                        };

                        this.requestRunning = false;
                        this.cancelToken = null;

                        resolve()
                    })
                    .catch(e => reject(e));
                })
        },
        getPriceText: function(value, def) {
            if (value === null) {
                value = def;
            }

            return this.toLocaleString(value) + (value == this.maxPrice ? "+" : "")
        },
        submit: function() {
            this.$store.commit("updateResearch", {
                search_criteria: this.searchValues
            });

            this.loadCriteriaLog()
                .then(log => this.$log('RESEARCH_CREATION', {
                        counts: this.resultCounts,
                        criteria: log,
                        type: this.research.research_type
                    })
                ).then(() => this.$emit("search-form-submit"))
        },
        apiCpvFilterUrl: function(query) {
            return this.isTenderSearchForm
                ? CONFIG.api.endpoints.tender.cpvFilter(query)
                : CONFIG.api.endpoints.supplier.cpvFilter(query);
        },
        setResearchPrerequisites: function(research) {
            return new Promise((resolve) => {
                if (research && research.search_criteria) {
                    this.searchValues = { ...research.search_criteria };
                }

                Promise.all([
                    this.getCpvsByIds(this.searchValues.cpv_ids),
                    this.getRegionsByIds(this.searchValues.works_region_selector),
                    this.getSuppliersByIds(this.searchValues.supplier_ids)
                ])
                .then(([cpvs, regions, suppliers]) => {
                    this.cpvValue = cpvs.map(n => { return {id: n.id, name: n.name} })

                    this.worksRegionValue = regions.map(n => { return {id: n.id, name: n.name} })

                    this.suppliersValue = suppliers

                    resolve()
                })
            })
        },
        switchToFulltext: function(input) {
            this.searchValues.fulltext_keywords = input
            this.searchValues.fulltext_search = true
        },
        loadCriteriaLog: function() {
            return new Promise((resolve) => {
                Promise.all([
                    this.getCpvsByIds(this.searchValues.cpv_ids),
                    this.getRegionsByIds(this.searchValues.works_region_selector)
                ])
                .then(([cpvs, regions]) => resolve(this.buildCriteriaLog(this.searchValues, regions, cpvs, this.suppliersValue)))
            })
        },
        searchSuppliers: function(query) {
            this.suppliersOptions = []

            this.suppliersBusy = true

            if (this.suppliersTimer) {
                clearTimeout(this.suppliersTimer)
            }

            this.query = query;
            if (query.length >= this.suppliersQueryMinLength) {
                // cancel already running request
                // check whether there is a call to server running
                // if so, cancel the request, new one will be planned instead
                if (this.suppliersCancelToken != null) {
                    this.suppliersCancelToken.cancel("Operation canceled by the user.")
                    this.suppliersCancelToken = null
                }

                this.suppliersTimer = setTimeout(() => {
                    // initiate cancel token used to identify, whether ther is a request running already
                    this.suppliersCancelToken = axios.CancelToken.source()
                    // if busy text is closed renew it
                    this.suppliersBusy = true
                    this.$store.getters.api
                        .get(CONFIG.api.endpoints.subjects(query), { cancelToken: this.suppliersCancelToken.token })
                        .then(response => this.suppliersOptions = response.data)
                        .catch(e => console.log(e))
                        .finally(() => {
                            this.suppliersBusy = false
                            this.suppliersCancelToken = null
                        })
                }, 500)
            } else {
                this.suppliersOptions = []
                this.suppliersBusy = false
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/scss/custom";

#id_fulltextKeywords {
    max-height: 50px;
}

.bar_container {
    position: relative;
    display: inline-block;
}

.form-row .price_slider {
    padding-left: 11px;
    padding-right: 12px;
}

.price_histogram {
    position: relative;
    top: 5px;
}

.count_badge {
    height: 24px;
}

.form-input-help {
    display: block;
    margin-top: 0.25rem;
    font-style: italic;
    font-size: 12px;
    color: $dark;
}
</style>