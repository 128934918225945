<template>
    <div>
        <Overlay v-if="overlay.component" v-bind="overlay" v-model:show="showOverlay" />

        <header v-if="!hideNavbar">
            <b-navbar toggleable="md" type="dark" fixed="top" :class="{shadow: scrollPosition > 0}">
                <div class="container-fluid">
                    <b-navbar-brand to="/main_page">
                        <img class="datlab-logo" :src="baseUrl + '/img/datlab_logo.svg'">
                        <h4>TENDERMAN</h4>
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse">
                        <font-awesome-icon icon="bars" />
                    </b-navbar-toggle>

                    <b-collapse id="nav-collapse" :isNav="true">
                        <b-navbar-nav class="ms-auto">
                            <b-nav-item to="/main_page">{{ $t('nav.research') }}</b-nav-item>

                            <b-nav-item to="/research_list">{{ $t('nav.history') }}</b-nav-item>

                            <b-nav-item :href="apiUrl + '/faq'" target="_blank">{{ $t('nav.help') }}</b-nav-item>

                            <b-nav-item-dropdown v-if="user" right>
                                <template v-slot:button-content>
                                    <font-awesome-icon icon="user"/>&nbsp;&nbsp;{{ user.username }}
                                </template>
                                <b-dropdown-item :href="apiUrl + '/password'"><div class="menu-icon"><font-awesome-icon icon="key" /></div> {{ $t('nav.changePass') }}</b-dropdown-item>
                                <b-dropdown-item :href="apiUrl + '/logout'"><div class="menu-icon"><font-awesome-icon icon="sign-out-alt" /></div> {{ $t('nav.logout') }}</b-dropdown-item>
                                <b-dropdown-item @click="$ebus.$emit('show-overlay', {component: 'company-form', showBackButton: false})">
                                    <div class="menu-icon"><font-awesome-icon :icon="['far', 'building']" /></div>
                                    {{ $t('companyForm.title') }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-navbar-nav>
                    </b-collapse>
                </div>
            </b-navbar>
        </header>
        <header v-else>
            <b-navbar toggleable="md" type="dark" fixed="top" :class="{shadow: scrollPosition > 0}">
                <div class="container-fluid">
                    <b-navbar-brand>
                        <img class="datlab-logo" :src="baseUrl + '/img/datlab_logo.svg'">
                        <h4>TENDERMAN</h4>
                    </b-navbar-brand>
                </div>
            </b-navbar>
        </header>

        <router-view ref="view"/>
    </div>
</template>

<script>
import { CONFIG } from './config.js';
import Overlay from '@/components/overlay/Overlay.vue'

export default {
    name: 'app',
    data: function() {
        return {
            showOverlay: false,
            overlay: {},
            scrollPosition: 0
        }
    },
    components: { Overlay },
    mounted: function() {
        this.$ebus.$on("show-overlay", evt => {
            if (this.showOverlay) {
                this.$ebus.$emit('overlay-update-content', evt)
            } else {
                this.showOverlay = true
                this.overlay = evt
            }
        })

        this.$ebus.$on("close-overlay", () => this.showOverlay = false)

        document.body.addEventListener('scroll', this.updateScrollPosition)
    },
    watch: {
        isLoggedIn: function() {
            if (this.isLoggedIn && !this.user.company && !this.$cookies.get(this.cookieName)) {
                this.$ebus.$emit('show-overlay', {component: 'company-form-on-start', showBackButton: false})
                this.$cookies.set(this.cookieName, 1)
            }
        },
        showOverlay: function(value) {
            if (!value && ['search-form', 'research-save-form'].indexOf(this.overlay.component) > -1) {
                this.overlay = {}
            }
        }
    },
    computed: {
        user: function() {
            return this.$store.getters.user || {}
        },
        isLoggedIn: function() {
            return this.$store.getters.isLoggedIn
        },
        apiUrl: function() {
            return CONFIG.api.baseUrl
        },
        cookieName: () => "missing-company-name-reminded",
        hideNavbar: function() {
            return this.$store.getters.isExternal
        },
        baseUrl: function() {
            return CONFIG.vueAppUrl
        }
    },
    methods: {
        updateScrollPosition: function() {
            this.scrollPosition = document.body.scrollTop
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/main.scss';

.nav-item.dropdown .dropdown-item {
    cursor: pointer;
}
</style>
