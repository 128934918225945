<template>
    <ResearchResultList
        research-type="MarketResearchType.SUPPLIERS"
        :is-save-enabled="isSaveEnabled"
        :selected="selected"
        @search-criteria-changed="refreshData"
        @research-prerequisities-loaded="lockItemLoaders = false"
    >
        <ResearchResultListTable
            row-component="research-result-list-supplier-row"
            :order-options="orderOptions"
            :default-order="defaultOrder"
            :items-loader="suppliersLoader"

            @selected-items-update="onSelectedItemsUpdate"

            ref="suppliers"
        >
            <template #found-label="{count}">
                <span v-html="$tc('result.found.supplier', count)" />
            </template>
            <template #no-items>
                <Empty :title="$t('result.nodata.suppliers.title')" :subtitle="$t('result.nodata.suppliers.subtitle')" />
            </template>
        </ResearchResultListTable>
    </ResearchResultList>
</template>


<script>
import ResearchResultList from '@/components/ResearchResultList.vue'
import ResearchResultListTable from '@/components/ResearchResultListTable.vue'
import Empty from '@/components/overlay/Empty.vue'
import { CONFIG } from '@/config.js'
import { handleError } from 'vue';

export default {
    data: function() {
        return {
            isSaveEnabled: false,
            selected: [],
            lockItemLoaders: true
        }
    },
    components: { ResearchResultList, ResearchResultListTable, Empty },
    computed: {
        research: function() {
            return this.$store.getters.research
        },
        orderOptions: function() {
            let options = [
                {
                    name: this.$t("result.order.supplier.options[0]"),
                    id: 'ref_size',
                },
                {
                    name: this.$t("result.order.supplier.options[1]"),
                    id: 'ref_count',
                },
                {
                    name: this.$t("result.order.supplier.options[2]"),
                    id: 'name',
                },
                {
                    name: this.$t("result.order.supplier.options[3]"),
                    id: 'cpv_code',
                },
                {
                    name: this.$t("result.order.supplier.options[4]"),
                    id: 'cpv_name',
                },
                {
                    name: this.$t("result.order.supplier.options[5]"),
                    id: 'subject',
                }
            ]

            return options
        },
        defaultOrder: function() {
            return this.orderOptions ? this.orderOptions[0].id : null
        }
    },
    watch: {
        selected: {
            handler() {
                this.isSaveEnabled = this.selected.length > 0
            },
            deep: true
        }
    },
    methods: {
        refreshData: function() {
            if (this.$refs["suppliers"]) {
                this.$refs["suppliers"].loadItems()
                this.$refs["suppliers"].selectedIds = []
            }
            this.selected = []
        },
        onSelectedItemsUpdate: function(selected) {
            this.selected = selected
        },
        suppliersLoader: function() {
            if (this.lockItemLoaders) {
                return null
            }

            let suppliersResponse;

            return this.$store.getters.api
                .post(CONFIG.api.endpoints.supplier.list, { criteria: this.research.search_criteria })
                .then(response => {
                    suppliersResponse = response
                    // load advanced info
                    return this.$store.getters.api.post(
                        "suppliers_advanced_info",
                        {
                            ids: response.data.map(n => n.id),
                            criteria: this.research.search_criteria
                        }
                    )
                }).then(response => {
                    // inject supplier extra fields for advanced ordering
                    suppliersResponse.data.forEach(r => {
                        let advancedInfo = response.data[r.id]
                        r.cpv = advancedInfo ? advancedInfo.cpv : null
                        r.subject = advancedInfo ? advancedInfo.subject : null
                    })

                    return new Promise(resolve => {resolve(suppliersResponse)})
                })
        }
    }
}
</script>

<style scoped lang="scss">
</style>