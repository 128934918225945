<template><b-container>
        <Loader v-if="busy" class="mt-5" />
        <template v-else>
            <ResearchHeader :type="formType" />

            <div class="rowjustify-content-center">
                <div class="col-sm-12 col-md-11 col-lg-9 col-xl-7">
                    <CheckerForm @search-form-submit="onSearchFormSubmit"/>
                </div>
            </div>
        </template>
    </b-container>
</template>

<script>
import CheckerForm from '@/components/CheckerForm.vue'
import ResearchHeader from '@/components/ResearchHeader.vue'
import Loader from '@/components/Loader.vue'
export default {
    data: function() {
        return {
            formType: 'MarketResearchType.SUPPLIER_CHECKER'
        }
    },
    components: {
        CheckerForm,ResearchHeader,Loader
    },
    methods: {
        onSearchFormSubmit: function() {
            if (!this.$store.getters.research) {
                return;
            }
            if (!this.$store.getters.research.search_criteria){
                return;
            }
            if (!this.$store.getters.research.search_criteria.supplier){
                return;
            }
            this.$router.push({
                name: 'external-supplier',
                params: {
                    id: this.$store.getters.research.search_criteria.supplier.id
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">

</style>