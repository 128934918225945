<template>
    <button class="btn" :class="[variantClass, sizeClass, disabledClass]" @click="$emit('click', $event)">
        <slot></slot>
    </button>
</template>
<script>
    export default {
        name: "b-button",
        props: {
            variant: {
                type: String,
                required: false,
                default: "light"
            },
            size: {
                type: String,
                required: false,
                default: null
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        computed: {
            variantClass: function(){
                return "btn-" + this.variant
            },
            sizeClass: function () {
                if (this.size){
                    return "btn-" + this.size
                }
                return ""
            },
            disabledClass: function () {
                if (this.disabled) {
                    return "disabled"
                }
                return ""
            }
        }
    }
</script>

<style lang="scss">

</style>