<template>
    <SupplierOverview :id="id" class="mt-5"/>
</template>

<script>
import SupplierOverview from '@/components/overlay/SupplierOverview.vue'
export default {
    props: {
        id: {type: [String, Number], required: true}
    },
    data: function() {
        return {
        }
    },
    components: {
        SupplierOverview
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

</style>