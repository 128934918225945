<template>
    <li>
        <a :href="href" class="dropdown-item" @click="$emit('click', $event)">
            <slot></slot>
        </a>
    </li>
</template>

<script>
    export default {
        name: "b-dropdown-item",
        props: {
            href: "",
        },
    }
</script>

<style lang="scss">

</style>