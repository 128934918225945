<template>
    <div class="row result-row align-items-center overview-toggler side-bar-grey">
        <div class="col-12 row-content">
            <div class="row">
               <div class="col">
                    <span v-if="item.first_year" class="award-date">{{ item.first_year }}</span>
                    <span v-if="item.last_year && item.first_year != item.last_year" class="award-date">-{{ item.last_year }}</span>
                    <br />
                    <h4 class="mb-2">
                        {{ $t('result.otherTenders.more', {count: item.count, amount: getAmount()}) }}
                        (<a href="#" @click.stop.prevent="moveToTab">{{ $t('result.otherTenders.list') }}</a>)
                    </h4>
                    <h4 class="mb-2">
                        <span v-if="item.cpvs && item.cpvs.length > 0">{{ $t('result.otherTenders.types') }}</span>
                        <span v-for="(cpv, index) in item.cpvs" v-bind:key="cpv">
                            <span>{{ cpv }}</span>
                            <span v-if="index < item.cpvs.length - 1">, </span>
                        </span>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "overview-other-tenders-row",
    props: {
        item: { type: Object, required: true },
        tab: { type: Number, required: false, default: -1 },
    },
    data: function() {
        return {}
    },
    computed: {
        disabled: function(){
            return this.tab == -1
        }
    },
    methods: {
        moveToTab: function(){
            if(!this.disabled){
                this.$emit('changeTab', this.tab);
            }
        },
        getFormatedNumber: function(value) {
            return this.$filters.number(value, " ", ",", 0)
        },
        getAmount: function() {
            return this.getFormatedNumber(this.item.amount)
        },
    }
};
</script>
<style>
.side-bar-grey {
    border-left-color: grey !important;
}
</style>
