<template>
    <FlagRow :item="item">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="30" height="30" viewBox="0 0 24 24" stroke-width="3" stroke="#7bc62d" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M5 12l5 5l10 -10" />
            </svg>
        </template>
    </FlagRow>
</template>


<script>
import FlagRow from '@/components/overlay/FlagRow.vue'

export default {
    name: "green-flag-row",
    props: {
        item: { type: Object, required: true },
    },
    components: { FlagRow }
};
</script>

<style>
</style>
