<template>
    <div class="form-group">
        <div v-if="label" class="col-12 mb-3">
            <component :is="labelClass"><b>{{ label }}</b></component>
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "b-form-group",
    props: {
        labelClass: {
            type: String,
            required: false,
            default: null
        },
        label
            : {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>

<style lang="scss">

</style>