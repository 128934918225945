<template>
    <b-container v-cloak>
        <div class="row justify-content-center mt-5">
            <div class="col-12 col-sm-6">
                <h2>{{ $t('serverError.title') }}</h2>
                <p>{{ $t('serverError.description') }}</p>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    computed: {
        error: function() {
            return this.$route.meta.error.message
        }
    }
}
</script>

<style scoped lang="scss">
</style>