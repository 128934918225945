<template>
    <SupplierExternalOverview :id="id" class="mt-5"/>
</template>

<script>
import SupplierExternalOverview from '@/components/overlay/SupplierExternalOverview.vue'
export default {
    props: {
        id: {type: [String, Number], required: true}
    },
    data: function() {
        return {
        }
    },
    components: {
        SupplierExternalOverview
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

</style>