<template>
    <b-container v-bind="$attrs" :fluid="fluid">
        <div class="row justify-content-center" :class="{'g-0': noGutters}">
            <div class="col-sm-12 col-md-11 col-lg-9 col-xl-8">
                <slot></slot>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    props: {
        noGutters: {type: Boolean, default: false},
        fluid: {type: Boolean, default: true}
    }
}
</script>
