<template>
    <Container>
        <Header :subtitle="$t('companyForm.title')" />

        <Loader v-if="requestRunning" :title="$t('companyForm.loader')" />
        <template v-else>
            <b-alert  variant="success" :show="dismissCountDown" @dismiss-count-down="(c) => dismissCountDown = c" dismissible fade>
                <template v-if="user.company">{{ $t('companyForm.submit.save') }}</template>
                <template v-else>{{ $t('companyForm.submit.delete') }}</template>
            </b-alert>

            <slot name="before" :user="user"></slot>

            <b-form-row class="justify-content-center text-center">
                <div class="col-10">
                    <div class="d-inline-block me-2 mb-0">
                        <b-form-input
                            size="lg"
                            type="text"
                            name="company_name"
                            v-model="name"
                            :placeholder="$t('companyForm.placeholder')"
                            @keydown.enter.prevent=''
                        />
                    </div>
                    <b-button variant="primary" @click="submit" class="align-top">{{ $t('companyForm.save') }}</b-button>
                </div>
            </b-form-row>

            <slot name="after" :user="user"></slot>
        </template>
    </Container>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Container from '@/components/overlay/Container.vue'

export default {
    props: {
    },
    data: function() {
        return {
            name: null,
            requestRunning: false,
            dismissCountDown: 0
        };
    },
    components: { Loader, Container, Header },
    mounted: function() {
        this.name = this.user.company;
    },
    computed: {
        user: function() {
            return this.$store.getters.user || {};
        },
        dismissSec: () => 5
    },
    methods: {
        submit: function() {
            this.requestRunning = true
            this.dismissCountDown = 0

            this.$store.dispatch("setUserCompanyName", this.name)
                .then(() => {
                    this.requestRunning = false
                    this.dismissCountDown = this.dismissSec
                })
                .catch(e => {
                    console.log(e)
                    this.requestRunning = false
                })
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/scss/custom";

input[name=company_name] {
    padding: 1rem !important;
    min-width: 220px !important;
}
</style>
