<template>
    <div class="histogram">
        <div class="row header">
            <div class="col-6">
                <div class="row data">
                    <div class="col-5"></div>
                    <div class="col-2 text-end">{{ $t('overview.tabs.summary.histogram.header.count') }}</div>
                    <div class="col-5 text-end">{{ $t('overview.tabs.summary.histogram.header.amount') }}</div>
                </div>
            </div>
        </div>

        <div class="row align-items-center histogram-row" v-for="(n, i) in data" :key="`data-${i}`">
            <div class="col-6">
                <div class="row data">
                    <div class="col-5">{{ n.label }}</div>
                    <div class="col-2 text-end numeric">{{ n.count }}</div>
                    <div class="col-5 text-end numeric">{{ $filters.number(n.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                </div>
            </div>
            <div class="col-6 bar-wrapper">
                <div :style="{width: `${getBarWidth(n[valueKey])}%`}" class="bar"></div>
            </div>
        </div>

        <div class="row total-row align-items-center">
            <div class="col-6">
                <div class="row data">
                    <div class="col-5">{{ $t('overview.tabs.summary.histogram.total') }}</div>
                    <div class="col-2 text-end numeric">{{ totalCount }}</div>
                    <div class="col-5 text-end numeric">{{ $filters.number(totalAmount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {type: Array, required: true},
        valueKey: {type: String, default: 'count'}
    },
    computed: {
        totalCount: function() {
            if (!this.data) {
                return 0
            }

            return this.data.reduce((a, b) => a + b.count, 0)
        },
        totalAmount: function() {
            if (!this.data) {
                return 0
            }

            return this.data.reduce((a, b) => a + b.amount, 0)
        },
        max: function() {
            if (!this.data) {
                return 0
            }

            return this.data.reduce((a, b) => Math.max(a, b[this.valueKey]), 0)
        }
    },
    methods: {
        getBarWidth: function(value) {
            if (this.max == 0) {
                return 0
            }
            return value / this.max * 100
        }
    }

}
</script>

<style lang="scss">
@import 'src/scss/custom';

.histogram {
    .header {
        font-size:12;
        font-weight: 700;
        color: $dark;
    }

    .histogram-row {
        .data {
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-top: 1px solid $dark;
        }

        .bar-wrapper {
            padding: 0 0 0 30px;

            .bar {
                height: 32px;
                background-color: $primary;
                border-radius: 8px;
            }
        }
    }

    .histogram-row:first-of-type .data {
        border-top: none;
    }

    .total-row .data {
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 4px double $dark;
        font-weight: 700;
    }
}
</style>