<template>
    <span class="badge-subtotal">{{ $filters.number(value) }}</span>
</template>

<script>
export default {
    props: ["value"]
};
</script>

<style scoped lang="scss">
.badge-subtotal {
    background: #e8ecef;
    padding: 2px 8px 2px 8px;
    border-radius: 30px;
    white-space: nowrap;
}
</style>