<template>
    <div class="text-center" id="unknown">
        <h1 class="mb-5">{{ $t("overview.unknownSupplier.title")}}</h1>
        <p>{{ $t("overview.unknownSupplier.description")}}</p>
    </div>
</template>

<script>
export default {
    name: "supplier-overview",
}
</script>

<style lang="scss">
@import 'src/scss/custom';

#unknown {
    h1 {
        color: $primary;
        font-weight: bold;
    }
    p {
        font-size: 20px;
    }
}
</style>