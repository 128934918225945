<template>
    <form @submit.stop.prevent>
        <slot></slot>
    </form>
</template>
<script>
    export default {
        name: "b-form",
    }
</script>

<style lang="scss">

</style>