<template>
    <div class="row">
        <div v-for="(option, i) in options" :key="i" :class="[cols]">
            <input class="form-check-input" type="radio" :name="name" :id="name + i" :value="option.value" v-model="value"><span class="ms-1">{{ option.text }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "b-form-radio-group",
    emits: ['update:modelValue'],
    props: {
        modelValue: {},
        options: {
            type: Array,
            required: false,
            default: []
        },
        stacked: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        cols: function () {
            if (this.stacked) {
                return "col-12"
            }
            return "col"
        },
        value: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit("update:modelValue", newValue)
            }
        }
    }
}
</script>

<style lang="scss">

</style>