<template>
    <div class="row result-row align-items-center align-middle">
        <div class="col research-content">
            <div class="row align-items-center">
                <div class="col-6 row-header-column">
                    <span class="award-date">{{ $filters.datetime(research.created) }}</span>
                    <h4>{{ research.name }}</h4>
                    <b-badge pill variant="dark" v-text="badgeLabel" />
                </div>
                <div class="col-4">
                    <a href="#" @click="$emit('research-download-pdf', research.id)"><font-awesome-icon :icon="['fas', 'download']" /> {{ $t('research.downloadFile') }}</a>
                </div>
                <div class="col-2 dropdown text-end">
                    <b-dropdown variant="outline-primary">
                        <template v-slot:button-content>
                            {{ $t('button.action') }}
                        </template>
                        <b-dropdown-item href="#" @click="$emit('research-edit', research.id)" v-if="!isSupplierTendersResearch">
                            <div class="menu-icon"><font-awesome-icon :icon="['far', 'edit']" /></div> {{ $t('button.edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="$emit('research-clone', research.id)" v-if="!isSupplierTendersResearch">
                            <div class="menu-icon"><font-awesome-icon :icon="['far', 'copy']" /></div> {{ $t('button.clone') }}
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="$emit('research-delete', research.id)">
                            <div class="menu-icon"><font-awesome-icon :icon="['far', 'trash-alt']" /></div> {{ $t('button.remove') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["research"],
    data: function() {
        return {
        }
    },
    computed: {
        api: function() {
            return this.$store.getters.api
        },
        isTendersResearch: function() {
            return this.research.research_type == 'MarketResearchType.TENDERS'
        },
        isSuppliersResearch: function() {
            return this.research.research_type == 'MarketResearchType.SUPPLIERS'
        },
        isSupplierTendersResearch: function() {
            return this.research.research_type == 'MarketResearchType.SUPPLIER_TENDERS'
        },
        badgeLabel: function() {
            if (this.isTendersResearch) {
                return this.$t('research.references')
            } else if (this.isSuppliersResearch) {
                return this.$t('research.candidates')
            } else if (this.isSupplierTendersResearch) {
                return this.$t('research.supplierTenders')
            } else {
                return ""
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import 'src/scss/list';
</style>