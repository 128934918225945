<template>
    <div class="collapse" ref="collapse" :id="id" :class="{'navbar-collapse': isNav}">
        <slot></slot>
    </div>
</template>

<script>
import { Collapse } from 'bootstrap';
    export default {
        name: "b-collapse",
        props: {
            id: {
                type: String,
                required: false,
                default: ""
            },
            isNav: {
                type: Boolean,
                required: false,
                default: false
            },
        },
    data: function () {
        return {
                instance: null
            }
        },
        mounted: function () {
            this.instance = new Collapse(this.$refs.collapse, {})
        },
        unmounted: function () {
            if (this.instance) {
                this.instance.dispose();
            }
        },
    }
</script>

<style lang="scss">

</style>