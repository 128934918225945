<template>
    <b-container>
        <Loader v-if="busy" class="mt-5" />
        <template v-else>
            <ResearchHeader :type="formType" />

            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-11 col-lg-9 col-xl-7">
                    <SearchForm @search-form-submit="$router.push(submitRoute)" v-if="routeType != 'supplier-checker'"/>
                    <CheckerForm @search-form-submit="$router.push(submitRoute)" v-else/>
                </div>
            </div>
        </template>
    </b-container>
</template>


<script>
import SearchForm from '@/components/SearchForm.vue'
import CheckerForm from '@/components/CheckerForm.vue'
import ResearchHeader from '@/components/ResearchHeader.vue'
import Loader from '@/components/Loader.vue'

export default {
    props: ["id", "form"],
    data: function() {
        return {
            busy: false
        }
    },
    components: { SearchForm, CheckerForm, ResearchHeader, Loader },
    computed: {
        submitRoute: function() {
            return `/${this.routeType}_result`
        },
        routeType: function() {
            return this.form.split("_", 1)[0]
        },
        formType: function() {
            switch(this.routeType) {
                case "tender":
                    return 'MarketResearchType.TENDERS'
                case "supplier":
                    return 'MarketResearchType.SUPPLIERS'
                case "supplier-checker":
                    return 'MarketResearchType.SUPPLIER_CHECKER'
                default:
                    return null
            }
        }
    },
    created: function() {
        this.busy = true
        this.loadPrerequisites()
            .then(() => {


                if (this.form.endsWith("_duplicate")) {
                    this.$store.commit("updateResearch", {id: null, name: null})
                }
            })
            .finally(() => this.busy = false)
    },
    methods: {
        loadPrerequisites: function() {
            return new Promise((resolve) => {
                if (this.id) {
                    if (this.$store.getters.researches.length) {
                        this.$store.commit("setResearch", this.$store.getters.researchById(this.id))
                        resolve()
                    }

                    this.$store.dispatch("loadResearchById", this.id).then(() => resolve())
                } else {
                    this.$store.commit("setResearch", { research_type: this.formType })
                    resolve()
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
</style>