<template>
    <div class="alert show" :class="[variantClass, dismissibleClass, fadeClass, showClass]" ref="alert" role="alert" v-if="showEl">
        <span><slot></slot></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="dismiss=true"></button>
    </div>
</template>
<script>
import { Alert } from "bootstrap";
export default {
    name: "b-alert",
    props: {
        variant: {
            type: String,
            required: false,
            default: "success"
        },
        dismissible: {
            type: Boolean,
            required: false,
            default: false
        },
        fade: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: [Boolean, Number],
            required: false,
            default: false
        },
    },
    data: function () {
        return {
            count: 0,
            interval: null,
            showEl: false,
            fadeTime: 500,
            dismiss: false,
            instance: null
        }
    },
    mounted: function () {
        this.instance = new Alert(this.$refs.alert);
    },
    unmounted: function () {
        this.instance.dispose()
    },
    computed: {
        variantClass: function(){
            return "alert-" + this.variant
        },
        dismissibleClass: function () {
            if (this.dismissible) {
                return "alert-dismissible"
            }
            return ""
        },
        fadeClass: function () {
            if (this.fade) {
                return "fade"
            }
            return ""
        },
        showClass: function () {
            if (this.showAlert) {
                return "show"
            }
            return ""
        },
        showAlert: function () {
            if (this.dismiss) {
                return false;
            }
            if (typeof this.show == "boolean") {
                return this.show
            }
            if (this.show){
                return true
            }
            return false
        }
    },
    watch: {
        show: {
            handler: function () {
                if (!this.show) {
                    this.hide();
                }
                else {
                    this.showEl = true;
                    this.dismiss = false;
                }

                if (typeof this.show == "number") {
                    if (this.interval) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                    if (this.show == 0) {
                        return
                    }
                    if (this.dismiss){
                        this.$emit("dismiss-count-down", 0);
                    }
                    this.interval = setInterval(function () {
                        this.$emit("dismiss-count-down", this.show - 1);
                    }.bind(this), 1000);
                }
            },
            immediate: true,
        }
    },
    methods: {
        hide: function () {
            if (this.fade) {
                setTimeout(function () { this.showEl = false }.bind(this), this.fadeTime);
            }
            else {
                this.showEl = false;
            }
        }
    }
}
</script>

<style lang="scss">

</style>