export default {
    handlers: {},
    $on: function(name, handler) {
        this.handlers[name] = handler
    },
    $off: function(name) {
        delete this.handlers[name]
    },
    $emit: function(name, data) {
        if (this.handlers[name]) {
            this.handlers[name](data)
        }
    }
}