<template>
    <div class="row form-row">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "b-form-row",
    }
</script>

<style lang="scss">
@import 'src/scss/custom';

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;

    & > [class*=col-] {
        padding-right: 5px;
        padding-left: 5px;
    }

    label, .label {
        margin-bottom: .5rem;
    }
}
</style>