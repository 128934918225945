<template>
    <div v-show="show" id="content-overlay">
        <b-container fluid class="action-bar-wrapper" :class="{shadow: scrollPosition > 0}">
            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-11 col-lg-9 col-xl-8">
                    <div class="row action-bar">
                        <div class="col-6 text-start">
                            <b-button v-if="showBackButton" class="overlay-btn overlay-back text-truncate" variant="light" @click="back">
                                <font-awesome-icon icon="arrow-left" />&nbsp;
                                <template v-if="backButtonLabel">{{ $t("overview.backTo", [backButtonLabel]) }}</template>
                                <template v-else>{{ $t("overview.back") }}</template>
                            </b-button>
                        </div>
                        <div class="col-6 text-end">
                            <div>
                                <router-link v-if="newWindowRoute" :to="newWindowRoute" v-slot="{ href }">
                                    <b-button class="overlay-btn overlay-newtab" variant="light" @click="newtab(href)">
                                        <font-awesome-icon icon="external-link-alt" />
                                    </b-button>
                                </router-link>

                                <b-button class="overlay-btn overlay-close" variant="light" @click="close">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>

        <component :is="content.component" v-bind="content.props" v-on="content.listeners" ref="content"/>
    </div>
</template>

<script>
import SupplierOverview from '@/components/overlay/SupplierOverview.vue'
import TenderOverview from '@/components/overlay/TenderOverview.vue'
import CompanyForm from '@/components/overlay/CompanyForm.vue'
import CompanyFormOnStart from '@/components/overlay/CompanyFormOnStart.vue'
import ResearchDeleteForm from '@/components/overlay/ResearchDeleteForm.vue'
import ResearchSaveForm from '@/components/ResearchSaveForm.vue'
import SearchForm from '@/components/overlay/SearchForm.vue'

export default {
    name: "content-overlay",
    emits: ['update:show'],
    props: {
        props: {type: Object, default: null},
        component: {type: String, required: true},
        listeners: {type: Object, default: null},
        show: {type: Boolean, required: false},
        showBackButton: {type: Boolean, default: true}
    },
    components: {
        SupplierOverview,
        TenderOverview,
        CompanyForm,
        CompanyFormOnStart,
        ResearchDeleteForm,
        ResearchSaveForm,
        SearchForm
    },
    data: function() {
        return {
            content: null,
            history: [],
            scrollPosition: 0
        }
    },
    computed: {
        newWindowRoute: function() {
            if (this.content) {
                switch (this.content.component) {
                    case 'supplier-overview':
                        return {name: 'supplier', params: {id: this.content?.props?.id}}
                    case 'tender-overview':
                        return {name: 'tender', params: {id: this.content?.props?.id}}
                    default:
                        null
                }
            }

            return null
        },
        backButtonLabel: function() {
            if (this.history.length > 0) {
                return this.history[this.history.length - 1].back
            } else if (this.$route.meta){
                return this.$route.meta.title
            }

            return null
        }
    },
    created: function() {
        this.history = []

        this.$ebus.$on('overlay-update-content', this.update)

        this.$ebus.$on("update-overlay-props", evt => Object.assign(this.content.props, evt))

        this.init()
    },
    mounted: function() {
        document.getElementById("content-overlay").addEventListener('scroll', this.updateScrollPosition)
        window.addEventListener('keyup', this.onEsc)
    },
    unmounted: function() {
        window.removeEventListener('keyup', this.onEsc)
    },
    watch: {
        props: function() {
            this.init()
        },
        component: function() {
            this.init()
        }
    },
    methods: {
        init: function() {
            this.content = {
                component: this.component,
                props: {...this.props },
                listeners: {...this.listeners}
            }
        },
        close: function() {
            this.history = []
            this.$emit("update:show", false)
        },
        back: function() {
            if (this.history.length > 0) {
                this.content = this.history.pop()
            } else {
                this.close()
            }
        },
        update: function(evt) {
            if (this.content) {
                this.history.push({
                    component: this.content.component,
                    props: {...this.content.props, ...this.content.listeners},
                    back: this.getBackLabel()
                })
            }

            this.content = {
                component: evt.component,
                props: { ...evt.props, ...evt.listeners }
            }

            this.scrollPosition = 0
        },
        newtab: function(href) {
            window.open(href, "_blank");
        },
        getBackLabel: function() {
            return this.$refs.content ? this.$refs.content.name : ""
        },
        updateScrollPosition: function() {
            this.scrollPosition = document.getElementById("content-overlay").scrollTop
        },
        onEsc: function(e) {
            if (e.keyCode !== 27) {
                return
            }

            this.close()
        }
    }
};
</script>

<style lang="scss">
@import 'src/scss/custom';

#content-overlay {
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    overflow-y: auto;
    overflow-x: hidden;

    .action-bar-wrapper {
        padding-top: 45px;
        background-color: $body-bg;
        position: sticky;
        top: 0;
        padding-bottom: 25px;
        z-index: 2100;
    }

    .action-bar-wrapper.shadow {
        padding-top: 25px;
    }

    .action-bar-wrapper ~ * {
        margin-top: 25px;
    }

    .action-bar {
        .overlay-btn {
            font-size: 16px;
            line-height: 16px;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background-color: $theme-color-2;
            color: $dark;
            font-weight: 100;
            text-align: center;

            svg {
                height: 16px;
                width: 16px;
            }
        }

        .overlay-back {
            width: auto;
            max-width: 100%;
        }

        .overlay-newtab {
            margin-right: 30px;
        }

        .overlay-newtab, .overlay-close {
            padding: 16px;
        }
    }
}
</style>