<template>
    <div class="row detail-row justify-content-center" v-bind="$attrs">
        <div class="col-12">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.detail-row {
    background: $theme-color-1;
    margin-right: 0px;
    margin-left: 0px;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;

    p, address {
        margin-bottom: 0px;
    }

    .detail-item {
        margin-bottom: 20px;

        &.mb-10 {
            margin-bottom: 10px;
        }

        &.mt-10 {
            margin-top: 10px;
        }

        &:last-of-type {
            margin-bottom: 0px;
        }
    }
}
</style>