<template>
    <div class="row result-row align-items-center overview-toggler p-4" @click.stop.prevent="$overlayTender(item.id, disabledSupplierLinks)">
        <div class="col-9 row-content">
            <div class="row">
                <div class="col row-header-column">
                    <span v-if="item.award_date" class="award-date">{{ $filters.date(item.award_date)  }}</span>
                    <br />
                    <h4>{{ item.name }}</h4>
                    <h5 v-if="item.lot_name && item.name != item.lot_name">{{ item.lot_name }}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <h6>{{ $t('result.buyer') }}</h6>
                    <template v-if="item.buyer">{{ item.buyer.name }}</template>
                </div>
                <div class="col-4 text-center price-column">
                    <img :src="`${publicPath}img/arrow.svg`" class="price-arrow" />
                    <div class="price" v-if="item.price_national">{{ $filters.number($filters.integer(item.price_national)) }} {{ $t("currency.czk") }}</div>
                </div>
                <div class="col-4">
                    <h6>{{ $t('result.supplier') }}</h6>
                    <template v-if="item.supplier">{{ item.supplier.name }}</template>
                </div>
            </div>
        </div>
        <div class="col-3">
            <h6>{{ $t('result.docs.title') }}</h6>
            <div v-for="(d, i) in documents" :key="i" :class="[d.exists ? 'doc-available' : 'doc-na']">
                <font-awesome-icon :icon="['fas', 'circle']" class="align-baseline dot" />
                {{ d.text }}
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "overview-tender-row",
    props: {
        item: { type: Object, required: true },
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    data: function() {
        return {};
    },
    computed: {
        documents: function() {
            return [
                {
                    text: this.$t("result.docs.spec"),
                    exists: this.item.doc_specification > 0
                },
                {
                    text: this.$t("result.docs.contract"),
                    exists: this.item.doc_contract > 0
                },
                {
                    text: this.$t("result.docs.other"),
                    exists: this.item.doc_other > 0
                }
            ];
        },
        publicPath: function() {
            return import.meta.env.BASE_URL
        }
    },
    mounted: function() {},
    methods: {}
};
</script>
