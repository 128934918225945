<template>
    <ul class="navbar-nav">
        <slot></slot>
    </ul>
</template>
<script>
    export default {
        name: "b-navbar-nav",
        props: {
        },
    }
</script>

<style lang="scss">

</style>