<template>
    <Layout :error-status="error">
        <div class="row justify-content-center">
            <div class="col-sm-12">
                <Loader v-if="pending" title="Data se načítají" />
                <template v-else>
                    <div class="row">
                       <div class="col"><p>Čas měření: {{ $filters.datetime(currentStamp) }}</p></div>
                    </div>

                    <div class="row">
                        <div class="col-7">
                            <OverviewCard title="Uživatel" subtitle="Přehled chování uživatele">
                                <SingleUserStats :data="usersStats" />
                            </OverviewCard>
                        </div>

                        <div class="col-5">
                            <OverviewCard title="Počet aktivních dní uživatele"
                                subtitle="Počet aktivních uživatelů, kteří byli v tendermanovi aktivní za posledních 7/14/30 dní v závislosti na počtu aktivních dní"
                                :width="80"
                            >
                                <ActiveDaysCounts :data="activeDaysCounts" />
                            </OverviewCard>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <OverviewCard title="Počet akcí" subtitle="Počet akcí za poslední den/týden/měsíc" :width="90">
                                <ActionsCounts :data="actionCounts" />
                            </OverviewCard>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <OverviewCard title="Seznam akcí" subtitle="Seznam jednotlivých akcí">
                                <ActionsStats :data="actionStats" />
                            </OverviewCard>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </Layout>
</template>


<script>
import OverviewCard from '@/components/applog/OverviewCard.vue'
import ActionsCounts from '@/components/applog/ActionsCounts.vue'
import ActionsStats from '@/components/applog/ActionsStats.vue'
import ActiveDaysCounts from '@/components/applog/ActiveDaysCounts.vue'
import SingleUserStats from '@/components/applog/SingleUserStats.vue'
import Loader from '@/components/Loader.vue'
import Layout from "@/views/Layout.vue"
import { CONFIG } from '@/config.js'

export default {
    props: ["id"],
    data: function() {
        return {
            currentStamp: null,
            actionCounts: null,
            actionStats: null,
            usersStats: null,
            activeDaysCounts: null,
            activeUsersCounts: null,
            pending: false,
            error: null
        }
    },
    components: { OverviewCard, ActionsCounts, ActionsStats, ActiveDaysCounts, SingleUserStats, Loader, Layout },
    mounted: function() {
        this.pending = true

        this.$store.getters.api
            .get(CONFIG.api.endpoints.applog.overview + this.id)
            .then(response => {
                this.currentStamp = response.data.current_stamp
                this.actionCounts = response.data.actions_counts
                this.actionStats = response.data.actions_stats
                this.usersStats = response.data.users_stats[this.id]
                this.activeDaysCounts = response.data.active_days_counts
            })
            .catch(e => this.error = e.response.status)
            .finally(() => this.pending = false)
    }
}
</script>

<style scoped lang="scss">
</style>