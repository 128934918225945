<template>
    <div class="my-5 w-100" id="contractors-map">
        <h2 class="overlay-content-title">{{ $t('overview.tabs.summary.map.title') }}</h2>
        <section>
            <p class="map-description" v-if="data.map.length" v-html="mapDescription"></p>
            <GChart
                type="GeoChart"
                :settings="{ packages: ['geochart'], mapsApiKey: gmapkey }"
                :data="mapData"
                :options="{
                    region: 'CZ',
                    displayMode: 'markers',
                    resolution: 'provinces',
                    colorAxis: {colors: ['#A560EB', '#1CB0F6']},
                    magnifyingGlass: {enable: false},
                    width: mapWidth,
                    height: mapHeight,
                    keepAspectRatio: false
                }"
            />
        </section>
    </div>
</template>

<script>
import { CONFIG } from '@/config.js'
import { GChart } from 'vue-google-charts'

export default {
    name: "map-summary",
    props: {
        data: { type: Object, required: true },
        supplier: { type: Object, required: true }
    },
    components: {
        GChart
    },
    created: function() {
        window.addEventListener("resize", this.setMapWidth);
    },
    unmounted: function() {
        window.removeEventListener("resize", this.setMapWidth);
    },
    data: function() {
        return { mapWidth: null}
    },
    computed: {
        gmapkey: function() {
            return CONFIG.gmapkey
        },
        mapData: function() {
            var map = [
                [
                    {type:'number', label: this.$t('overview.tabs.summary.map.labels.lat')},
                    {type:'number', label: this.$t('overview.tabs.summary.map.labels.lon')},
                    {type:'string'},
                    {type:'number', label: this.$t('overview.tabs.summary.map.labels.value')},
                    {type:'string', role:'tooltip'}
                ]
            ]

            if (!this.data || !this.data.map.length) {
                return map
            }

            var data = {}
            this.data.map.forEach(n => {
                var key = n.lat + " - " + n.lon
                if (!data[key]) {
                    data[key] = [n.lat, n.lon, n.city, 0, []]
                }

                data[key][3] += n.count
                if (data[key][4].length < 3) {
                    data[key][4].push(`${n.name} - ${this.$tc('overview.tabs.summary.map.tooltip', n.count)}`)
                } else if (data[key][4].length == 3) {
                    data[key][4].push("...")
                }
            })

            data = Object.values(data)
            // tooltip description
            data.forEach(n =>
                n[4] =
                    // new line after tooltip title
                    ['']
                    // three bigest contractors
                    .concat(n[4])
                    // total count
                    .concat([`\n${this.$tc('overview.tabs.summary.histogram.total')} ${this.$tc('overview.tabs.summary.map.tooltip', n[3])}`])
                    .join("\n")
            )

            return map.concat(data)
        },
        mapHeight: function() {
            return this.mapWidth !== null ? this.mapWidth * 0.6 : null
        },
        mapDescription: function() {
            var stats = {'buyers': {}, 'cities': {}}
            this.data.map.forEach(n => {
                if (!(n.city in stats.cities)) {
                    stats.cities[n.city] = {count: 0, amount: 0, name: n.city}
                }
                if (!(n.name in stats.buyers)) {
                    stats.buyers[n.name] = {count: 0, amount: 0, name: n.name}
                }

                stats.buyers[n.name].count += n.count
                stats.buyers[n.name].amount += n.amount
                stats.cities[n.city].count += n.count
                stats.cities[n.city].amount += n.amount

            })

            var bestCity = Object.values(stats.cities).reduce((a, b) => a.count > b.count ? a : b, {})
            var bestBuyerByAmount = Object.values(stats.buyers).reduce((a, b) => a.amount > b.amount ? a : b, {})
            var bestBuyerByCount = Object.values(stats.buyers).reduce((a, b) => a.count > b.count ? a : b, {})

            return this.$t('overview.tabs.summary.map.desc', {
                supplier: this.supplier.name,
                buyers_count: Object.keys(stats.buyers).length,
                cities_count: Object.keys(stats.cities).length,
                city_count: bestCity.count,
                city_name: bestCity.name,
                buyer_amount_name: bestBuyerByAmount.name,
                buyer_amount_value: `${this.getFormatedNumber(bestBuyerByAmount.amount)} ${this.$t('currency.czk')}`,
                buyer_count_name: bestBuyerByCount.name,
                buyer_count_value: bestBuyerByCount.count
            })
        },
    },
    methods: {
        setMapWidth: function() {
            var rect = document.getElementById('contractors-map').getBoundingClientRect()
            this.mapWidth = rect.width
        },
        getFormatedNumber: function(value) {
            return this.$filters.number(value, " ", ",", 0)
        },
    }
}

</script>