<template>
    <div v-bind="$attrs" :class="[navWrapperClass]">
        <ul class="nav nav-tabs" :class="[justifiedClass, navClass, pillsClass]" ref="tabs" role="tablist">
            <li v-for="(title, index) in titles" :key="index" class="nav-item text-center" role="presentation">
                <a role="tab" type="button" aria-controls="settings"  data-bs-toggle="tab"  aria-selected="false" aria-setsize="4"
                :id="title.key+'-tab'" :data-bs-target="'#' + title.key"
                aria-posinset="4" class="nav-link" target="_self" @click="changeNumber(index)">{{ title.name }}</a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <slot></slot>
    </div>
</template>
<script>
import { Tab } from "bootstrap";
export default {
    inheritAttrs: false,
    name: "b-tabs",
    emits: ['update:modelValue'],
    props: {
        modelValue: {},
        justified: {
            type: Boolean,
            required: false
        },
        navClass: {
            type: String,
            required: false
        },
        pills: {
            type: Boolean,
            required: false
        },
        navWrapperClass: {
            type: String,
            required: false
        },
        titles: {
            type: Array,
            required: false,
            default: []
        }
    },
    data: function () {
        return {
            activeTab: 0,
            tabs: []
        }
    },
    computed: {
        justifiedClass: function(){
            if(this.justified){
                return "nav-justified"
            }
            return ""
        },
        pillsClass: function () {
            if (this.pills) {
                return "nav-pills"
            }
            return ""
        }
    },
    mounted: function () {
        var elements = this.$refs.tabs.getElementsByTagName("a");
        for (var i = 0; i < elements.length; i++){
            var tab = Tab.getOrCreateInstance(elements[i]);
            this.tabs.push(tab);
            if (i == 0) {
                tab.show();
            }
        }
    },
    unmounted: function () {
        for (var i = 0; i < this.tabs.length; i++){
            this.tabs[i].dispose();
        }
    },
    watch: {
        modelValue: function () {
            if (this.modelValue < this.tabs.length) {
                this.tabs[this.modelValue].show()
            }
            else {
                setTimeout(function () {
                    if (this.modelValue < this.tabs.length) {
                        this.tabs[this.modelValue].show()
                    }
                }.bind(this), 1000)
            }
        }
    },
    methods: {
        changeNumber: function (index) {
            this.$emit("update:modelValue", index)
        }
    }
}
</script>

<style lang="scss">

</style>