<template>
    <div class="row justify-content-center nodata">
        <div class="col-8 text-center">
            <p class="icon">
                &#x1f937;&#x200d;&#x2642;&#xfe0f;
            </p>

            <h2 v-if="title" class="title">{{ title }}</h2>

            <h3 v-if="subtitle" class="subtitle">{{ subtitle }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {type: String, required: false},
        subtitle: {type: String, required: false}
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.nodata {
    color: $dark;

    .title {
        font-size: 18px;
        font-weight: 700;
        color: $body-color;
        margin-bottom: 8px;
    }

    .subtitle {
        font-size: 18px;
        font-weight: 400;
        color: $dark;
        margin-bottom: 0;
    }

    .icon {
        font-size: 55px;
        margin-bottom: 35px;
        opacity: 0.75;
    }
}
</style>