<template>
    <span>
        <h2 class="overlay-content-title">{{ $t('overview.tabs.summary.summary.title') }}</h2>
        <section class="company-data">
            <div class="row">
                <div class="col-9">
                     <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.legal_form.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="summary.legal_form">
                                {{ summary.legal_form }}
                            </span>
                            <span v-else>---</span>
                        </div>
                    </div>

                    <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.revenue.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="summary.revenue.amount">
                                {{ $filters.number($filters.integer(summary.revenue.amount)) }} {{ $t("currency.czk") }}
                                <span v-if="summary.revenue.year">
                                    ({{ summary.revenue.year }})
                                </span>
                            </span>
                            <span v-else-if="summary.revenue.estimated">
                                {{ summary.revenue.estimated }}
                                <span v-if="summary.revenue.year">
                                    ({{ summary.revenue.year }})
                                </span>
                            </span>
                            <span v-else>---</span>
                        </div>
                    </div>

                    <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.capital_value.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="summary.capital_value">
                                {{ $filters.number($filters.integer(summary.capital_value)) }} {{ $t("currency.czk") }}
                            </span>
                            <span v-else>---</span>
                        </div>
                    </div>

                    <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.market_value.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="summary.market_value">
                                {{ $filters.number($filters.integer(summary.market_value)) }} {{ $t("currency.czk") }}
                            </span>
                            <span v-else>---</span>
                        </div>
                    </div>

                    <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.risk.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="!summary.risk_level || summary.risk_level === 'NO'">{{ $t('overview.tabs.summary.summary.risk.no') }}</span>
                            <span v-else-if="summary.risk_level === 'LOW'">{{ $t('overview.tabs.summary.summary.risk.small') }}</span>
                            <span v-else-if="summary.risk_level === 'MEDIUM'">{{ $t('overview.tabs.summary.summary.risk.medium') }}</span>
                            <span v-else>{{ $t('overview.tabs.summary.summary.risk.high') }}</span>
                        </div>
                    </div>

                    <div v-if="summary.relationship.buyer" class="row align-items-center my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.relationship.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="haveRelationship">
                                <span>{{ $t('overview.tabs.summary.summary.relationship.yes', {buyer: summary.relationship.buyer} )}}</span>
                                <span> (</span>
                                <span>{{ $tc('overview.tabs.summary.summary.relationship.tenders', summary.relationship.tender_count )}}</span>
                                <span>, </span>
                                <span>{{ $tc('overview.tabs.summary.summary.relationship.contracts', summary.relationship.contract_count )}}</span>
                                <span v-if="summary.relationship.year_amount">
                                    <span>, </span>
                                    <span>{{ $t('overview.tabs.summary.summary.relationship.amount', {year: summary.relationship.year, amount: relationshipAmount} )}}</span>
                                </span>
                                <span>)</span>
                            </span>
                            <span v-else>{{ $t('overview.tabs.summary.summary.relationship.no', {buyer: summary.relationship.buyer}) }}</span>
                        </div>
                    </div>

                    <div class="row my-2 my-md-1">
                        <div class="col-12 col-md-4">{{ $t('overview.tabs.summary.summary.concurrency.title') }}</div>
                        <div class="col-12 col-md-8">
                            <span v-if="summary.concurrencies.length == 0">---</span>
                            <span v-else>
                                <span v-for="(concurrency, index) in summary.concurrencies" v-bind:key="concurrency.id">
                                    <span v-if="disabledSupplierLinks">{{ concurrency.name }}</span>
                                    <a href="#" v-else  @click.prevent.stop="$overlaySupplier(concurrency.id)">{{ concurrency.name }}</a>
                                    <span v-if="index < summary.concurrencies.length - 1">, </span>
                                </span>
                                <br>
                                <span>
                                    <span> (</span>
                                    <a href="#" @click.prevent.stop="moveToConcurrency">{{ $t('overview.tabs.summary.summary.concurrency.list') }}</a>
                                    <span>)</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <slot></slot>
                </div>
            </div>
        </section>
    </span>
</template>

<script>
export default {
    name: "company-summary",
    props: {
        summary: { type: Object, required: false},
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    computed: {
        relationshipAmount: function() {
            return this.getFormatedNumber(this.summary.relationship.year_amount)
        },
        haveRelationship: function(){
            return this.summary.relationship.tender_count != 0 || this.summary.relationship.contract_count != 0
        }
    },
    methods: {
        getFormatedNumber: function(value) {
            return this.$filters.number(value, " ", ",", 0)
        },
        moveToConcurrency: function(){
            this.$emit('changeTab', 3);
        },
    }
}

</script>
