<template>
    <div class="histogram" ref="histogram">
        <div
            class="column"
            v-bind:style="{
                width: barWidth + '%',
                height: n / onePixel > 1 ? n / onePixel + 'px' : '0px',
            }"
            v-for="(n, index) in data"
            v-bind:key="index"
        ></div>
    </div>
</template>

<script>
export default {
    props: ["data"],
    computed: {
        barWidth: function () {
            if (this.data) {
                return 100 / this.data.length;
            }
            return 2;
        },
        maxValue: function () {
            if (this.data) {
                return Math.max(...this.data);
            }
            return 1;
        },
        onePixel: function () {
            return this.maxValue / 110;
        },
    },
};
</script>

<style scoped lang="scss">
@import "src/scss/custom";
.histogram {
    height: 110px;
    position: relative;
}

.column {
    display: inline-block;
    background-color: $theme-color-4;
    border-right: solid 1px white;
    border-left: solid 1px white;
    border-radius: 4px;
    height: 5px;
}
</style>