<template>
    <div class="row justify-content-center header">
        <div :class="cols">
            <h3 class="text-center">{{ title }}</h3>
            <h2 class="text-center">{{ subtitle }}</h2>
            <h4 v-if="subtitle2" class="text-center">{{ subtitle2 }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {type: String},
        subtitle: {type: String},
        subtitle2: {type: String},
        options: {type: Object, default: () => {}}
    },
    data: function() {
        return {}
    },
    computed: {
        cols: function () {
            if (!this.options) {
                return ""
            }

            var classes = "";
            for (const [key, value] of Object.entries(this.options)) {
                classes += `col-${key}-${value} `;
            }

            return classes;
        }
    }
};
</script>

<style scoped lang="scss">
@import 'src/scss/custom';


.header {
    margin-top: 40px;
    margin-bottom: 20px;

    h3 {
        font-size: 0.8em;
        font-weight: 700;
    }

    h2 {
        font-size: 1.6em;
        margin-bottom: 2px;
        font-weight: 800;
    }

    h4 {
        font-weight: 300;
    }
}

</style>
