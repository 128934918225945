// common configuration
var config = {
    api: {
        baseUrl: "https://tenderman.cz",
        endpoints: {
            userProfile: "/user_profile",
            research: {
                list: "/research_list",
                get: id => `/research/${id}`,
                pdfDownload: id => "/pdf_download/" + id,
                delete: id => "/research_delete/" + id,
                tender: {
                    // edit: (id) => "/tender_edit/" + id,
                    // clone: (id) => "/tender_duplicate/" + id,
                    pdf: "/tender_generate_pdf"
                },
                supplier: {
                    pdf: "/supplier_generate_pdf"
                },
                supplierTenders: {
                    pdf: "/supplier_tenders_generate_pdf"
                },
                count: "/research_count"
            },
            region: {
                list: "/region_list"
            },
            tender: {
                count: "/tender_count",
                cpvFilter: query => "/filter_cpvs/tender_count/" + query,
                list: "/tender_list",
                overview: id => "/tender/" + id
            },
            supplier: {
                count: "/supplier_count",
                cpvFilter: query => "/filter_cpvs/supplier_count/" + query,
                list: "/supplier_list",
                overview: id => "/supplier/" + id,
                summary: id => "/supplier_summary/" + id,
                tenders: id => "/supplier_tenders/" + id,
                contracts: id => "/supplier_contracts/" + id,
                competitors: id => "/supplier_competitors/" + id,
                competitorDetail: (competitor_id, supplier_id) => `/supplier_competitor_detail/${competitor_id}/${supplier_id}`,
                downloadPdf: (organization_id) => `/supplier_pdf/${organization_id}`
            },
            subjects: query => "/subjects_list/" + (query ? query : ""),
            cvps: "/cpvs_by_ids",
            cvpsByCompany: query => "/filter_cpvs/" + query,
            log: "/log",
            userCompany: "/set_user_company",
            applog: {
                overview: "applog/overview/"
            },
            buyerInsight: {
                search: query => "buyer_insight/search/" + query,
                data: id => "buyer_insight/id/" + id
            }
        }
    },
    afterLoginRoute: "/main_page",
    buyerProfileApi: "https://bpapi.datlab.eu",
    contractRegistryApi: "https://crapi.datlab.eu",
    documentOrigin: {
        BUYER_PROFILE: 'https://bpapi.datlab.eu',
        CONTRACT_REGISTRY: 'https://crapi.datlab.eu',
        ROZZA: 'https://rzapi.datlab.eu'
    },
    vueAppUrl: "https://tenderman.cz/app",
    tenderAuditEnabled: false
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
    config["gtmkey"] = "GTM-MB742KQ";
    config["vueAppUrl"] = "https://tenderman.cz/app";
    config["tenderAuditEnabled"] = false
}

if (import.meta.env.VITE_VUE_APP_SERVER == "dev01_kuba") {
    config["api"]["baseUrl"] = "http://localhost:22021";
}

if (import.meta.env.VITE_VUE_APP_SERVER == "dev_wedro") {
    config["api"]["baseUrl"] = "http://localhost:8000";
    config["gmapkey"] = "AIzaSyA0CyIu2zoqz_50J06v9PKbzvcDSDCo3F8"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "dev02_wedro") {
    config["api"]["baseUrl"] = "http://localhost:40020";
}

if (import.meta.env.VITE_VUE_APP_SERVER == "staging") {
    config["api"]["baseUrl"] = "https://tenderman-staging.datlab.eu";
    config["gmapkey"] = "AIzaSyA0CyIu2zoqz_50J06v9PKbzvcDSDCo3F8"
    config["vueAppUrl"] = "https://tenderman-staging.datlab.eu/app";
    config["tenderAuditEnabled"] = false
}


if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
	config["api"]["baseUrl"] = "http://localhost:35601"
    config["vueAppUrl"] = "http://localhost:35602";
}


export const CONFIG = config;
