<template>
    <Layout :error-status="error" id="buyer-insight">
        <div class="row justify-content-center">
           <div class="col-11">
                <div class="row" v-if="!id">
                    <div class="col-12">
                        <multiselect
                            v-model="selectedBuyer"
                            :options="buyersOptions"
                            :searchable="true"
                            :loading="isMultiselectBusy"
                            :internal-search="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :show-labels="false"
                            @search-change="search"
                            track-by="id"
                            label="name"
                            placeholder="Zadej název nebo ičo zadavatele, případně jen jeho část"
                            class="mt-4"
                        >
                            <template #option="data">
                                <div class="row g-0 align-items-center">
                                    <div class="col-10">{{ data.option.name }}</div>
                                    <div class="col text-end text-muted">{{ data.option.organization_id }}</div>
                                </div>
                            </template>

                            <template #noResult>
                                Nic jsem nenašel, zkus upravit hledaný výraz
                            </template>

                            <template #noOptions>
                                Musíš něco zadat, bez toho to nepůjde
                            </template>
                        </multiselect>
                    </div>
                </div>

                <Loader v-if="isBusy" title="Vydrž prďka, louduju data." class="mt-5"/>
                <template v-else>
                    <div class="row" v-if="buyer">
                       <div class="col">
                            <h1 class="mt-5">
                                <b>{{ buyer.name }}</b>
                                <small class="ms-3">
                                    {{ buyer.organization_id }}

                                    <router-link :to="{name: 'buyer-insight-detail', params: {id: buyer.id}}" target="_blank" class="ms-3">#permalink</router-link>
                                </small>
                            </h1>

                            <div class="row text-nowrap text-muted mb-5">
                                <div class="col-12 col-xl-auto" v-if="buyer.contact_name">
                                    <font-awesome-icon icon="address-card"/> {{ buyer.contact_name }}
                                </div>

                                <div class="col-12 col-xl-auto" v-if="buyer.contact_email">
                                    <font-awesome-icon icon="envelope"/> {{ buyer.contact_email }}
                                </div>

                                <div class="col-12 col-xl-auto" v-if="buyer.contact_number">
                                    <font-awesome-icon icon="phone"/> {{ buyer.contact_number }}
                                </div>

                                <div class="col-12 col-xl-auto" v-if="address">
                                    <font-awesome-icon icon="map-marked-alt"/> {{ address }}
                                </div>

                                <div class="col-12 col-xl-auto" v-if="buyer.vat_id">
                                    DIČ: {{ buyer.vat_id }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="data">
                        <div class="row justify-content-center">
                            <div class="col text-center">
                                <div class="price_card_container text-center" v-if="price">
                                    <h3>{{ price.title }}</h3>
                                    <div class="price_card">
                                        <img class="price_icon" :src="price.img">
                                        <div class="price">{{ price.value }} {{ $t('currency.czk') }}</div>
                                        <div class="period">měsíčně</div>
                                        <div class="count">počet&nbsp;zakázek:&nbsp;<strong>{{ price.label }}</strong>&nbsp;({{ $filters.integer(tendersByYearAverage) }})</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="mt-5"><font-awesome-icon icon="hashtag" /> Posledních 5 tenderů podle zdroje</h2>

                        <div class="row" v-if="data?.tenders && Object.keys(data.tenders).length">
                            <div v-for="(t, o) in data.tenders" :key="`tenders-${o}`" class="col-12 col-xl-6 mt-3">
                                <h4 class="text-info">{{ o }}</h4>
                                <template v-for="n in t" :key="`tender-${n.id}`">
                                    <div class="table-row">
                                        <div class="row mb-1">
                                            <div class="col">
                                                <a v-if="n.source" :href="n.source" target="_blank"><b>{{ n.name }}</b></a>
                                                <b v-else>{{ n.name }}</b>
                                            </div>
                                        </div>
                                        <div class="row text-dark">
                                            <div v-if="n.publication_date || n.award_date" class="col-auto">
                                                <div v-if="n.publication_date">
                                                    <font-awesome-icon :icon="['far', 'calendar-alt']" />{{ $filters.date_short(n.publication_date) }}
                                                </div>
                                                <div v-if="n.award_date">
                                                    <font-awesome-icon icon="award" />{{ $filters.date_short(n.award_date) }}
                                                </div>
                                            </div>
                                            <div class="col">{{ n.supplier.name }} <small>{{ n.supplier.organization_id }}</small></div>
                                            <div class="col-auto text-end">{{ $filters.number(n.price_national) }} {{ n.currency_national }}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <p v-else class="nodata"><font-awesome-icon :icon="['far', 'angry']" /> Nemáme ani jednu zakázku</p>
                    </template>

                    <div class="row">
                        <div class="col-12 col-xl-6 mt-3">
                            <h2 class="mt-5"><font-awesome-icon icon="hashtag" /> 10 nejčastějších CPV s počtem tenderů</h2>

                            <template v-if="data?.cpvs && data.cpvs.length">
                                <div class="table-row" v-for="c in data.cpvs" :key="'cpv-' + c.cpv_code">
                                    <div class="row">
                                        <div class="col"><span class="cpv-code">{{ c.cpv_code }}</span> <small><b>{{ c.cpv_name }}</b></small></div>
                                        <div class="col-2 text-end">{{ $filters.number(c.count) }}</div>
                                    </div>
                                </div>
                            </template>
                            <p v-else class="nodata"><font-awesome-icon :icon="['far', 'angry']" /> Nenašel jsem ani jedno použité CPV</p>
                        </div>

                        <div class="col-12 col-xl-6 mt-3">
                            <h2 class="mt-5"><font-awesome-icon icon="hashtag" /> Objem a počet zakázek podle let</h2>

                            <template v-if="data?.tenders_by_year && data.tenders_by_year.length">
                                <div class="table-row" v-for="t in data.tenders_by_year" :key="'tenders-year-' + t.year">
                                    <div class="row">
                                        <div class="col">{{ t.year }}</div>
                                        <div class="col text-end">
                                            <b>{{ $filters.number(t.price) }} CZK</b>
                                            <br><small>{{ $filters.number(t.price * 0.05) }} CZK</small>
                                            <br><small>{{ $filters.number(t.price * 0.0025) }} CZK</small>
                                            <br>
                                            <small>
                                                VVZ: {{ $tc("tender", t.count_vvz) }},
                                                BP: {{ $tc("tender", t.count_bp) }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-row tenders-by-year-total-row">
                                    <div class="row">
                                        <div class="col">Celkem</div>
                                        <div class="col text-end">
                                            <b>{{ $filters.number(tendersByYearTotal.price) }} CZK</b>
                                            <br><small>{{ $filters.number(tendersByYearTotal.price * 0.05) }} CZK</small>
                                            <br><small>{{ $filters.number(tendersByYearTotal.price * 0.0025) }} CZK</small>
                                            <br>
                                            <small>
                                                VVZ: {{ $tc("tender", tendersByYearTotal.count_vvz) }},
                                                BP: {{ $tc("tender", tendersByYearTotal.count_bp) }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <p v-else class="nodata"><font-awesome-icon :icon="['far', 'angry']" /> Nemáme ani jednu zakázku</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/Layout.vue"
import Loader from "@/components/Loader.vue"
import { CONFIG } from "@/config.js"

export default {
    props: {
        id: {type: [String, Number], required: false}
    },
    components: { Loader, Layout },
    data: function () {
        return {
            buyer: null,
            data: null,
            isBusy: false,
            isMultiselectBusy: false,
            buyersOptions: [],
            selectedBuyer: null,
            searchTimeout: null,
            debounce: 700,
            error: null
        };
    },
    created: function() {
        this.loadData(this.id)
    },
    watch: {
        id: function(value) {
            this.loadData(value)
        },
        selectedBuyer: function(value) {
            this.loadData(value ? value.id : null)
        }
    },
    computed: {
        address: function() {
            if (!this.buyer) {
                return "";
            }

            return `${this.buyer.street}, ${this.buyer.city} ${this.buyer.postal_code}, ${this.buyer.country}`.replace(/^[ ,]*|[ ,]*$/, "")
        },
        tendersByYearTotal: function() {
            if (!this.data  || !this.data.tenders_by_year.length) {
                return null
            }

            var total_count_bp = 0
            var total_count_vvz = 0
            var total_price = 0

            this.data.tenders_by_year.forEach(n => {
                total_count_bp += n.count_bp
                total_count_vvz += n.count_vvz
                total_price += n.price
            })

            return {
                count_vvz: total_count_vvz,
                count_bp: total_count_bp,
                price: total_price
            }
        },
        tendersByYearAverage: function() {
            if (!this.data  || !this.data.tenders_by_year.length) {
                return 0
            }

            var tmp = this.data.tenders_by_year.slice(0, 4)
            if (tmp[0].year == 2020) {
                tmp.shift()
            } else {
                tmp.pop()
            }

            return tmp.reduce((sum, n) => sum + n.count_vvz, 0) / 3
        },
        publicPath: function() {
            return import.meta.env.BASE_URL
        },
        price: function() {
            if (this.tendersByYearAverage > 15) {
                return { value: 15000, img: `${this.publicPath}img/price-large.png`, label: "15+", title: "LARGE" }
            } else if (this.tendersByYearAverage > 3) {
                return { value: 3000, img: `${this.publicPath}img/price-mid.png`, label: "3-15", title: "MIDDLE" }
            } else {
                return { value: 500, img: `${this.publicPath}img/price-tiny.png`, label: "0-3", title: "TINY" }
            }
        }
    },
    mounted: function () {
    },
    methods: {
        search: function(q) {
            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout)
            }

            this.searchTimeout = setTimeout(() => this.loadMultiselectOptions(q), this.debounce)
        },
        loadMultiselectOptions: function(q) {
            if (q === null || q === "") {
                this.buyersOptions = []
                return
            }

            this.isMultiselectBusy = true
            this.$store.getters.api
                .get(CONFIG.api.endpoints.buyerInsight.search(q))
                .then(r => this.buyersOptions = r.data)
                .catch(e => this.error = e.response.status)
                .finally(() => this.isMultiselectBusy = false);
        },
        loadData: function(id) {
            if (!id) {
                this.data = null
                this.buyer = null
                return
            }

            this.isBusy = true
            this.$store.getters.api
                .get(CONFIG.api.endpoints.buyerInsight.data(id))
                .then(r => {
                    this.buyer = r.data.buyer
                    this.data = r.data.data
                })
                .catch(e => this.error = e.response.status)
                .finally(() => this.isBusy = false);
        }
    }
};
</script>

<style lang="scss">
.multiselect__option {
    white-space: normal;
}
</style>

<style scoped lang="scss">
@import "src/scss/_custom";

#buyer-insight {
    font-size: 15px;
}

.table-row {
    border: 1px solid lightgray;
    border-bottom-width: 0px;
    padding: 15px;

    a {
        color: $body-color;
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-width: 1px;
    }

    .row {
        align-items: center;
    }

    .svg-inline--fa {
        width: 15px;
        height: 15px;
        margin-right: 3px;
    }
}

.tenders-by-year-total-row {
    background-color: lightgray;
}

.cpv-code {
    display: inline-block;
    width: 110px;
}

.nodata {
    font-size: 25px;
    text-align: center;
    color: $dark;
}

.price_card_container {
    border: 1px solid lightgray;
    padding: 10px;
    display: inline-block;
    margin: 0 auto;
    border-radius: 8px;

    .price {
        font-size: 24px;
        font-weight: 700;
    }
}
</style>
