<template>
    <div>
        <div class="row align-items-center controls-row">
            <div class="col-6 text-start">
                <template v-if="!loading">
                    <slot v-bind="{count: items.length}" name="found-label">{{ items.length }}</slot>
                </template>
                <a v-if="isSomeSelected" class="select-all-or-none" href="#" @click="toggleSelectAll">{{ $t("result.selection.cancelSelection") }}</a>
                <a v-else class="select-all-or-none" href="#" @click="toggleSelectAll">{{ $t("result.selection.selectAll") }}</a>
            </div>

            <div class="col-6 text-end">
                <div class="row small-gutters">
                    <div class="col-6 select-column">
                        <multiselect
                            v-model="selectedSelectionOption"
                            track-by="id"
                            label="name"
                            openDirection="bottom"
                            :show-labels="false"
                            :hide-selected="true"
                            :options="selectionOptions"
                            :searchable="false"
                            :allow-empty="false"
                            :preselectFirst="true"
                        ></multiselect>
                    </div>
                    <div v-if="orderOptions" class="col-6 select-column">
                        <multiselect
                            v-model="selectedOrderOption"
                            track-by="id"
                            label="name"
                            openDirection="bottom"
                            :show-labels="false"
                            :hide-selected="true"
                            :options="orderOptions"
                            :searchable="false"
                            :allow-empty="false"
                            :preselectFirst="true"
                            @select="onOrderChange"
                        ></multiselect>
                    </div>
                </div>
            </div>
        </div>

        <Loader v-if="loading" :title="itemsLoaderTitle" />

        <div class="row" v-else>
            <div v-if="items.length" ref="col result-rows">
                <template v-for="item in itemsToShow" :key="item.id">
                    <component
                        v-bind="rowComponentProps(item)"
                        :is="rowComponent"
                        ref="row"
                        @rowSelected="rowSelected"
                    />
                </template>
            </div>
            <div v-else class="col mt-4">
                <slot name="no-items"></slot>
            </div>
        </div>
    </div>
</template>


<script>
import ResearchResultListTenderRow from "@/components/ResearchResultListTenderRow.vue";
import ResearchResultListSupplierRow from "@/components/ResearchResultListSupplierRow.vue";
import Loader from "@/components/Loader.vue";
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import orderingMixin from '@/plugins/orderingMixin.js'

export default {
    props: {
        orderOptions: { type: Array, required: true },
        rowComponent: { type: String, required: true },
        defaultOrder: String,
        itemsLoaderTitle: String,
        itemsLoader: { type: Function, required: false},
        rowProps: {type: Object, default: () => {}},
    },
    data: function() {
        return {
            selectedSelectionOption: null,
            selectedOrderOption: null,
            loading: false,
            selectedIds: [],
            items: [],
        };
    },
    mixins: [applicationLogMixin, orderingMixin],
    components: {
        ResearchResultListTenderRow,
        ResearchResultListSupplierRow,
        Loader
    },
    computed: {
        selectionOptions: function() {
            return [
                {
                    name: this.$t("result.selection.options[0]"),
                    id: "all"
                },
                {
                    name: this.$t("result.selection.options[1]"),
                    id: "selectedOnly"
                }
            ];
        },
        showAll: function() {
            return this.selectedSelectionOption
                ? this.selectedSelectionOption.id == "all"
                : true;
        },
        itemsToShow: function () {
            if (this.showAll) {
                return this.items
            }

            return this.items.filter(n => this.isSelected(n.id))
        },
        isAllSelected: function() {
            return this.items.every(n => this.isSelected(n.id));
        },
        isSomeSelected: function() {
            if (this.selectedIds.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        research: function() {
            return this.$store.getters.research
        },
        selectedItems: function() {
            return this.items.filter(n => this.isSelected(n.id));
        }
    },
    mounted: function() {
        this.loadItems()
    },
    watch: {
        itemsLoader: {
            handler() {
                this.loadItems()
            },
            deep: true
        }
    },
    methods: {
        toggleSelectAll: function() {
            if (this.selectedIds.length > 0) {
                this.selectedIds = []
            } else {
                this.selectedIds = this.items.map(x => x.id)
            }

            this.$emit("selected-items-update", this.selectedItems)
        },
        rowSelected: function(itemId, state) {
            if (state) {
                this.selectedIds.push(itemId)
            } else {
                this.selectedIds = this.selectedIds.filter(function(value) {
                    return value != itemId
                });
            }

            this.$emit("selected-items-update", this.selectedItems)
        },
        isSelected: function(itemId) {
            return this.selectedIds.includes(itemId)
        },
        onOrderChange: function(evt) {
            this.sortItems(evt.id)
        },
        loadItems: function() {
            let promise = this.itemsLoader()
            if (promise != null) {
                this.loading = true
                promise
                    .then(response => {
                        this.items = response.data
                        if (this.defaultOrder) {
                            this.sortItems(this.defaultOrder)
                        }
                    })
                    .catch((e) =>  {
                        console.error(e)
                        this.items = []
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        rowComponentProps: function(item) {
            return {...{item: item, selectedIds: this.selectedIds}, ...this.rowProps}
        }
    }
};
</script>