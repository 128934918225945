<template>
    <Container v-if="research">
        <Header :title="$t('research.popup.title')" :subtitle="research.name" class="mt-0" />

        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 col-xl-6 text-center">
                <p>{{ $t('research.popup.confirm[0]') }}</p>
                <p>{{ $t('research.popup.confirm[1]') }}</p>
                <div class="text-center">
                    <a class="btn btn-danger btn-sm" href="#" @click="() => $ebus.$emit('delete-research', {id: research.id})">{{ $t('research.popup.button') }}</a>
                </div>
            </div>
        </div>
    </Container>
</template>

<script>
import Container from '@/components/overlay/Container.vue'
import Header from '@/components/Header.vue'

export default {
    props: {
        id: {type: Number, required: true}
    },
    components: { Container, Header },
    computed: {
        research: function() {
            return this.$store.getters.researchById(this.id)
        },
        api: function() {
            return this.$store.getters.api
        }
    }
}
</script>

<style>

</style>