<template>
    <Layout :error-status="error">
        <div class="row justify-content-center">
            <div class="col-sm-12">
                <Loader v-if="pending" title="Data se načítají" />
                <template v-else>
                    <div class="row">
                       <div class="col"><p>Čas měření: {{ $filters.datetime(currentStamp) }}</p></div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-4 mb-2 mb-md-0">
                            <OverviewCard title="Aktivní uživatelé" subtitle="Počet aktivních uživatelů za poslední den/týden/měsíc" :width="80">
                                <ActiveUsersCounts :data="activeUsersCounts" />
                            </OverviewCard>
                        </div>

                        <div class="col-12 col-md-8">
                            <OverviewCard title="Počet aktivních dní uživatelů"
                                subtitle="Počet aktivních uživatelů, kteří byli v tendermanovi aktivní za posledních 7/14/30 dní v závislosti na počtu aktivních dní"
                                :width="80"
                            >
                                <ActiveDaysCounts :data="activeDaysCounts" />
                            </OverviewCard>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <OverviewCard title="Uživatelé" subtitle="Přehled chování uživatelů">
                                <UsersStats :data="usersStats" />
                            </OverviewCard>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <OverviewCard title="Počet akcí" subtitle="Počet akcí za poslední den/týden/měsíc" :width="90">
                                <ActionsCounts :data="actionCounts" />
                            </OverviewCard>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <OverviewCard title="Seznam akcí" subtitle="Seznam jednotlivých akcí">
                                <ActionsStats :data="actionStats" />
                            </OverviewCard>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </Layout>
</template>

<script>
import OverviewCard from "@/components/applog/OverviewCard.vue";
import ActionsCounts from "@/components/applog/ActionsCounts.vue";
import ActiveUsersCounts from "@/components/applog/ActiveUsersCounts.vue";
import ActionsStats from "@/components/applog/ActionsStats.vue";
import ActiveDaysCounts from "@/components/applog/ActiveDaysCounts.vue";
import UsersStats from "@/components/applog/UsersStats.vue";
import Loader from "@/components/Loader.vue";
import Layout from "@/views/Layout.vue"
import { CONFIG } from "@/config.js";

export default {
    data: function () {
        return {
            currentStamp: null,
            actionCounts: null,
            actionStats: null,
            usersStats: null,
            activeDaysCounts: null,
            activeUsersCounts: null,
            pending: false,
            error: null
        };
    },
    components: {
        OverviewCard,
        ActionsCounts,
        ActiveUsersCounts,
        ActionsStats,
        ActiveDaysCounts,
        UsersStats,
        Loader,
        Layout
    },
    computed: {},
    mounted: function () {
        this.pending = true;
        this.$store.getters.api
            .get(CONFIG.api.endpoints.applog.overview)
            .then((response) => {
                this.currentStamp = response.data.current_stamp;
                this.actionCounts = response.data.actions_counts;
                this.actionStats = response.data.actions_stats;
                this.usersStats = response.data.users_stats;
                this.activeDaysCounts = response.data.active_days_counts;
                this.activeUsersCounts = response.data.active_users_counts;
            })
            .catch(e => this.error = e.response.status)
            .finally(() => (this.pending = false));
    },
};
</script>
