<template>
    <div class="row competitor-row align-items-center g-0" @click.prevent.stop="toggleDetail">
        <div class="col-4 name">{{ competitor.name }}</div>
        <div class="col-3 text-end">
            <h3 class="overlay-content-title">{{ $t('overview.tabs.competitors.row.amount') }}</h3>
            <div class="numeric">{{ $filters.number(competitor.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
        </div>
        <div class="col-3 text-end">
            <h3 class="overlay-content-title">{{ $t('overview.tabs.competitors.row.count') }}</h3>
            <div class="numeric">{{ competitor.count }}</div>
        </div>
        <div class="col-2 text-end" v-if="disabledSupplierLinks">
            <b-button class="detail-toggler" variant="outline-primary" size="sm">
                {{ $t("result.supplierDetail") }}
            </b-button>
        </div>
        <div class="col-2 text-end" v-else>
            <b-button class="detail-toggler" :class="{expanded: expanded}" variant="outline-primary" size="sm" @click.prevent.stop="$overlaySupplier(competitor.id)">
                {{ $t("result.supplierDetail") }}
            </b-button>
        </div>
    </div>

    <div v-if="expanded" :key="`competitor-detail-${competitor.id}`" class="row competitor-detail-row g-0">
        <div class="col">
            <Loader v-if="loading" />
            <div class="row" v-else-if="data">
                <div class="col-12 col-lg-4 text mb-4 mb-lg-0">
                    <h3 class="overlay-content-title">{{ $t('overview.tabs.competitors.detail.tenders', {year: $store.getters.concurrencyFromYear}) }}</h3>
                    <p>
                        <span v-html="detailTextHtml(0, 2)"></span>
                        <span>(např. <a href="#" @click.stop.prevent="$overlayTender(data.tender.id, disabledSupplierLinks)">{{ data.tender.title }}</a>).</span>
                        <span v-html="detailTextHtml(2, 6)"></span>
                    </p>
                    <p v-html="detailTextHtml()"></p>
                </div>
                <div class="col-12 col-lg-8 data">
                    <div class="row g-0">
                        <div class="col-7"></div>
                        <div class="col-1 overlay-content-title mb-0">{{ $t('overview.tabs.competitors.detail.count') }}</div>
                        <div class="col-4 overlay-content-title mb-0">{{ $t('overview.tabs.competitors.detail.amount') }}</div>
                    </div>
                    <div class="row g-0 group-row">
                        <div class="col-7">{{ $t('overview.tabs.competitors.detail.common') }}</div>
                        <div class="col-1 numeric">{{ commonTotal.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(commonTotal.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 member-row">
                        <div class="col-6 offset-1">{{ supplier.name }}</div>
                        <div class="col-1 numeric">{{ data.common.supplier.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(data.common.supplier.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 member-row">
                        <div class="col-6 offset-1">{{ competitor.name }}</div>
                        <div class="col-1 numeric">{{ data.common.competitor.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(data.common.competitor.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 member-row last">
                        <div class="col-6 offset-1">{{  $t('overview.tabs.competitors.detail.otherWinners') }}</div>
                        <div class="col-1 numeric">{{ data.common.other.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(data.common.other.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 group-row">
                        <div class="col-7">{{ $t('overview.tabs.competitors.detail.other') }}</div>
                        <div class="col-1 numeric">{{ otherTotal.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(otherTotal.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 member-row">
                        <div class="col-6 offset-1">{{ competitor.name }}</div>
                        <div class="col-1 numeric">{{ data.other.competitor.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(data.other.competitor.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 member-row last">
                        <div class="col-6 offset-1">{{  $t('overview.tabs.competitors.detail.otherWinners') }}</div>
                        <div class="col-1 numeric">{{ data.other.other.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(data.other.other.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                    <div class="row g-0 total-row">
                        <div class="col-7">{{  $t('overview.tabs.competitors.detail.total') }}</div>
                        <div class="col-1 numeric">{{ total.count }}</div>
                        <div class="col-4 numeric">{{ $filters.number(total.amount, " ", ",", 0) }} {{ $t('currency.czk') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Empty from '@/components/overlay/Empty.vue'

import { CONFIG } from '@/config.js'

export default {
    name: "supplier-competitors",
    props: {
        competitor: { type: Object, required: true},
        supplier: { type: Object, required: true},
        disabledSupplierLinks: { type: Boolean, default: false }
    },
    components: { Loader, Empty },
    data: function() {
        return {
            data: null,
            loading: false,
            expanded: false
        }
    },
    computed: {
        commonTotal: function() {
            var count = 0
            var amount = 0

            if (this.data?.common) {
                Object.values(this.data.common).forEach(item => {
                    count += item.count
                    amount += item.amount || 0
                })
            }

            return {
                amount: amount,
                count: count
            }
        },
        otherTotal: function() {
            var count = 0
            var amount = 0

            if (this.data?.other) {
                Object.values(this.data.other).forEach(item => {
                    count += item.count
                    amount += item.amount || 0
                })
            }

            return {
                amount: amount,
                count: count
            }
        },
        total: function() {
            return {
                amount: this.commonTotal.amount + this.otherTotal.amount,
                count: this.commonTotal.count + this.otherTotal.count
            }
        },
        competitorWonTotal: function() {
            if (this.data === null) {
                return {amount: 0, count: 0}
            }

            var common = this.data.common.competitor
            var other = this.data.other.competitor

            return {
                amount: common.amount + other.amount,
                count: common.count + other.count
            }
        }
    },
    methods: {
        loadDetail: function () {
            this.loading = true

            var data = {
                params: {
                    from_year: this.$store.getters.concurrencyFromYear,
                }
            }

            this.$store.getters.api
                .get(CONFIG.api.endpoints.supplier.competitorDetail(this.competitor.id, this.supplier.id), data)
                .then(response => this.data = response.data)
                .catch(() => this.data = null)
                .finally(() => this.loading = false)
        },
        toggleDetail: function() {
            this.expanded = !this.expanded

            if (this.data === null) {
                this.loadDetail()
            }
        },
        detailTextHtml: function(from, to) {
            var data = {
                competitor: this.competitor.name,
                supplier: this.supplier.name,
                total: this.total.count,
                common_count: this.commonTotal.count,
                won_total_count: this.competitorWonTotal.count,
                won_total_amount: this.$filters.number(this.competitorWonTotal.amount, " ", ",", 0),
                common: this.data.common.supplier.count,
                won_count: this.data.common.competitor.count,
                lost_count:
                    this.data.common.supplier.count
                    + this.data.common.other.count
                    + this.data.other.other.count
            }

            var text = " "
            for (var i = from; i < to; i++) {
                if (i == 1) {
                    text += this.translationTextWithVariety(i, data.common_count)
                } else if (i == 3) {
                    text += this.translationTextWithVariety(i, data.won_total_count)
                } else if (i == 5) {
                    text += this.translationTextWithVariety(i, data.lost_count)
                } else {
                    text += this.translationText(i, data)
                }

                text += " "
            }

            return text
        },
        translationText: function(number, data){
            return this.$t( 'overview.tabs.competitors.detail.text[' + number + ']', data)
        },
        translationTextWithVariety: function(number, count){
            return this.$tc('overview.tabs.competitors.detail.text[' + number + ']', count)
        }
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

.competitor-row {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $theme-color-1;
    cursor: pointer;

    &:hover {
        background-color: darken($theme-color-1, 5%);
    }

    h3 {
        margin-bottom: 5px;
        font-size: 12px;
    }

    h3 ~ div {
        font-weight: 700;
        font-size: 14px;
    }

    .name {
        font-size: 16px;
    }

    .detail-toggler {
        font-size: 12px;
        padding: 5px 10px;
    }

    div[class*='col-']:not(.name) {
        padding-left: 15px
    }
}

.competitor-detail-row {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: $light;
    margin-bottom: 20px;

    .text {
        h3 {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .data {
        font-size: 14px;

        .row {
            & > div {
                text-align: right;
                padding-top: 12px;
                padding-bottom: 12px;

                &:first-of-type {
                    text-align: left;
                }
            }

            &:first-of-type > div {
                padding-top: 0;
            }
        }

        .group-row {
            font-weight: 400;
            padding-left: 0px;

            & > div {
                border-bottom: 1px solid $dark;
            }

            .numeric {
                font-weight: bold;
            }
        }

        .member-row ~ .group-row > div {
            border-top: 1px solid $dark;
        }

        .member-row {
            font-weight: 300;

            & > div {
                border-bottom: 1px solid $dark;
            }

            &.last > div {
                border-bottom: none;
            }
        }

        .total-row {
            & > div {
                border-top: 4px double $dark;
            }
            font-weight: 700;
        }
    }
}
</style>