<template>
    <Container v-if="loading" >
        <Loader />
    </Container>
    <div v-else-if="!supplier">
        <UnknownSupplier/>
    </div>
    <div v-else id="overview-supplier">
        <Container class="text-center overlay-header">
            <h1>{{ supplier.name }}</h1>

            <h2 v-if="supplier.organization_id">
                {{ $t('detail.organizationId') }}: {{ supplier.organization_id }}
            </h2>

            <h2 v-if="address">{{ address }}</h2>
        </Container>

        <b-tabs
            nav-class="col col-12 col-md-11 col-lg-9 col-xl-7"
            nav-wrapper-class="row justify-content-center overview-nav-tabs g-0"
            :titles="titles"
            justified
            v-model="selectedTab"
        >
            <Container :no-gutters="true">
                <div class="tab-content" id="nav-tabContent">
                    <b-tab name="summary_tab">
                        <Summary :supplier="supplier" @changeTab="changeTab" :disabled-supplier-links="disabledSupplierLinks"/>
                    </b-tab>
                    <b-tab name="tenders_tab">
                        <TendersTable :supplier="supplier" :tenders-loader="tendersLoader" :disabled-supplier-links="disabledSupplierLinks" @count="(n) => tendersCount = n">
                            <template #found="{ count }">
                                {{ $tc('result.found.tender', count) }}
                            </template>
                            <template #no-items>
                                <Empty :title="$t('overview.tabs.tenders.nodata.title')" :subtitle="$t('overview.tabs.tenders.nodata.subtitle')" />
                            </template>
                            <template #all-items>
                                <small class="text-center text-muted w-100">{{ $t('overview.tabs.tenders.allItems') }}</small>
                            </template>
                        </TendersTable>
                    </b-tab>
                    <b-tab name="contracts_tab">
                        <TendersTable :supplier="supplier" :tenders-loader="contractsLoader" :disabled-supplier-links="disabledSupplierLinks" @count="(n) => contractsCount = n">
                            <template #found="{ count }">
                                {{ $tc('result.found.contract', count) }}
                            </template>
                            <template #no-items>
                                <Empty :title="$t('overview.tabs.tenders.nodata.title')" :subtitle="$t('overview.tabs.contracts.nodata.subtitle')" />
                            </template>
                            <template #all-items>
                                <small class="text-center text-muted w-100">{{ $t('overview.tabs.contracts.allItems') }}</small>
                            </template>
                        </TendersTable>
                    </b-tab>
                    <b-tab name="competitors_tab">
                        <Competitors :supplier="supplier" :disabled-supplier-links="disabledSupplierLinks" @count="(n) => competitorsCount = n"/>
                    </b-tab>
                </div>
            </Container>
        </b-tabs>
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Container from '@/components/overlay/Container.vue'
import Summary from '@/components/overlay/Summary.vue'
import TendersTable from '@/components/overlay/TendersTable.vue'
import Competitors from '@/components/overlay/Competitors.vue'
import Empty from '@/components/overlay/Empty.vue'
import UnknownSupplier from '@/components/overlay/UnknownSupplier.vue'
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import { CONFIG } from '@/config.js'

export default {
    name: "supplier-overview",
    props: {
        id: { type: [String, Number], required: true },
        tab: { type: Number, default: 0 }
    },
    components: { Loader, Container, Summary, TendersTable, Competitors, Empty, UnknownSupplier },
    mixins: [applicationLogMixin],
    data: function() {
        return {
            supplier: null,
            loading: false,
            tabIndex: 0,
            selectedTab: 0,
            disabledSupplierLinks: true,
            competitorsCount: '-',
            tendersCount: '-',
            contractsCount: '-'
        }
    },
    watch: {
        id: function() {
            this.loadData()
        },
        tab: function(value) {
            this.selectedTab = parseInt(value);
        },
        selectedTab: function(value) {
            this.$ebus.$emit('update-overlay-props', {tab: value})
        }
    },
    created: function() {
        this.selectedTab = this.tab
        this.loadData()
    },
    computed: {
        address: function() {
            if (!this.supplier) {
                return null;
            }

            return `${this.supplier.street}, ${this.supplier.postal_code} ${this.supplier.city}`.replace(/^[ ,]*|[ ,]*$/, "")
        },
        name: function() {
            return this.supplier ? this.supplier.name : ""
        },
        titles: function() {
            return [{
                "name": this.$t('overview.tabs.summary.title'),
                "key": "summary_tab"
            },{
                "name": this.$t('overview.tabs.tenders.title', {count: this.tendersCount}),
                "key": "tenders_tab"
            },{
                "name": this.$t('overview.tabs.contracts.title', {count: this.contractsCount}),
                "key": "contracts_tab"
            },{
                "name": this.$t('overview.tabs.competitors.title', {count: this.competitorsCount}),
                "key": "competitors_tab"
            }]
        }
    },
    methods: {
        loadData: function() {
            this.loading = true

            this.$store.getters.api
                .post(CONFIG.api.endpoints.supplier.overview(this.id))
                .then(response => {
                    this.supplier = response.data
                    this.$log("SUPPLIER_DETAIL_VIEW", this.buildSupplierLog(this.supplier))
                })
                .catch(() => this.data = null)
                .finally(() => this.loading = false)
        },
        tendersLoader: function(page, orderby) {
            return this.$store.getters.api
                .post(CONFIG.api.endpoints.supplier.tenders(this.supplier.id), {page: page, orderby: orderby})
        },
        contractsLoader: function(page, orderby) {
            return this.$store.getters.api
                .post(CONFIG.api.endpoints.supplier.contracts(this.supplier.id), {page: page, orderby: orderby})
        },
        changeTab: function(tabNumber){
            this.selectedTab = tabNumber;
        }
    }
};
</script>

<style lang="scss">
@import 'src/scss/custom';

#overview-supplier {
    .overview-nav-tabs {
        color: $body-color;
        margin-top: 50px;
        border-bottom: 1px solid $background-light;

        .nav-tabs {
            padding: 0;
            margin-bottom: -2px;
            border-bottom: 2px solid transparent;
        }

        .nav-item {
            a {
                border: none;
                border-bottom: 2px solid transparent;
                color: $body-color;
                margin-bottom: -1px;
                font-size: 18px;
                padding-top: 24px;
                padding-bottom: 24px;
                font-weight: 400;

                &.active, &:hover {
                    border-bottom: 2px solid $primary;
                    color: $primary;
                    font-weight: 700;
                }
            }
        }
    }

    .overview-nav-tabs ~ .tab-content {
        margin-top: 40px;
        margin-bottom: 80px;
    }
}
</style>