<template>
    <div>
        <div class="flags my-5">
            <h2 class="overlay-content-title">{{ $t('overview.tabs.summary.flags.title') }}</h2>
            <!-- <div class="my-3">Prověřena byla mj. rizika mezinárodních sankcí a střetu zájmů. Projděte si <a href="https://popisrizik.datlab.eu" target="blank">úplný seznam</a>.</div> -->

            <GreenFlagRow :item="noSanctionsFlag" v-if="noSanctionsFlagEnabled"/>
            <GreenFlagRow :item="noConflictsFlag" v-if="noConflictsFlagEnabled"/>
            <GreenFlagRow :item="noRisksFlag" v-if="isFlagsEmpty"/>

            <template v-if="flags && flags.length !== 0">
                <FlagRow v-for="flag in flags" :item="flag" v-bind:key="flag.name"/>
            </template>

            <div v-if="!isFlagsEmpty" class="row result-row align-items-center side-bar-grey flag-row">
                <div class="col" v-html="$t('overview.tabs.summary.flags.othersDescriptionLong')"></div>
            </div>
        </div>

        <div class="flags my-5" v-if="buyer_flags && buyer_flags.length !== 0">
            <h2 class="overlay-content-title">{{ $t('overview.tabs.summary.buyer_flags.title', {buyer: buyer}) }}</h2>
            <span>
                <FlagRow v-for="flag in buyer_flags" :item="flag" v-bind:key="flag.name"/>
            </span>
        </div>
    </div>
</template>


<script>
import FlagRow from '@/components/overlay/FlagRow.vue'
import GreenFlagRow from '@/components/overlay/GreenFlagRow.vue'

export default {
    name: "flags-summary",
    props: {
        flags: { type: Array, required: false },
        buyer_flags: { type: Array, required: false },
        buyer: { type: String, required: false }
    },
    computed: {
        isFlagsEmpty: function() {
            return !this.flags || this.flags.length == 0
        },
        noSanctionsFlag: function() {
            return {
                risk_level: "NO",
                name: this.$t("overview.tabs.summary.flags.sanctions"),
                documentation_url: "https://popisrizik.datlab.eu/home/sankce",
                description: this.$t("overview.tabs.summary.flags.noRisk")
            }
        },
        noSanctionsFlagEnabled: function() {
            let codes = ["sankce_rusky_vliv", "sankce_seznam_cz", "sankce_seznam_eu", "sankce_seznam_noneu"]
            return !this.flags || this.flags.findIndex(f => codes.includes(f.code)) < 0
        },
        noConflictsFlag: function() {
            return {
                risk_level: "NO",
                name: this.$t("overview.tabs.summary.flags.conflicts"),
                documentation_url: "https://popisrizik.datlab.eu/home/politické-vazby/st%C5%99et-z%C3%A1jm%C5%AF-obecn%C3%BD",
                description: this.$t("overview.tabs.summary.flags.noRisk")
            }
        },
        noConflictsFlagEnabled: function() {
            let codes = ["stret_zajmu_funkcionar", "stret_zajmu_ministr"]
            return !this.flags || this.flags.findIndex(f => codes.includes(f.code)) < 0
        },
        noRisksFlag: function() {
            return {
                risk_level: "NO",
                name: this.$t('overview.tabs.summary.flags.noOthers'),
                description: this.$t('overview.tabs.summary.flags.othersDescription')
            }
        }
    },
    components: { FlagRow, GreenFlagRow }
}
</script>

<style scoped lang="scss">
.price_thumbs_up {
    position: relative;
    top: -5px;
    left: -5px;
}
</style>