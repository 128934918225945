<template>
    <b-container v-cloak fluid>
        <div v-if="error" class="row justify-content-center mt-5">
            <div class="col-12 col-sm-6">
                <component :is="error"></component>
            </div>
        </div>
        <slot v-else></slot>
    </b-container>
</template>

<script>

import Error403 from "@/components/errors/403.vue"

export default {
    props: {
        "errorStatus": {type: Number}
    },
    components: { Error403 },
    computed: {
        error: function() {
            if (!this.errorStatus) {
                return null
            }

            switch(this.errorStatus) {
                case 403:
                    return 'Error403'
                default:
                    return null
            }
        }
    }
}
</script>

<style>

</style>