<template>
    <UserCompanyForm>
        <template #before>
            <div class="row mb-3 justify-content-center">
                <div class="col-12 col-xl-8 text-center">
                    {{ $t('companyForm.before') }}
                </div>
            </div>
        </template>
        <template #after>
            <div class="row mt-4 justify-content-center">
                <div class="col-12 col-xl-8 text-center text-muted">
                    <small>{{ $t('companyForm.onStart.after') }}</small>
                </div>
            </div>
        </template>
    </UserCompanyForm>
</template>

<script>
import UserCompanyForm from "@/components/UserCompanyForm.vue";

export default {
    components: { UserCompanyForm }
}
</script>
