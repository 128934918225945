<template>
    <b-container v-cloak>
        <Header :subtitle="$t('research.new.title')" :options="{md: 12, lg: 11, xl: 10}"/>

        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-11 col-xl-10 search-form">
                <div>
                    <b-form>
                        <div class="form-group">
                            <b-form-row class="justify-content-center text-start">
                                <div class="col">
                                    <input type="hidden" name="research_type" id="id_research_type" v-model="researchType" /><br>

                                    <div class="row research-selector-row justify-content-center">
                                        <div @click="researchType='TENDERS'" class="col-7 col-sm-6 col-md-5 col-lg-4 mb-5 mb-lg-0">
                                            <div class="research-selector text-center" :class="{ active: researchType == 'TENDERS' }">
                                                <span class="icon"><font-awesome-icon icon="copy"/></span><br>
                                                {{ $t('research.new.references.title') }}
                                                <ul class="research-selector-list">
                                                    <li v-for="(n, i) in $tm('research.new.references.description')" :key="i">{{ $rt(n) }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div @click="researchType='SUPPLIERS'" class="col-7 col-sm-6 col-md-5 col-lg-4 mb-5 mb-lg-0">
                                            <div class="research-selector text-center" :class="{ active: researchType == 'SUPPLIERS' }">
                                                <span class="icon"><font-awesome-icon icon="users"/></span><br>
                                                {{ $t('research.new.candidates.title') }}
                                                <ul class="research-selector-list">
                                                    <li v-for="(n, i) in $tm('research.new.candidates.description')" :key="i">{{ $rt(n) }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-7 col-sm-6 col-md-5 col-lg-4" @click="researchType='SUPPLIER_CHECKER'">
                                            <div class="research-selector text-center" :class="{ active: researchType == 'SUPPLIER_CHECKER' }">
                                                <span class="icon"><font-awesome-icon icon="sitemap"/></span><br>
                                                {{ $t('research.new.supplierChecker.title') }}
                                                <ul class="research-selector-list">
                                                    <li v-for="(n, i) in $tm('research.new.supplierChecker.description')" :key="i">{{ $rt(n) }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-form-row>
                        </div>

                        <div class="form-group text-center">
                            <span id="new-research-submit" v-b-tooltip.hover :title="$t('research.new.start.tooltip')"
                                v-if="!isFormValidForSubmit"
                                class="d-inline-block"
                            >
                                <b-button :disabled="!isFormValidForSubmit" variant="primary" size="lg" @click="submit">
                                    {{ $t('research.new.start.label') }}
                                </b-button>
                            </span>
                            <b-button v-else variant="primary" size="lg" @click="submit">
                                {{ $t('research.new.start.label') }}
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
    props: ["id", "form"],
    data: function() {
        return {
            researchType: null,
            target: '/tender_search'
        }
    },
    components: { Header },
    watch: {
        researchType: function(value) {
            if (value == 'TENDERS') {
                this.target = "/tender_search"
            }

            if (value == 'SUPPLIERS') {
                this.target = "/supplier_search"
            }

            if (value == 'SUPPLIER_CHECKER') {
                this.target = "/supplier-checker_search"
            }
        }
    },
    computed: {
        isFormValidForSubmit: function() {
            return this.researchType != null
        }
    },
    mounted: function() {
    },
    methods: {
        submit: function() {
            if (this.isFormValidForSubmit) {
                this.$router.push(this.target)
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import 'src/scss/custom';

.research-selector {
    height: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    cursor: pointer;
    padding-top: 40px;
}

.research-selector:hover {
    border: 1px solid $primary;
}

.research-selector .icon {
    font-size: 3rem;
}

.research-selector-row .active {
    background-color: $primary;
    border: 1px solid $primary;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    color: white;
}

.research-selector-list {
    padding: 30px 20px 10px 40px;
    font-size: 14px;
    text-align: left;
}
</style>