<template>
    <span>
        <h2 class="overlay-content-title">
            {{ $t('overview.tabs.summary.histogram.title') }}
        </h2>
        <section class="histogram">
            <b-tabs pills class="histogram-tabs" :titles="titles">
                <div class="tab-content" id="nav-tabContent">
                    <b-tab name="tenders_tab_summary" active>
                            <p class="tab-desc">
                                <span v-html="$t('overview.tabs.summary.histogram.tendersDesc', {name: supplier.name, max: tenderMax, min: tenderMin})"></span>
                                <span> Úplný seznam v sekci <a href="#" @click.prevent.stop="changeTab(1)">Veřejné zakázky</a>.</span>
                            </p>
                            <b-container fluid>
                                <Histogram :data="tenderHistogram" value-key="amount" />
                            </b-container>
                    </b-tab>
                    <b-tab name="contracts_tab_summary">
                            <p class="tab-desc">
                                <span v-html="$t('overview.tabs.summary.histogram.contractsDesc', {name: supplier.name, max: contractMax, min: contractMin})"></span>
                                <span> Úplný seznam v sekci <a href="#" @click.prevent.stop="changeTab(2)">Smlouvy</a>.</span>
                            </p>
                            <b-container fluid>
                                <Histogram :data="contractHistogram" value-key="amount" />
                            </b-container>
                    </b-tab>
                </div>
            </b-tabs>
        </section>
    </span>
</template>

<script>
import Histogram from '@/components/overlay/Histogram.vue'

export default {
    name: "histogram-summary",
    props: {
        data: { type: Object, required: true},
        supplier: { type: Object, required: true}
    },
    data: function () {
        return {
            titles: [{
                "name": this.$t('overview.tabs.summary.histogram.tenders'),
                "key": "tenders_tab_summary"
            }, {
                "name": this.$t('overview.tabs.summary.histogram.contracts'),
                "key": "contracts_tab_summary"
            }]
        }
    },
    components: {Histogram},
    computed: {
        tenderHistogram: function() {
            if (!this.data) {
                return null
            }

            return this.getHistogram(this.data.histogram.tender)
        },
        contractHistogram: function() {
            if (!this.data) {
                return null
            }

            return this.getHistogram(this.data.histogram.contract)
        },
        tenderMin: function() {
            return this.getFormatedNumber(this.data.histogram.tender.min_amount)
        },
        tenderMax: function() {
            return this.getFormatedNumber(this.data.histogram.tender.max_amount)
        },
        contractMin: function() {
            return this.getFormatedNumber(this.data.histogram.contract.min_amount)
        },
        contractMax: function() {
            return this.getFormatedNumber(this.data.histogram.contract.max_amount)
        }
    },
    methods: {
        getFormatedNumber: function(value) {
            return this.$filters.number(value, " ", ",", 0)
        },
        getHistogram: function(data) {
            return Object.keys(data)
                .filter(k => k.match(/lvl_\d+$/i))
                .map(k => {
                    return {label: this.getHistogramLabel(k), count: data[k], amount: data[`${k}_amount`]}
                })
        },
        getHistogramLabel: function(key) {
            return this.$t(`overview.tabs.summary.histogram.${key}`)
        },
        changeTab: function(tab){
            this.$emit('changeTab', tab);
        },
    }
}

</script>