<template>
    <div class="mt-5" v-if="research">
        <SupplierOverview :id="research.search_criteria.supplier.id" :tab="0"/>
    </div>
</template>


<script>
import SupplierOverview from '@/components/overlay/SupplierOverview.vue'

export default {
    data: function() {
        return {
        }
    },
    components: { SupplierOverview },
    computed: {
        research: function() {
            return this.$store.getters.research
        }
    }
}
</script>

<style scoped lang="scss">
</style>