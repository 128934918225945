<template>
    <input
        class="form-control"
        :class="[sizeClass]"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :id="id"
        :min="min"
        :max="max"
        :required="required"
        :value="modelValue"

        @input="onInput"
    />
</template>

<script>
    export default {
        name: "b-form-input",
        emits: ['update:modelValue'],
        props: {
            modelValue: {},
            type: {
                type: String,
                required: false,
                default: "text"
            },
            placeholder: {
                type: String,
                required: false,
                default: "text"
            },
            id: {
                type: String,
                required: false,
                default: ""
            },
            name: {
                type: String,
                required: false,
                default: ""
            },
            min: {
                type: [String, Number],
                required: false,
                default: null
            },
            max: {
                type: [String, Number],
                required: false,
                default: null
            },
            debounce: {
                type: Number,
                required: false,
                default: 0
            },
            size: {
                type: String,
                required: false,
                default: null
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data: function() {
            return {
                inputTimeout: null
            }
        },
        computed: {
            sizeClass: function () {
                if (this.size) {
                    return "form-control-" + this.size
                }
                return ""
            }
        },
        methods: {
            onInput: function(event) {
                if (this.inputTimeout != null) {
                    clearTimeout(this.inputTimeout)
                }

                let value = event.target.value

                if (this.debounce == 0) {
                    this.$emit('update:modelValue', value)
                } else {
                    this.inputTimeout = setTimeout(() => this.$emit('update:modelValue', value), this.debounce)
                }
            }
        }
    }
</script>

<style lang="scss">
</style>