<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title" v-if="title">{{ title }}</h4>
            <h6 class="card-subtitle mb-2 text-primary" v-if="subtitle">{{ subtitle }}</h6>
            <div class="mx-auto" :style="{width: width + '%'}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        title: {type: String},
        subtitle: {type: String},
        width: {type: Number, default: 100}
    }
}
</script>

<style lang="scss" scoped>
.card {
    .card-title {
        margin-bottom: .75rem;
    }

    .card-subtitle {
        margin-top: -.375rem;
    }
}
</style>