import { CONFIG } from "../config.js";

export default {
    data: function() {
        return {
            cpvs: [],
            regions: []
        };
    },
    computed: {
        cpvIdsCache: function() {
            return this.cpvs.map(n => n.id)
        }
    },
    methods: {
        getCpvsByIds: function(ids) {
            return new Promise((resolve, reject) => {
                if (!ids || !ids.length) {
                    resolve([])
                } else {
                    var req_ids = ids.filter(i => !this.cpvIdsCache.includes(i))

                    if (req_ids.length) {
                        this.$store.getters.api
                            .post(CONFIG.api.endpoints.cvps, {ids: req_ids})
                            .then(r => {
                                this.cpvs = this.cpvs.concat(r.data).filter((value, index, self) => {
                                    // filter same values -> same ids
                                    var count = 0
                                    var first = false
                                    // loop thought whole array
                                    self.forEach((x, i) => {
                                        if (x.id == value.id){
                                            count++
                                            // get only first element with same id
                                            if (index == i && count == 1){
                                                first = true
                                            }
                                        }
                                    })
                                    return first
                                  })
                                resolve(this.cpvs.filter(n => ids.includes(n.id)))
                            })
                            .catch(error => reject(error))
                    } else {
                        resolve(this.cpvs.filter(n => ids.includes(n.id)))
                    }
                }
            })
        },
        loadRegions: function() {
            return new Promise((resolve, reject) => {
                if (this.regions.length) {
                    resolve(this.regions)
                } else {
                    this.$store.getters.api
                        .get(CONFIG.api.endpoints.region.list)
                        .then(r => {
                            this.regions = r.data
                            resolve(r.data)
                        })
                        .catch(error => {
                            this.regions = [];
                            reject(error);
                        })
                }
            })
        },
        getRegionsByIds: function(ids) {
            if (ids == null) {
                return new Promise((resolve) => resolve([]))
            }

            return new Promise((resolve) =>
                this.loadRegions()
                    .then(() => resolve(this.regions.filter(n => ids.includes(n.id))))
            )
        },
        getSuppliersByIds: function(ids) {
            if (ids == null) {
                return new Promise((resolve) => resolve([]))
            }

            return new Promise((resolve, reject) =>
                this.$store.getters.api
                    .post(CONFIG.api.endpoints.subjects(), {ids: ids})
                    .then(response => resolve(response.data))
                    .catch(e => reject(e))
            )
        }
    }
};
