<template>
    <li class="nav-item dropdown" ref="dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <slot name="button-content"></slot>
        </a>
        <ul :class="['dropdown-menu', {'dropdown-menu-end': right}]">
            <slot></slot>
        </ul>
    </li>
</template>

<script>
import { Dropdown } from 'bootstrap';
export default {
    name: "b-nav-item-dropdown",
    props: {
        right: {type: Boolean, default: false}
    },
    data: function () {
        return {
            id: this.createId(),
            instance: null
        }
    },
    mounted: function () {
        this.instance = new Dropdown(this.$refs.dropdown, {})
    },
    unmounted: function () {
        if (this.instance) {
            this.instance.dispose();
        }
    },
    methods: {
        createId: function () {
            var length = 10;
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
            }
        }
    }
</script>

<style lang="scss">

</style>