<template>
    <div class="tab-pane" :id="name" role="tabpanel" :aria-labelledby="name + '-tab'">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "b-tab",
    props: {
        name: {
            type: String,
            required: false
        },
    }
}
</script>

<style lang="scss">

</style>