<template>
    <div class="references my-5">
        <h2 class="overlay-content-title">{{ $t('overview.tabs.summary.references.title') }}</h2>
        <div class="row" v-if="references && references.length > 0">
            <div class="col-12" v-for="reference in references" v-bind:key="reference.id">
                <TenderRow :item="reference"/>
            </div>
            <div class="col-12" v-if="referencesOthers.count != 0">
                <OtherTendersRow :item="referencesOthers" @changeTab="changeTab" :tab="1"/>
            </div>
        </div>
        <span v-else>
            <section class="flags-data">
                <img src="@/assets/mood_sad.svg" class="price_thumbs_up" /> {{ $t('overview.tabs.summary.references.noData') }}
            </section>
        </span>
    </div>
</template>

<script>
import TenderRow from '@/components/overlay/TenderRow.vue'
import OtherTendersRow from '@/components/overlay/OtherTendersRow.vue'

export default {
    name: "tenders-summary",
    props: {
        references: { type: Array, required: true},
        referencesOthers: { type: Object, required: true}
    },
    components: { TenderRow, OtherTendersRow},
    methods: {
        changeTab: function(tab){
            this.$emit('changeTab', tab);
        },
    }
}

</script>