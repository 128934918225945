<template>
    <b-container v-cloak>
        <div class="row justify-content-center mt-5">
            <div class="col-12 col-sm-6">
                <h2>{{ $t('notFound.title') }}</h2>
                <p>{{ $t('notFound.description') }}</p>
            </div>
        </div>
    </b-container>
</template>


<script>
export default {
    data: function() {
        return {
        }
    }
}
</script>

<style scoped lang="scss">
</style>