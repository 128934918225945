<template>
    <div>
        <a :href="item.documentation_url" target="_blank" v-if="item.documentation_url" class="flag-link">
            <BasicFlagRow :item="item" class="overview-toggler">
                <template v-slot:icon="slotProps">
                    <slot name="icon" v-bind="slotProps"></slot>
                </template>
            </BasicFlagRow>
        </a>
        <div v-else>
            <BasicFlagRow :item="item" :show-help-icon="false">
                <template v-slot:icon="slotProps">
                    <slot name="icon" v-bind="slotProps"></slot>
                </template>
            </BasicFlagRow>
        </div>
    </div>
</template>


<script>
import BasicFlagRow from '@/components/overlay/BasicFlagRow.vue'

export default {
    name: "overview-other-tenders-row",
    props: {
        item: { type: Object, required: true },
    },
    components: { BasicFlagRow },
    computed: {
        level: function(){
            if (!this.item.risk_level || this.item.risk_level === 'NO') {
                return "#c7c7c7";
            }
            else if (this.item.risk_level === 'LOW') {
                return "#f8e71c";
            }
            else if (this.item.risk_level === 'MEDIUM') {
                return "#f5a623";
            }
            return "#ff2700";
        }
    }
};
</script>

<style>
.flag-link{
    color: inherit;
    text-decoration: none;
}
.flag-link:hover{
    color: inherit;
    text-decoration: none;
}
</style>
