export default {
    methods: {
        getUserStats: function(user) {
            return {
                "id": user.id,
                "email": user.email,
                "name": user.name,
                "registred": user.registred,
                "last_activity": user.last_activity,
                "first_activity": user.first_activity,
                "unique_days": user.unique_days,
                "medians": {
                    "inactive_days": this.median(user.inactive_days),
                    "actions_count": user.actions_count,
                    "tender_details_count": user.tender_details_count,
                    "documents_downloaded_count": user.documents_downloaded_count,
                    "changes_count_saved": this.median(user.changes_count_saved),
                    "changes_count_unsaved": this.median(user.changes_count_unsaved),
                },
                "saved_researches_count": user.saved_researches_count
            }
        },
        median: function(values) {
            if (!(values instanceof Array) || values.length === 0)
                return 0;

            values.sort(function(a, b) {
                return a - b;
            });

            var half = Math.floor(values.length / 2);

            if (values.length % 2)
                return values[half];

            return (values[half - 1] + values[half]) / 2.0;
        },
        dateDist: function(date) {
            return this.$moment().diff(date, 'days')
        }
    }
}
