<template>
    <div class="navbar-brand">
        <router-link :to="to">
            <slot></slot>
        </router-link>
  </div>
</template>
<script>
    export default {
        name: "b-navbar-brand",
        props: {
            to: "",
        },
    }
</script>

<style lang="scss">

</style>