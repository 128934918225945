<template>
    <Container>
        <Header :title="$t('research.save.button').toLowerCase()" :subtitle="research ? research.name : $t('research.tenders')" />

        <Loader v-if="requestRunning" :title="$t('research.save.loader')" />
        <template v-else>
            <template v-if="!saved">
                <div class="form-group">
                    <b-form-row v-if="research.name" class="justify-content-center text-center">
                        <div class="col-6">
                            <label class="label">{{ $t('research.save.info') }}</label>
                        </div>
                    </b-form-row>
                    <b-form-row v-else class="justify-content-center text-start">
                        <div class="col-6">
                            <label class="label">{{ $t('research.new.name.label') }}</label>
                            <b-form-input
                                size="lg"
                                type="text"
                                name="research_name"
                                id="id_research_name"
                                v-model="name"
                                :placeholder="$t('research.new.name.placeholder')"
                                @keydown.enter.prevent=''
                            />
                            <small id="id_research_name" class="form-text text-muted">
                                {{ $t('research.save.info') }}
                            </small>
                        </div>
                    </b-form-row>
                </div>
                <b-form-row class="justify-content-center text-center">
                    <div class="col-6">
                        <b-button :disabled="name == null" variant="primary" @click="createNewResearch">{{ $t('research.save.button') }}</b-button>
                    </div>
                </b-form-row>
            </template>
            <div v-else class="form-group justify-content-center text-center">
                <b-form-row class="justify-content-center">
                    <div class="col-8">
                        <div class="success-icon">
                            <font-awesome-icon :icon="['far', 'check-circle']" />
                        </div>
                        <div class="success-message">{{ $t('research.save.saved') }}</div>

                        <b-button variant="outline-primary" size="sm" @click="$ebus.$emit('close-overlay')">{{ $t('research.save.back[0]') }}</b-button>
                        {{ $t('research.save.back[1]') }}
                        <b-link to="/research_list" @click="$ebus.$emit('close-overlay')" class="link-primary">{{ $t('research.save.back[2]') }}</b-link>
                    </div>
                </b-form-row>
            </div>
        </template>
    </Container>
</template>

<script>
import { CONFIG } from "../config.js";
import axios from "axios";
import downloadPdfMixin from "@/plugins/downloadPdfMixin.js";
import Loader from "@/components/Loader.vue";
import Container from "@/components/overlay/Container.vue";
import Header from "@/components/Header.vue";
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import searchFormMixin from "@/plugins/searchFormMixin.js";

export default {
    props: {
        selected: { type: Array, required: true }
    },
    data: function() {
        return {
            saved: false,
            requestRunning: false,
            name: null,
            cancelToken: null,
            fileId: null
        };
    },
    components: { Loader, Container, Header },
    mixins: [downloadPdfMixin, applicationLogMixin, searchFormMixin],
    mounted: function() {
        this.name = this.research.name;
    },
    computed: {
        research: function() {
            return this.$store.getters.research || {};
        },
        isTenderResearch: function() {
            return this.research.research_type == "MarketResearchType.TENDERS"
        },
        isSupplierResearch: function() {
            return this.research.research_type == "MarketResearchType.SUPPLIERS"
        },
        isSupplierTendersResearch: function() {
            return this.research.research_type == "MarketResearchType.SUPPLIER_TENDERS"
        },
        pdfExportEndpoint: function() {
            if (this.isTenderResearch) {
                return CONFIG.api.endpoints.research.tender.pdf;
            }
            if (this.isSupplierResearch) {
                return CONFIG.api.endpoints.research.supplier.pdf;
            }
            if (this.isSupplierTendersResearch) {
                return CONFIG.api.endpoints.research.supplierTenders.pdf;
            }
            return null;
        }
    },
    methods: {
        saveResearch: function(update = false) {
            return new Promise((resolve, reject) => {
                this.requestRunning = true;
                // cancle already running request
                if (this.cancelToken != null) {
                    this.cancelToken.cancel("Operation canceled by the user.");
                }

                // initiate cancel token used to identify, whether ther is a request running already
                this.cancelToken = axios.CancelToken.source();

                var payload = Object.assign({}, this.research.search_criteria, {
                    requested_ids: this.selected.map(n => n.id),
                    research_name: this.research.name,
                    research_id: this.research.id || null,
                    file_id: this.fileId,
                    update_file: update
                });

                // send request to server
                this.$store.getters.api
                    .post(this.pdfExportEndpoint, payload, {
                        cancelToken: this.cancelToken.token
                    })
                    .then(response => {
                        this.cancelToken = null;

                        this.saved = true;
                        this.fileId = response.data.file_id;
                        this.requestRunning = false;

                        resolve(this.fileId);
                    })
                    .catch(e => {
                        if (axios.isCancel(e)) {
                            console.log("Request canceled", e.message);
                        } else {
                            console.log(e);
                        }
                        this.requestRunning = false;

                        reject(e);
                    });
            });
        },
        createNewResearch: function() {
            this.$emit("save-before")

            this.$store.commit("updateResearch", { name: this.name });

            var created = new Date(Date.now())

            this.saveResearch()
                .then(id => {
                    this.$store.commit("updateResearch", { id: id });
                    this.downloadPdf(id);

                    this.loadCriteriaLog()
                        .then(criteriaLog => {
                            var log = {
                                id: this.research.id,
                                name: this.research.name,
                                type: this.research.research_type,
                                criteria: criteriaLog
                            }

                            if (this.isTenderResearch || this.isSupplierTendersResearch) {
                                log["tenders"] = this.selected.map(t => this.buildTenderLog(t))
                            } else {
                                log["suppliers"] = this.selected.map(s => this.buildSupplierLog(s))
                            }

                            this.$log('RESEARCH_SAVE', log, created)

                            this.$emit("save-after")
                        })
                });
        },
        loadCriteriaLog: function() {
            return new Promise((resolve) => {
                let crit = this.research.search_criteria

                Promise.all([
                    this.getCpvsByIds(crit ? crit.cpv_ids : null),
                    this.getRegionsByIds(crit ? crit.works_region_selector : null),
                    this.getSuppliersByIds(crit ? crit.supplier_ids : null)
                ])
                .then(([cpvs, regions, suppliers]) => resolve(this.buildCriteriaLog(this.research.search_criteria, regions, cpvs, suppliers)))
            })
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/scss/custom";
</style>
