<template>
    <div class="detail-item">
        <DetailItemHeader :title="title" />

        <div class="row" v-if="!isValueEmpty">
            <slot></slot>
        </div>
        <div class="row" v-else><div class="col-12">{{ $t("detail.noData") }}</div></div>
    </div>
</template>

<script>
import DetailItemHeader from '@/components/DetailItemHeader.vue';

export default {
    props: {
        title: { type: String, required: true},
        isValueEmpty: { type: Boolean, default: false}
    },
    components: { DetailItemHeader }
}
</script>

<style lang="scss" scoped>
.header-column h6 {
    margin-bottom: 10px;
}
</style>