<template>
    <Container  >
        <Loader v-if="loading" />
        <div v-else-if="!tender">
            {{ $t("overview.unknownTender") }}
        </div>
        <div v-else id="overview-tender">
            <div class="row justify-content-center overlay-header text-center">
               <div class="col">
                    <h1>{{ tender.name }}</h1>
                    <h2 v-if="tender.lot_name">{{ tender.lot_name }}</h2>
                </div>
            </div>
            <TenderDetail :tender="tender" @tender-source-shown="$log('TENDER_SOURCE_VIEW', buildTenderLog(tender))" :disabled-supplier-links="disabledSupplierLinks"/>
        </div>
    </Container>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Container from '@/components/overlay/Container.vue'
import TenderDetail from '@/components/TenderDetail.vue'
import applicationLogMixin from "@/plugins/applicationLogMixin.js";
import { CONFIG } from '@/config.js'

export default {
    name: "tender-overview",
    props: {
        id: { type: [String, Number], required: true},
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    components: { Loader, Container, TenderDetail },
    mixins: [applicationLogMixin],
    data: function() {
        return {
            tender: null,
            loading: false
        }
    },
    computed: {
        name: function() {
            return this.tender ? this.tender.name : ""
        }
    },
    watch: {
        id: function() {
            this.loadData()
        }
    },
    created: function() {
        this.loadData()
    },
    methods: {
        loadData: function() {
            this.loading = true

            this.$store.getters.api
                .post(CONFIG.api.endpoints.tender.overview(this.id))
                .then(response => {
                    this.tender = response.data
                    this.$log("TENDER_DETAIL_VIEW", this.buildTenderLog(this.tender))
                })
                .catch(() => this.data = null)
                .finally(() => this.loading = false)
        }
    }
};
</script>

<style lang="scss">
@import 'src/scss/custom';

#overview-tender {
    .overview-nav-tabs {
        color: $body-color;
        margin-top: 50px;

        & > .row {
            border-bottom: 1px solid $background-light;
        }

        .nav-tabs {
            padding: 0;
            margin-bottom: -2px;
            border-bottom: 2px solid transparent;
        }

        .nav-item {
            a {
                border: none;
                border-bottom: 2px solid transparent;
                color: $body-color;
                margin-bottom: -1px;
                font-size: 18px;
                padding-top: 24px;
                padding-bottom: 24px;
                font-weight: 400;

                &.active, &:hover {
                    border-bottom: 2px solid $primary;
                    color: $primary;
                    font-weight: 700;
                }
            }
        }
    }

    .overview-nav-tabs ~ .tab-content {
        margin-top: 40px;
        margin-bottom: 80px;
    }
}
</style>