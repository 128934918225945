<template>
    <Header :title="title" :subtitle="subtitle" :options="{sm: 12, md: 10, lg: 8, xl: 6}"/>
</template>

<script>
import Header from "@/components/Header.vue"

export default {
    name: "research-header",
    props: ["type"],
    components: { Header },
    data: function() {
        return {
        }
    },
    computed: {
        research: function() {
            return this.$store.getters.research
        },
        title: function () {
            return this.research && this.research.id ? this.$t("research.form.edit.label") : this.$t('research.form.create.label')
        },
        subtitle: function() {
            if (this.research && this.research.name) {
                return this.research.name
            }

            switch (this.type) {
                case "MarketResearchType.TENDERS":
                    return this.$t('research.references').toUpperCase()
                case "MarketResearchType.SUPPLIERS":
                    return this.$t('research.suppliers').toUpperCase()
                case "MarketResearchType.SUPPLIER_TENDERS":
                    return this.$t('research.supplierTenders').toUpperCase()
                case "MarketResearchType.SUPPLIER_CHECKER":
                    return this.$t('research.supplierChecker').toUpperCase()
            }

            return null
        }
    }
};
</script>

<style scoped lang="scss">
</style>
    