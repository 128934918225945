export default {
    data: function() {
        return {
            selectedOrder: null
        }
    },
    methods: {
        sortItems: function(order) {
            this.selectedOrder = order

            this.items.sort(this.orderComparator(order))
        },
        orderComparator: function(order) {
            switch (order) {
                case 'newest':
                    return this.orderBy(n => n.award_date, true)
                case 'oldest':
                    return this.orderBy(n => n.award_date)
                case 'price_desc':
                    return this.orderBy(n => n.price_national, true)
                case 'price_asc':
                    return this.orderBy(n => n.price_national)
                case 'name':
                    return this.orderBy(n => (n.name + n.lot_name).toLowerCase())
                case 'ref_size':
                    return this.orderBy(n => n.reference_tenders_sum, true)
                case 'ref_count':
                    return this.orderBy(n => n.reference_tenders_count, true)
                case 'buyer':
                    return this.orderBy(n => n.buyer ? n.buyer.name : null)
                case 'supplier':
                    return this.orderBy(n => n.supplier ? n.supplier.name : null)
                case 'cpv_code':
                    return this.orderBy(n => n.cpv ? n.cpv.code : null)
                case 'cpv_name':
                    return this.orderBy(n => n.cpv ? n.cpv.name : null)
                case 'subject':
                    return this.orderBy(n => n.subject)
                default:
                    console.error('Unknown order type ' + order)
                    return null
            }
        },
        orderBy: function(key, desc) {
            function compare(a, b) {
                let val_a = key(a),
                    val_b = key(b);

                if (val_b == null) {
                    return -1
                } else if (val_a == null) {
                    return 1
                } if (val_a < val_b) {
                    return desc ? 1 : -1
                } else if (val_a > val_b) {
                    return desc ? -1 : 1
                }

                return 0
            }

            return compare
        }
    }
}
