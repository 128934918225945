<template>
    <li class="nav-item">
        <router-link :to="to" class="nav-link" v-if="to">
            <slot></slot>
        </router-link>
        <a :href="href" v-if="href" class="nav-link" @click="$emit('click', $event)">
            <slot></slot>
        </a>
  </li>
</template>
<script>
    export default {
        name: "b-nav-item",
        props: {
            to: {
                type: String,
                required: false,
                default: ""
            },
            href: {
                type: String,
                required: false,
                default: ""
            },
        },
    }
</script>

<style lang="scss">

</style>