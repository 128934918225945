<template>
    <table v-if="data" class="table table-sm table-borderless text-center table-hover">
        <thead>
            <tr>
                <th></th><th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>5+</th>
            </tr>
        </thead>
        <tbody>
            <tr><th>7 dní</th><td v-for="(n, i) in data.d7" :key="'7-' + i">{{ n }}</td></tr>
            <tr><th>14 dní</th><td v-for="(n, i) in data.d14" :key="'14-' + i">{{ n }}</td></tr>
            <tr><th>30 dní</th><td v-for="(n, i) in data.d30" :key="'30-' + i">{{ n }}</td></tr>
        </tbody>
    </table>    
</template>


<script>
export default {
    props: {
        data: { type: Object}
    }
}
</script>

<style lang="scss" scoped>
</style>