<template>
    <select
        v-model="selected"
        :class="['form-select', size ? `form-select-${size}` : '']"
        @change="$emit('update:modelValue', selected)"
    >
        <option
            v-for="opt in options"
            :value="opt"
        >
            {{ opt }}
        </option>
    </select>
</template>

<script>
export default {
    name: "b-form-select",
    props: {
        modelValue: {},
        size: {type: String},
        options: {type: Array, required: true}
    },
    data: function() {
        return {
            selected: this.modelValue
        }
    },
    emits: ['update:modelValue'],
    watch: {
        modelValue: function(val) {
            this.selected = val
        }
    }
}
</script>

<style lang="scss">

</style>