<template>
    <button class="navbar-toggler" type="button" data-toggle="collapse" :data-target="'#' + target"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "b-navbar-toggle",
        props: {
            target: "",
    }
    }
</script>

<style lang="scss">

</style>