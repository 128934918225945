<template>
    <div v-if="documents.length">
        <div class="row detail-item mb-10 mt-10">
            <div class="col-12">
                <p class="fw-bold">{{ title }}</p>
            </div>
        </div>

        <template v-for="(d, i) in documents"  :key="i">
            <div class="row detail-item mb-10">
                <div class="col-12">
                    <a :href="getDocumentUrl(d)" target="blank" @click="logDocumentDownload(d)">{{ decodeURIComponent(d.name) }}</a>
                    &nbsp;<b-badge v-if="d.machine_readable" pill variant="dark">{{ $t("detail.documents.machineReadable") }}</b-badge>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { CONFIG } from '@/config.js'

export default {
    props: {
        title: { type: String, required: true},
        documents: { type: Array, default: () => []},
        origin: { type: String, required: true}
    },
    methods: {
        getDocumentUrl: function(document) {
            if (document.url != null && !document.url.match("^https?://") && document.origin != null) {
                var domain = CONFIG.documentOrigin[document.origin.replace("DocumentOrigin.", "")]
                if (domain !== undefined) {
                    return domain + document.url
                }
            }

            return document.url
        },
        logDocumentDownload: function(document) {
            this.$log("DOCUMENT_DOWNLOAD", {
                title: document.name,
                url: document.url,
                is_machine_readable: document.machine_readable,
                type: document.doc_type
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>