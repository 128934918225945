<template>
    <div v-if="data" class="row text-center">
        <div class="col-4">
            <strong class="h2">{{ data.day }}</strong><br><small class="text-muted text-nowrap">den</small>
        </div>
        <div class="col-4">
            <strong class="h2">{{ data.week }}</strong><br><small class="text-muted text-nowrap">týden</small>
        </div>
        <div class="col-4">
            <strong class="h2">{{ data.month }}</strong><br><small class="text-muted text-nowrap">měsíc</small>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Object}
    }
}
</script>

<style lang="scss" scoped>
</style>