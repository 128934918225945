<template>
    <div :class="['input-group', 'mb-3', size ? `input-group-${size}` : '']">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "b-input-group",
    props: {
        size: {type: String}
    }
}
</script>

<style lang="scss">

</style>