<template>
    <div class="row justify-content-center">
       <div class="col">
            <div v-if="tender.source" class="row detail-head justify-content-center">
                <div class="col text-center">
                    <a :href="tender.source" @click="$emit('tender-source-shown')" target="_blank">
                        <template v-if="isContractRegistry">{{ $t("detail.contract.link") }}</template>
                        <template v-else>{{ $t("detail.tender.link") }}</template>
                    </a>
                </div>
            </div>

            <DetailRow>
                <DetailItem :title="$t('detail.publicationDate')">
                    <div class="col-6" v-if="tender.publication_date">
                        <span v-if="isContractRegistry">{{ $t('detail.contract.announced') }}</span>
                        <span v-else>{{ $t('detail.tender.announced') }}</span>
                        <br />
                        <p class="fw-bold">{{ $filters.date(tender.publication_date) }}</p>
                    </div>
                    <div class="col-6" v-if="tender.award_date">
                        <span v-if="isContractRegistry">{{ $t('detail.contract.awarded') }}</span>
                        <span v-else>{{ $t('detail.tender.awarded') }}</span>
                        <br />
                        <p class="fw-bold">{{ $filters.date(tender.award_date) }}</p>
                    </div>
                </DetailItem>
            </DetailRow>

            <DetailRow>
                <DetailItem
                    :title="$t('detail.priceLabel')"
                    :isValueEmpty="!tender.price_national && !tender.estimated_price_national && !tender.payments_price">
                    <div v-if="tender.price_national" class="col text-nowrap">
                        <span>{{ $t("detail.finalPrice") }}</span>
                        <p class="fw-bold">{{ $filters.number($filters.integer(tender.price_national)) }} {{ $t("currency.czk") }}</p>
                    </div>
                    <div v-if="tender.payments_price" class="col text-nowrap">
                        <span>{{ $t("detail.paymentsPrice") }}</span>
                        <br />
                        <p class="fw-bold">{{ $filters.number($filters.integer(tender.payments_price)) }} {{ $t("currency.czk") }}</p>
                    </div>
                    <div v-if="tender.estimated_price_national" class="col text-nowrap">
                        <span>{{ $t("detail.estimatedPrice") }}</span>
                        <br />
                        <p class="fw-bold">{{ $filters.number($filters.integer(tender.estimated_price_national)) }} {{ $t("currency.czk") }}</p>
                    </div>
                </DetailItem>
            </DetailRow>

            <DetailRow>
                <DetailItem :title="$t('detail.cpv')" :isValueEmpty="!tender.cpv">
                    <div class="col-12" v-if="tender.cpv">
                        <strong>{{ tender.cpv.code }}</strong>
                        {{ tender.cpv.name }}
                    </div>
                </DetailItem>

                <DetailItem :title="$t('detail.priceCriteria')" :isValueEmpty="!sortedPriceCriteria.length">
                    <div class="col-12" v-if="isLowestPrice">{{ $t("detail.lowestPrice") }}</div>
                    <div class="col-12" v-else-if="sortedPriceCriteria.length">
                        <div v-for="(c, i) in sortedPriceCriteria" :key="i">
                            <span v-if="c.weight">{{ c.weight }}%&nbsp;-&nbsp;</span>
                            <span>{{ c.name }}</span>
                        </div>
                    </div>
                </DetailItem>

                <DetailItem :title="$t('detail.criterias')" v-if="sortedOtherCriteria.length">
                    <div class="col-12">
                        <div v-for="(c, i) in sortedOtherCriteria" :key="i">
                            <span v-if="c.weight">{{ c.weight }}%&nbsp;-&nbsp;</span>
                            <span v-if="c.category_name">{{ c.category_name }}&nbsp;-&nbsp;</span>
                            <span>{{ c.name }}</span>
                        </div>
                    </div>
                </DetailItem>

                <DetailItem :title="$t('detail.description')" :isValueEmpty="!tender.description">
                    <div class="col-12 tender-description">{{ tender.description }}</div>
                </DetailItem>
            </DetailRow>

            <DetailRow>
                <DetailItemHeader :title="$t('result.buyer')" />
                <DetailItemBody :body="tender.buyer" />
            </DetailRow>

            <DetailRow>
                <DetailItemHeader :title="$t('result.supplier')" />
                <DetailItemBody :body="tender.supplier" v-if="disabledSupplierLinks"/>
                <DetailItemBody :body="tender.supplier" :name-on-click="() => $overlaySupplier(tender.supplier.id)" v-else/>
            </DetailRow>

            <DetailRow>
                <DetailItem :title="$t('detail.competitors')" :isValueEmpty="!tender.competitors || !tender.competitors.length" class="mb-0"/>

                <div v-for="c in tender.competitors" :key="c.id" class="row detail-item mb-10 competitor">
                    <div class="col-12">
                        <p v-if="disabledSupplierLinks">{{ c.name }}</p>
                        <a v-else href="#" class="fw-bold" @click.stop.prevent="$overlaySupplier(c.id)">{{ c.name }}</a>
                        <p v-if="c.organization_id" class="sub-value">{{ $t("detail.organizationId") }}: {{ c.organization_id }}</p>
                    </div>
                </div>
            </DetailRow>

            <DetailRow v-if="tenderAuditEnabled && Object.keys(audits).length">
                <DetailItemHeader :title="$t('result.audit.label')" />
                <div v-for="(v, k) in audits" :key="`audit-source-${k}`" class="audit-group">
                    <h4>{{ $t(`audit.source.${k}`) }}</h4>

                    <div v-for="n in v" :key="`audit-${n.id}`" class="audit-group-item">
                        <div class="icon">
                            <font-awesome-icon v-if="n.audit_state == 'WITHOUT_FINDINGS'" icon="check" class="text-success" />
                            <font-awesome-icon v-else-if="n.audit_state == 'WITH_FINDINGS'" icon="bug" class="text-danger" />
                            <font-awesome-icon v-else-if="n.audit_state == 'CANCELED'" icon="times" class="text-warning" />
                            <font-awesome-icon v-else-if="n.audit_state == 'APPROVING'" :icon="['fa', 'clock']" />
                            <font-awesome-icon v-else icon="question" class="text-info" />
                        </div>
                        <div>
                            <template v-if="n.source == 'UOHS'">
                                <div>{{ $t(`audit.state.${n.audit_state}`) }}</div>
                                <div class="small" v-html="n.description">
                                </div>
                            </template>
                            <template v-else>
                                <div v-if="n.audit_type">{{ $t(`audit.state.${n.audit_state}`) }}</div>
                                <div v-else>{{ $t("audit.state.NO_AUDIT") }}</div>
                                <div class="small">
                                    <span v-if="n.audit_type">{{ n.audit_type }}</span>

                                    <span v-if="n.date_start || n.date_end">
                                        &nbsp;|&nbsp;
                                        <font-awesome-icon :icon="['far', 'calendar']" />
                                        {{ $filters.date_short(n.date_start) }} - {{ $filters.date_short(n.date_end) }}
                                    </span>

                                    <span v-if="n.correction">
                                        &nbsp;|&nbsp;
                                        <font-awesome-icon icon="coins" />
                                        {{ $filters.number(n.correction) }} {{ $t("currency.czk") }}
                                    </span>

                                    <span v-if="n.audit_state == 'WITH_FINDINGS'">
                                        &nbsp;|&nbsp;{{ $t("result.audit.totalCount") }} {{ n.total_count }}
                                    </span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </DetailRow>

            <DetailRow v-if="hasDocuments">
                <DetailItemHeader :title="$t('detail.documents.label')" no-margin />

                <DetailItemDocuments :title="$t('detail.documents.specifications')" :documents="specificationDocuments" :origin="tender.origin" />

                <DetailItemDocuments :title="$t('detail.documents.contracts')" :documents="contractDocuments" :origin="tender.origin" />

                <DetailItemDocuments :title="$t('detail.documents.other')" :documents="otherDocuments" :origin="tender.origin" />
            </DetailRow>
        </div>
    </div>
</template>

<script>
import { CONFIG } from "@/config.js";

import DetailItem from "@/components/DetailItem.vue";
import DetailItemHeader from "@/components/DetailItemHeader.vue";
import DetailItemDocuments from "@/components/DetailItemDocuments.vue";
import DetailItemBody from "@/components/DetailItemBody.vue";
import DetailRow from "@/components/DetailRow.vue";
import applicationLogMixin from "@/plugins/applicationLogMixin.js";

export default {
    props: {
        tender: { type: Object, required: true },
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    data: function() {
        return {};
    },
    components: {
        DetailRow,
        DetailItem,
        DetailItemHeader,
        DetailItemDocuments,
        DetailItemBody
    },
    mixins: [applicationLogMixin],
    computed: {
        isContractRegistry: function() {
            return this.tender.origin == "TenderOrigin.CONTRACT_REGISTRY";
        },
        hasDocuments: function() {
            return this.tender.documents && this.tender.documents.length > 0;
        },
        specificationDocuments: function() {
            if (this.tender.documents) {
                return this.tender.documents.filter(
                    n => n.doc_type == "DocumentType.SPECIFICATION"
                );
            }

            return [];
        },
        contractDocuments: function() {
            if (this.tender.documents) {
                return this.tender.documents.filter(
                    n => n.doc_type == "DocumentType.CONTRACT"
                );
            }

            return [];
        },
        otherDocuments: function() {
            if (this.tender.documents) {
                return this.tender.documents.filter(
                    n => n.doc_type == "DocumentType.OTHER"
                );
            }

            return [];
        },
        awardCriteria: function() {
            return this.tender.criteria ? this.tender.criteria : [];
        },
        hasAwardCriteria: function() {
            return this.awardCriteria.length;
        },
        sortedPriceCriteria: function() {
            var criteria = []
            this.awardCriteria
                .filter(n => this.isPriceCriterium(n))
                .forEach(n => {
                    let crit = {...n}
                    if (this.equalCaseInsensitive(crit.name, 'PRICE') || this.equalCaseInsensitive(crit.name, 'AC_PRICE')) {
                        crit.name = this.$t("detail.price")
                    }
                    criteria.push(crit)
                })
            return criteria.sort(this.sortByWeight)
        },
        sortedOtherCriteria: function() {
            var criteria = []
            this.awardCriteria
                .filter(n => !this.isPriceCriterium(n))
                .forEach(n => {
                    let crit = {...n}
                    if (crit.category != null) {
                        crit.category_name = this.$t(`searchForm.awardCriteria.values.${crit.category.toUpperCase()}`)
                    }
                    criteria.push(crit)
                })
            return criteria.sort(this.sortByWeight)
        },
        isLowestPrice: function() {
            return (
                this.awardCriteria.length == 1 && this.isPriceCriterium(this.awardCriteria[0])
            );
        },
        audits: function() {
            return this.tender.audits_grouped || []
        },
        tenderAuditEnabled: () => CONFIG.tenderAuditEnabled
    },
    methods: {
        sortByWeight: function(a, b) {
            if (parseInt(a.weight) < parseInt(b.weight)) {
                return 1;
            }

            if (parseInt(a.weight) > parseInt(b.weight)) {
                return -1;
            }

            return 0;
        },
        logDetail: function(type) {
            this.$log(type, this.buildTenderLog(this.tender))
        },
        isPriceCriterium: function(crit) {
            return this.equalCaseInsensitive(crit?.category, 'PRICE')
                || this.equalCaseInsensitive(crit?.name, 'PRICE')
                || this.equalCaseInsensitive(crit?.name, 'AC_PRICE')
        },
        equalCaseInsensitive: function(str1, str2) {
            if (str1 == null || str2 == null) {
                return false
            }

            return str1.toLowerCase() === str2.toLowerCase()
        }
    }
};
</script>

<style lang="scss">
@import 'src/scss/custom';

.detail-head {
    margin-bottom: 30px;
    padding: 10px;
}

.sub-value {
    font-size: 80%;
}

.competitor {
    border-radius: 8px;
}

.tender-description {
    white-space: pre-wrap;
}

.audit-group {
    h4 {
        font-size: 14px;
        font-weight: 700;
    }

    .audit-group-item {
        display: flex;
        margin-left: 32px;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $theme-color-2;

        & + .audit-group-item {
            margin-top: 5px;
        }

        .icon {
            width: 32px;
        }

        .small {
            color: $dark;
            font-size: 12px;
        }
    }

    & + .audit-group {
        margin-top: 15px;
    }
}
</style>