import { CONFIG } from '../config.js'
import fileDownload from 'js-file-download';

export default {
    computed: {
        filenameRegexp: function() {
            return [
                /filename\*=.+'[^']*'([^ ]+)/,
                /filename="([^"]+)"/,
                /filename=([^ ]+)/
            ]
        }
    },
    methods: {
        downloadPdf: function(researchId) {
            this.$store.getters.api
                .get(CONFIG.api.endpoints.research.pdfDownload(researchId), {
                    responseType: 'blob',
                })
                .then(r => {
                    for (var i in this.filenameRegexp) {
                        var fileName = this.filenameRegexp[i].exec(r.headers["content-disposition"])
                        if (fileName) {
                            fileDownload(r.data, decodeURIComponent(fileName[1]))
                            return
                        }
                    }

                    throw new Error("Unable to download file because of filename parsing failed.")
                })
                .catch(e => { throw new Error(e) })
        }
    }
}
