<template>
    <Loader v-if="loading" />
    <Empty
        v-else-if="!data || !data.length"
        :title="$t('overview.tabs.competitors.nodata.title')"
        :subtitle="$t('overview.tabs.competitors.nodata.subtitle')"
    />
    <div v-else id="competitors">
        <CompetitorRow
            v-for="c in data"
            :key="`competitor-${c.id}`"
            :competitor="c"
            :supplier="supplier"
            :disabled-supplier-links="disabledSupplierLinks"
        />
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Empty from '@/components/overlay/Empty.vue'
import CompetitorRow from '@/components/CompetitorRow.vue'

import { CONFIG } from '@/config.js'

export default {
    name: "supplier-competitors",
    props: {
        supplier: { type: Object, required: true},
        disabledSupplierLinks: { type: Boolean, required: false, default: false }
    },
    components: { Loader, Empty, CompetitorRow },
    data: function() {
        return {
            data: null,
            loading: false
        }
    },
    created: function() {
        this.loadData()
    },
    methods: {
        loadData: function() {
            this.loading = true

            var data = {
                params: {
                    from_year: this.$store.getters.concurrencyFromYear,
                }
            }
            this.$store.getters.api
                .get(CONFIG.api.endpoints.supplier.competitors(this.supplier.id), data)
                .then(response => this.data = response.data)
                .catch(() => this.data = null)
                .finally(() => {
                    this.loading = false
                    this.$emit('count', this.data.length)
                })
        }
    }
}
</script>

<style lang="scss">

</style>