<template>
    <div v-if="body.name">
        <div class="row detail-item mb-10">
            <div class="col-12">
                <a v-if="nameOnClick" href="#" @click.stop.prevent="nameOnClick()" class="fw-bold">{{ body.name }}</a>
                <p v-else class="fw-bold">{{ body.name }}</p>
                <p v-if="body.organization_id" class="sub-value">{{ $t("detail.organizationId") }}: {{ body.organization_id }}</p>
            </div>
        </div>

        <div class="row" v-if="hasSomeContact">
            <div class="col-6">
                <address>
                    <span v-if="body.street">{{ body.street }}<br></span>
                    <span v-if="body.city">{{ body.city }}<br></span>
                    <span v-if="body.postal_code">{{ body.postal_code }}<br></span>
                </address>
            </div>
            <div class="col-6">
                <p v-if="body.contact_email"><font-awesome-icon fas icon="envelope" /> {{ body.contact_email }}</p>
                <p v-if="body.contact_number"><font-awesome-icon fas icon="phone" /> {{ body.contact_number }}</p>
            </div>
        </div>
    </div>
    <div v-else>
        {{ $t("detail.noData") }}
    </div>
</template>

<script>
export default {
    props: {
        body: { type: Object, required: true},
        nameOnClick: {type: Function}
    },
    computed: {
        hasSomeContact: function() {
            return this.body.street || this.body.city || this.body.postalCode
                || this.body.contactEmail || this.body.contactNumber
        }
    }
}
</script>

<style lang="scss" scoped>

</style>