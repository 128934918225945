import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store.js';

import ResearchList from '@/views/ResearchList.vue';
import ResearchSearchForm from '@/views/ResearchSearchForm.vue';
import ResearchSearchResultTender from '@/views/ResearchSearchResultTender.vue';
import ResearchSearchResultSupplier from '@/views/ResearchSearchResultSupplier.vue';
import ResearchSearchResultSupplierTenders from '@/views/ResearchSearchResultSupplierTenders.vue';
import ResearchSearchResultSupplierChecker from '@/views/ResearchSearchResultSupplierChecker.vue';
import CreateResearch from '@/views/CreateResearch.vue';
import ApplogOverview from '@/views/ApplogOverview.vue';
import ApplogOverviewUser from '@/views/ApplogOverviewUser.vue';
import ExternalSearch from '@/views/ExternalSearch.vue';
import NotFound from '@/views/NotFound.vue';
import BuyerInsight from '@/views/BuyerInsight.vue';
import SupplierOverview from '@/views/SupplierOverview.vue';
import SupplierExternalOverview from '@/views/SupplierExternalOverview.vue';
import TenderOverview from '@/views/TenderOverview.vue';
import Dropdown from '@/views/Dropdown.vue';
import ServerError from '@/views/ServerError.vue';

import i18n from "@/plugins/i18n.js"

import { CONFIG } from "@/config.js"

function getPageTitle(key) {
    return `${i18n.global.t("title.prefix")} - ${i18n.global.t("title." + key)}`
}

const routes = [
    {
        path: '/',
        redirect: "/main_page",
        name: "home"
    },
    {
        path: '/login',
        beforeEnter() {
            window.location = CONFIG.api.baseUrl + "/login?next=" + encodeURIComponent(window.location.href)
        },
        name: "login"
    },
    {
        path: '/research_list',
        component: ResearchList,
        name: "research-list",
        meta: {title: getPageTitle("researchList")}
    },
    {
        path: '/main_page',
        component: CreateResearch,
        name: "main-page",
        meta: {title: getPageTitle("mainPage")}
    },
    {
        path: "/tender_result",
        component: ResearchSearchResultTender,
        name: "tender-result",
        meta: {title: getPageTitle("search.tender")}
    },
    {
        path: "/supplier_result",
        component: ResearchSearchResultSupplier,
        name: "supplier-result",
        meta: {title: getPageTitle("search.supplier")}
    },
    {
        path: "/supplier-tenders_result",
        component: ResearchSearchResultSupplierTenders,
        name: "supplier-tenders-result",
        meta: {title: getPageTitle("search.supplierTenders")}
    },
    {
        path: "/supplier-checker_result",
        component: ResearchSearchResultSupplierChecker,
        name: "supplier-checker-result",
        meta: {title: getPageTitle("search.supplierTenders")}
    },
    {
        path: '/:form(tender_[^/]*)/:id?',
        props: true,
        component: ResearchSearchForm,
        name: "tender-research-form",
        meta: {title: getPageTitle("search.tender")}
    },
    {
        path: '/:form(supplier_[^/]*)/:id?',
        props: true,
        component: ResearchSearchForm,
        name: "supplier-research-form",
        meta: {title: getPageTitle("search.supplier")}
    },
    {
        path: '/:form(supplier-checker_[^/]*)/:id?',
        props: true,
        component: ResearchSearchForm,
        name: "supplier-checker-research-form",
        meta: {title: getPageTitle("search.supplierTenders")}
    },
    {
        path: '/applog-overview',
        component: ApplogOverview,
        name: "applog-overview",
        meta: {title: "Applog overview"}
    },
    {
        path: '/applog-overview/:id',
        component: ApplogOverviewUser,
        props: true,
        name: "applog-overview-user",
        meta: {title: "User applog overview"}
    },
    {
        path: '/buyer-insight',
        component: BuyerInsight,
        name: "buyer-insight"
    },
    {
        path: '/buyer-insight/:id',
        props: true,
        component: BuyerInsight,
        name: "buyer-insight-detail"
    },
    {
        path: '/supplier/:id',
        props: true,
        component: SupplierOverview,
        name: "supplier"
    },
    {
        path: '/external/search',
        component: ExternalSearch,
        name: "external-search"
    },
    {
        path: '/external/supplier/:id',
        props: true,
        component: SupplierExternalOverview,
        name: "external-supplier"
    },
    {
        path: '/tender/:id',
        props: true,
        component: TenderOverview,
        name: "tender"
    },
    {
        path: '/dropdown',
        component: Dropdown,
        name: "dropdown"
    },
    {
        path: '/error',
        component: ServerError,
        name: 'error'
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        name: "not-found"
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = getPageTitle("default")
    }

    if (store.getters.isLoggedIn || to.path == '/login') {
        if (store.getters.isExternal && to.name !== 'external-supplier' && to.name !== 'external-search'){
            next("/external/search");
        }
        else{
            next();
        }
    } else {
        store.dispatch("loadUserProfile")
            .then(() => {
                if (!store.getters.isLoggedIn) {
                    next('/login')
                } else {
                    if (store.getters.isExternal && to.name !== 'external-supplier' && to.name !== 'external-search'){
                        next("/external/search");
                    }
                    else{
                        next();
                    }
                }
            })
            .catch(() => {
                next('/login')
            })
    }
})

export default router;