<template>
    <div :class="[container]">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "b-container",
        props: {
            fluid: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        computed: {
            container: function(){
                if (this.fluid) {
                    return "container-fluid"
                }
                return "container"
            }
        }
    }
</script>

<style lang="scss">

</style>