<template>
    <div class="row align-items-center">
        <div class="col-auto">
            <font-awesome-icon icon="shield-alt" size="4x"/>
        </div>
        <div class="col">
            <h2>Chyba 403</h2>
            <p class="mb-0">Sem nemáte přístup</p>
        </div>
    </div>
</template>


<script>
export default {
    data: function() {
        return {
        }
    }
}
</script>

<style scoped lang="scss">
</style>