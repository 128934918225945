<template>
    <TenderOverview :id="id" class="mt-5"/>
</template>

<script>
import TenderOverview from '@/components/overlay/TenderOverview.vue'
export default {
    props: {
        id: {type: [String, Number], required: true}
    },
    data: function() {
        return {
        }
    },
    components: {
        TenderOverview
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

</style>