<template>
    <div class="row result-row align-items-center side-bar-grey flag-row">
        <div class="col-auto">
            <slot name="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="1.5" stroke="none" :fill="level" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="12" r="9" />
                </svg>
            </slot>
        </div>

        <div class="col-3">
            <span>{{ item.name }}</span>
            <b-icon-question-circle v-if="showHelpIcon"
                font-scale="0.95"
                variant="dark"
                class="ms-1"
            />
        </div>

        <div class="col" v-html="item.description"></div>
    </div>
</template>

<script>
export default {
    name: "overview-base-tenders-row",
    props: {
        item: { type: Object, required: true },
        showHelpIcon: { type: Boolean, required: false, default: true },
    },
    computed: {
        level: function(){
            if (!this.item.risk_level || this.item.risk_level === 'NO'){
                return "#c7c7c7";
            }
            else if (this.item.risk_level === 'LOW'){
                return "#f8e71c";
            }
            else if (this.item.risk_level === 'MEDIUM'){
                return "#f5a623";
            }
            return "#ff2700";
        }
    }
};
</script>

<style>
.side-bar-grey {
    border-left: none !important;
}

.flag-row {
    padding: 18px 0;
}

.flag-row > .col-auto {
    width: 60px;
    text-align: center;
}
</style>