<template>
    <Loader v-if="loading" />
    <div v-else-if="!data"></div>
    <div v-else id="summary">
        <CompanySummary :summary="data.summary" @changeTab="changeTab" :disabled-supplier-links="disabledSupplierLinks">
            <a
                :href="downloadLink"
                class="btn btn-primary mt-4 my-md-0"
            >
                Stáhnout průzkum v pdf
            </a>
        </CompanySummary>
        <FlagsSummary :flags="data.flags" :buyer_flags="data.buyer_flags" :buyer="data.buyer"/>
        <TendersSummary :references="data.references" :references-others="data.references_others" @changeTab="changeTab"/>
        <MapSummary :data="data" :supplier="supplier" v-if="data.references !== null && data.references.length > 0"/>
        <HistogramSummary :data="data" :supplier="supplier" v-if="data.references !== null && data.references.length > 0" @changeTab="changeTab"/>
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import TendersSummary from '@/components/summary/TendersSummary.vue'
import FlagsSummary from '@/components/summary/FlagsSummary.vue'
import MapSummary from '@/components/summary/MapSummary.vue'
import HistogramSummary from '@/components/summary/HistogramSummary.vue'
import CompanySummary from '@/components/summary/CompanySummary.vue'

import { CONFIG } from '@/config.js'

export default {
    name: "supplier-summary",
    props: {
        supplier: { type: Object, required: true},
        disabledSupplierLinks: { type: Boolean, required: false, default: false}
    },
    components: { Loader, TendersSummary, FlagsSummary, MapSummary, HistogramSummary, CompanySummary },
    data: function() {
        return {
            data: null,
            loading: false
        }
    },
    created: function() {
        this.loadData()
    },
    computed: {
        downloadLink: function() {
            const url = new URL(CONFIG.api.baseUrl + '/api/supplier_pdf/' + this.supplier.id);
            // add parameters
            if(this.cpv){
                url.searchParams.append("cpv", this.cpv);
            }
            if(this.buyerIco){
                url.searchParams.append("buyer_ico", this.buyerIco);
            }

            return url.href;
        },
        cpv: function(){
            return this.$route.query.cpv
        },
        buyerIco: function(){
            return this.$route.query.buyer_ico
        }
    },
    methods: {
        loadData: function() {
            this.loading = true;

            var data = {
                params: {
                    buyer_ico: this.buyerIco,
                    cpv: this.cpv,
                    conccurency_from_year: this.$store.getters.concurrencyFromYear
                }
            }

            this.$store.getters.api
                .get(CONFIG.api.endpoints.supplier.summary(this.supplier.id), data)
                .then(response => this.data = response.data)
                .catch(() => this.data = null)
                .finally(() => this.loading = false)
        },
        changeTab: function(tab){
            this.$emit('changeTab', tab);
        },
    }
}
</script>

<style lang="scss">
@import 'src/scss/custom';

#summary {
    .tab-desc {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    section {
        margin-bottom: 66px;
        padding: 36px 32px;
        border-radius: 8px;
        background-color: $theme-color-1;
    }

    .download-section {
        padding: 0px 32px;
    }

    .map {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .overlay-content-title {
            padding-left: 32px;
            padding-right: 32px;
        }

        .map-description {
            padding-left: 32px;
            padding-right: 32px;
            margin-top: 30px;
            margin-bottom: 50px;
        }

        svg {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    .histogram-tabs {
        li.nav-item {
            font-size: 14px;
            padding-top: 2px;
            padding-bottom: 2px;

            &:first-of-type {
                padding-left: 2px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-of-type {
                padding-right: 2px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            background-color: lightgray;

            a {
                color: $dark;
                padding: 5px 7px;
                border-bottom: 0px;
                font-size: 14px;
                border: none;
                &:hover{
                    border: none;
                    color: $dark;
                    font-weight: normal;
                }

                &.active {
                    color: white;
                    background-color: $primary;
                    font-weight: 700;
                    border: none;
                }
            }
        }
    }
}
</style>