import { createI18n } from "vue-i18n";
import en from "@/messages/en.json";
import cs from "@/messages/cs.json";

const i18n = createI18n({
    locale: "cs", // set locale
    warnHtmlInMessage: "off",
    messages: {
        en: en,
        cs: cs
    },
    pluralizationRules: {
        cs: function(choice, choicesLength) {
            if (choice === 0) {
                return 0;
            } else if (choice === 1) {
                return 1;
            } else if (choice >= 2 && choice <= 4) {
                return 2;
            }

            return choicesLength < 4 ? 2 : 3;
        }
    },
    numberFormats: {
        en: {
            currency: {
                style: "currency",
                currency: "USD"
            },
            decimal: {
                style: "decimal"
            }
        },
        cs: {
            currency: {
                style: "currency",
                currency: "CZK",
                currencyDisplay: "symbol"
            },
            decimal: {
                style: "decimal"
            }
        }
    }
});

export default i18n;