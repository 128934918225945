<template>
    <div class="row">
        <div class="col-12 header-column" :class="{'no-margin': noMargin}">
            <h6>{{ title }}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true},
        noMargin: { type: Boolean, default: false}
    }
}
</script>

<style lang="scss" scoped>
.header-column h6 {
    margin-bottom: 10px;
}

.header-column.no-margin h6 {
    margin: 0;
}
</style>